<template>
	<b-col sm="4" md="4" lg="1" xl="1" class="search-btn-col" :class="$CoreSettingsJs.emptyStringValidation(classPadding) ? `` : classPadding" :order="order">
		<b-button variant="primary" size="sm" type="submit" class="text-nowrap" :class="icon_only == 1 ? `btn-icon` : ``" style="margin-bottom: 5px">
			<b-icon-search></b-icon-search>
			<span v-show="icon_only == 0" class="ml-1" v-text="$t('COMMON.BTN_SEARCH')"></span>
		</b-button>
	</b-col>
</template>

<script>
export default {
	props: {
		hasPermission: false,
		order: 0,
		icon_only: {
			type: Number,
			default() {
				return 0;
			}
		},
		classPadding: {
			type: String,
			default() {
				return "";
			}
		}
	}
};
</script>
