import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import sysSettings from "./syssettings.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		htmlClass,
		config,
		sysSettings
	}
});
