import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import permission from "@/core/config/permission.json";

// action types
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";

export const SET_PERMISSION = "setPermission";
export const SET_FIELD = "setField";

// mutation types

export default {
	state: {
		permission: permission,
		config: config,
		initial: config,
		field_filter: null
	},
	getters: {
		/**
		 * Get config from layout config
		 * @param state
		 * @returns {function(path, defaultValue): *}
		 */
		layoutConfig: (state) => (path, defaultValue) => {
			return objectPath.get(state.config, path, defaultValue);
		},

		userCan: (state) => (permission, privilege) => {
			let found = false;
			if (Array.isArray(permission)) {
				found = state.permission.find((item) => {
					return (
						(permission.indexOf(item.id) != -1 || permission.indexOf(item.name) != -1) &&
						((privilege === undefined && item.privilege.length > 0) || item.privilege.indexOf(privilege) != -1)
					);
				});
			} else {
				found = state.permission.find(
					(item) =>
						(permission === item.id || permission === item.name) &&
						((typeof privilege == "undefined" && item.privilege.length > 0) || (privilege !== "undefined" && item.privilege.includes(privilege)))
				);
			}

			if (found) {
				return true;
			} else {
				return false;
			}
		},
		fieldAllow: (state) => (module, lvl1, lvl2 = null) => {
			let found = false;

			if (state.field_filter != null) {
				if (typeof state.field_filter[module] != "undefined") {
					if (state.field_filter[module] instanceof Object) {
						if (typeof state.field_filter[module][lvl1] != "undefined") {
							if (state.field_filter[module][lvl1] instanceof Object) {
								if (typeof state.field_filter[module][lvl1][lvl2] != "undefined") {
									found = state.field_filter[module][lvl1][lvl2] == 1 ? true : false;
								}
							} else {
								found = state.field_filter[module][lvl1] == 1 ? true : false;
							}
						}
					} else {
						found = state.field_filter[module] == 1 ? true : false;
					}
				}
			}

			return found;
		}
	},
	actions: {
		/**
		 * Set and replace the whole config
		 * @param state
		 * @param payload
		 */
		[SET_LAYOUT_CONFIG](state, payload) {
			state.commit(SET_LAYOUT_CONFIG, payload);
		},

		/**
		 * Reset the config at the initial state
		 * @param state
		 */
		[RESET_LAYOUT_CONFIG](state) {
			state.commit(RESET_LAYOUT_CONFIG);
		},

		/**
		 * Reset the config using saved config in the cache by the layout builder
		 * @param state
		 */
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.commit(OVERRIDE_LAYOUT_CONFIG);
		},

		/**
		 * Override config by page level
		 * @param state
		 * @param payload
		 */
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
		},

		/**
		 * Set and replace permission
		 * @param state
		 * @param payload
		 */
		[SET_PERMISSION](state, payload) {
			state.commit(SET_PERMISSION, payload);
		},

		/**
		 * Set and replace permission
		 * @param state
		 * @param payload
		 */
		[SET_FIELD](state, payload) {
			state.commit(SET_FIELD, payload);
		}
	},
	mutations: {
		[SET_LAYOUT_CONFIG](state, payload) {
			state.config = payload;
		},
		[RESET_LAYOUT_CONFIG](state) {
			state.config = Object.assign({}, state.initial);
		},
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.config = state.initial = Object.assign({}, state.initial, JSON.parse(localStorage.getItem("config")));
		},
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.config = merge(state.config, payload);
		},
		[SET_PERMISSION](state, payload) {
			if (Array.isArray(payload)) {
				payload.forEach((item, index) => {
					if (item.hasOwnProperty("id") && item.hasOwnProperty("privilege")) {
						var foundIndex = state.permission.findIndex((x) => x.id == item.id);
						if (state.permission[foundIndex] !== undefined) {
							state.permission[foundIndex].privilege = item.privilege;
						}
					}
				});

				state.permission.forEach((item, index) => {
					if (item.hasOwnProperty("id") && item.hasOwnProperty("privilege")) {
						if (typeof payload.find((x) => x.id == item.id) == "undefined") {
							state.permission[index].privilege = [];
						}
						var foundIndex = state.permission.findIndex((x) => x.id == item.id);
						if (state.permission[foundIndex] !== undefined) {
							state.permission[foundIndex].privilege = item.privilege;
						}
					}
				});
			}
		},
		[SET_FIELD](state, payload) {
			state.field_filter = JSON.parse(payload);
		}
	}
};
