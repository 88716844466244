<template>
	<div
		id="imgModal"
		:class="portal == 1 ? 'previewImgModalCustomer' : 'previewImgModal'"
		v-show="previewVisible"
		v-if="image != null"
		@click="handleCancel"
	>
		<!-- The Close Button -->
		<span
			id="closePimage"
			:class="portal == 1 ? 'closePreviewImageCustomer' : 'closePreviewImage'"
			class="closePreviewImage"
			@click="handleCancel"
			>&times;</span
		>
		TEST12333333333333
		<!-- Modal Content (The Image) -->
		<img
			class="previewImageContent"
			id="pImageContent"
			:src="image"
			:class="darkBg == 1 ? 'bg-dark' : 'bg-transparent'"
		/>
	</div>
</template>

<style>
.previewImgModal,
.previewImgModalCustomer {
	position: fixed; /* Stay in place */
	z-index: 2; /* Sit on top */
	padding-top: 50px; /* Location of the box */
	padding-bottom: 50px; /* Location of the box */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.previewImgModalCustomer {
	padding-top: 136px !important;
}

.previewImageContent {
	margin: auto;
	display: block;
	width: auto;
	height: auto;
	max-width: 80%;
	animation-name: zoom;
	animation-duration: 0.6s;
}

.closePreviewImage,
.closePreviewImageCustomer {
	position: absolute;
	top: 30px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.closePreviewImageCustomer {
	top: 140px !important;
	right: 10px !important;
}

.closePreviewImage:hover,
.closePreviewImage:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}
</style>

<script>
export default {
	props: {
		image: {
			type: String,
			default: null,
		},
		darkBg: {
			type: Number,
			default: 0,
		},
		portal: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			previewVisible: false,
		};
	},
	methods: {
		handleCancel() {
			this.previewVisible = false;
		},
	},
};
</script>
