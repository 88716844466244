<template>
	<div class="d-flex date-range">
		<div class="flex-fill mr-1">
			<v-menu
				v-model="dateFromMenu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
				:disabled="computed_date_from_disabled"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						class="dateField"
						v-model="dfrom"
						:placeholder="date_from_title"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
						:hide-details="true"
						dense
						outlined
						:class="date_from_class == null ? `` : date_from_class"
						@change="$emit('resetState')"
						:clearable="is_view == 0"
					></v-text-field>
				</template>
				<v-date-picker
					v-if="!show_time"
					v-model="dfrom"
					@input="dateFromMenu = false"
				></v-date-picker>
				<div class="d-flex" v-if="show_time">
					<v-date-picker
						v-if="show_time"
						v-model="datefrom"
						@input="dateFromMenu = false"
					></v-date-picker>
					<v-time-picker
						v-if="show_time"
						v-model="timefrom"
						format="24hr"
						use-seconds
					></v-time-picker>
				</div>
			</v-menu>
			<b-form-invalid-feedback
				:state="date_from_state"
				v-html="date_from_error"
			></b-form-invalid-feedback>
		</div>
		<div class="flex-fill" v-if="single_date === false">
			<v-menu
				v-model="dateToMenu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
				:disabled="computed_date_to_disabled"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						class="dateField"
						v-model="dto"
						:placeholder="date_to_title"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
						:hide-details="true"
						dense
						outlined
						:class="date_to_class == null ? `` : date_to_class"
						@change="$emit('resetState')"
						:clearable="is_view == 0"
					></v-text-field>
				</template>
				<v-date-picker
					v-if="!show_time"
					v-model="dto"
					@input="dateToMenu = false"
				></v-date-picker>
				<div class="d-flex" v-if="show_time">
					<v-date-picker v-model="dateTo" @input="dateToMenu = false"></v-date-picker>
					<v-time-picker
						v-if="show_time"
						v-model="timeTo"
						format="24hr"
						use-seconds
					></v-time-picker>
				</div>
			</v-menu>
			<b-form-invalid-feedback
				:state="date_to_state"
				v-html="date_to_error"
			></b-form-invalid-feedback>
		</div>
	</div>
</template>

<script>
export default {
	name: "DateRangePicker",
	props: {
		date_from: null,
		date_from_title: null,
		date_to: null,
		date_to_title: null,
		date_from_class: null,
		date_to_class: null,
		single_date: {
			type: Boolean,
			default() {
				return false;
			},
		},
		is_view: {
			type: Number,
			default() {
				return 0;
			},
		},
		date_from_state: {
			type: Boolean,
			default() {
				return false;
			},
		},
		date_from_error: {
			type: String,
			default() {
				return null;
			},
		},
		date_to_state: {
			type: Boolean,
			default() {
				return false;
			},
		},
		date_to_error: {
			type: String,
			default() {
				return null;
			},
		},
		date_from_disable: {
			type: Boolean,
			default() {
				return false;
			},
		},
		date_to_disable: {
			type: Boolean,
			default() {
				return false;
			},
		},
		show_time: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	computed: {
		dfrom: {
			get() {
				return this.date_from;
			},
			set(newVal) {
				this.$emit("updateDateFrom", newVal);
			},
		},
		dto: {
			get() {
				return this.date_to;
			},
			set(newVal) {
				this.$emit("updateDateTo", newVal);
			},
		},
		datefrom: {
			get() {
				return this.handleSplitDateTime(0, this.date_from);
			},
			set(newVal) {
				this.$emit(
					"updateDateFrom",
					`${newVal} ${this.handleSplitDateTime(1, this.date_from)}`
				);
			},
		},
		dateTo: {
			get() {
				return this.handleSplitDateTime(0, this.date_to);
			},
			set(newVal) {
				this.$emit(
					"updateDateTo",
					`${newVal} ${this.handleSplitDateTime(1, this.date_to)}`
				);
			},
		},
		computed_date_from_disabled() {
			return this.is_view == 1 || this.date_from_disable;
		},
		computed_date_to_disabled() {
			return this.is_view == 1 || this.date_to_disable;
		},
	},
	watch: {
		// datefrom: {
		// 	handler: function (newValue) {
		// 		console.log(tfrom);
		// 	},
		// 	deep: true,
		// },
		timefrom: {
			handler: function (newValue) {
				this.$emit("updateDateFrom", `${this.datefrom} ${newValue}`);
			},
			deep: true,
		},
		timeTo: {
			handler: function (newValue) {
				this.$emit("updateDateTo", `${this.dateTo} ${newValue}`);
			},
			deep: true,
		},
	},
	data() {
		return {
			dateFromMenu: false,
			dateToMenu: false,
			timefrom: this.handleSplitDateTime(1, this.date_from),
			timeTo: this.handleSplitDateTime(1, this.date_to),
		};
	},
	methods: {
		handleSplitDateTime(time, date) {
			if (this.show_time) {
				let dateStr = date.split(" ");
				return dateStr[time];
			} else {
				return null;
			}
		},
	},
};
</script>
