<template>
  <div class="barcode">
    <img id="barcode" />
  </div>
</template>
<script>
import JsBarcode from "jsbarcode";
export default {
  name: "barcode",
  props: {
    content: {
      type: String,
      default: "测试条形码",
    },
    width: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      default: 40,
    },
    margin: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      canvasId: "",
    };
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  mounted: function () {},
  methods: {
    init() {
      let width = this.width,
        height = this.height;
      JsBarcode("#barcode", this.content, {
        // format: "pharmacode",  //条形码的格式
        width: width,
        height: height, //条码高度
        displayValue: false, //是否显示文字信息
        margin: this.margin,
      });
    },
  },
  watch: {
    content(val) {
      if (val) this.init();
    },
  },
};
</script>
<style lang="scss" scoped></style>
