var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item-content',[_c('v-list-item-title',[(_vm.tooltip_required == 1)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":`selection${_vm.id(_vm.item)}`},domProps:{"textContent":_vm._s(_vm.name(_vm.item))}}),_c('b-tooltip',{staticClass:"btooltip",attrs:{"variant":"dark","target":`selection${_vm.id(_vm.item)}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.tooltip(_vm.item))+" ")])]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tooltip_required == 0),expression:"tooltip_required == 0"}],domProps:{"textContent":_vm._s(_vm.name(_vm.item))}})],2),(
			['marking', 'delivery_address'].includes(_vm.field_type) &&
			typeof _vm.item[_vm.status_key] != 'undefined' &&
			_vm.status(_vm.item) == 3
		)?_c('v-list-item-subtitle',{staticClass:"text-warning",domProps:{"textContent":_vm._s(_vm.customer == 1 ? _vm.$t('COMMON.STATUS.SUSPEND') : _vm.$t('COMMON.STATUS.WGI'))}}):_vm._e(),(_vm.field_type == 'inv_quotation')?_c('v-list-item-subtitle',{staticClass:"text-teal",domProps:{"innerHTML":_vm._s(
			`${_vm.$t('TRANSACTION.INVOICE_MANAGEMENT.TITLE_EFFECTIVE', {
				expire: _vm.$CoreSettingsJs.emptyStringValidation(_vm.item.effective_on)
					? `-`
					: _vm.item.effective_on,
			})}`
		)}}):_vm._e(),(_vm.field_type == 'category')?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.item.cat_type_name)}}):_vm._e(),(_vm.field_type == 'tax')?_c('v-list-item-subtitle',{staticClass:"text-teal",domProps:{"innerHTML":_vm._s(`${_vm.$Common.numberFormatCurrency(_vm.item.rate)}%`)}}):_vm._e(),(['customer_min_markup', 'customer_markup'].includes(_vm.field_type))?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.item.markup)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }