import index from './index.vue'
// This is the point
const JsBarcode = {
  install: function (Vue) {
    Vue.component('JsBarcode', index);
  }
}

// Export components
export default {
  JsBarcode,
}