export const locale = {
	WEEKDAYS: {
		MONDAY: "星期一",
		TUESDAY: "星期二",
		WEDNESDAY: "星期三",
		THURSDAY: "星期四",
		FRIDAY: "星期五",
		SATURDAY: "星期六",
		SUNDAY: "星期日",
	},
	COMMON: {
		SELECTION_OTHERS: "其他",
		PH_ENTER_TEXTBOX: "输入 {txt_fieldname}",
		STATUS: {
			INACTIVE: "不活跃",
			ACTIVE: "活跃",
			SUSPEND: "暂停",
			YES: "是",
			NO: "不是",
			SAVE_AS_DRAFT: "草稿",
			UNVERIFIED: "未核实",
			WGI: "WGI",
			PENDING: "待定",
			APPROVED: "已审核",
			REJECTED: "已拒绝",
			VERIFY: "核实中",
			CLOSED: "已关闭",
		},
	},
	EPO: {
		STATUS: {
			ACTIVE: "Active",
			INACTIVE: "Inactive",
		},
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "新建",
			UPDATE_TYPE: "更新",
		},
		STATUS: {
			PENDING: "待处理中",
			VERIFYING: "核查中",
			APPROVED: "已核准",
			REJECTED: "遭拒",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "保存为发货地址",
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			RECEIPT: "收据"
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",
			OTHER: "其他",
			PN: "PN",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			ACCOUNT_NAME: "账户名",
			ACCOUNT_NO: "账号",
			BANK_ADDRESS: "开户行",
			BANK_NAME: "银行名"
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CURRENCY: "币种",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",

			TOTAL_AMT: "总金额",
			DISCOUNT: "折扣",
			DEPOSIT: "订金",
			STICKER_FEE: "扣贴纸费",
			BALANCE_AMT: "余额",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	}
};
