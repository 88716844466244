<template>
	<b-row align-v="end">
		<slot name="search"></slot>
		<b-col
			sm="12"
			md="4"
			lg="3"
			xl="2"
			:order="getFieldOrder('customer_id')"
			v-if="!hide('customer_id')"
		>
			<b-form-group>
				<template v-slot:label>
					<div class="d-flex flex-nowrap justify-content-between">
						<label v-text="$t('COMMON.LBL_CUSTOMER_NAME')"></label>
						<b-form-checkbox
							v-if="allow_search_exists_partner == 1"
							v-model="enable_customer_manual"
							value="1"
							unchecked-value="0"
							:checked="enable_customer_manual == 1"
						>
							<span v-text="$t('COMMON.LBL_ENTER_CUSTOMER_NAME')"></span>
						</b-form-checkbox>
					</div>
				</template>
				<selectField
					:data="searchForm.customer_ids"
					:selection="customerSelection"
					@update="
						(v) => {
							searchForm.customer_ids = v;
							getMarkingSelection();
						}
					"
					:multiple="true"
					:clearable="true"
					:show_all_label="true"
					@focus="getCustomerCompanySelection()"
					v-if="use_marking_selection == 1 && enable_customer_manual == 0"
				>
				</selectField>
				<b-form-input
					v-if="use_marking_selection == 0 || enable_customer_manual == 1"
					size="sm"
					:placeholder="$Common.placeHolderHandle($t('COMMON.LBL_CUSTOMER_NAME'))"
					v-model="searchForm.customer_name"
					:formatter="
						(v) => {
							return $CoreSettingsJs.onFormatterUpperCase(v);
						}
					"
					lazy-formatter
				></b-form-input>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="4"
			lg="3"
			xl="2"
			:order="getFieldOrder('customer_marking_id')"
			v-if="!hide('customer_marking_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_CUSTOMER_MARKING')">
				<selectField
					v-if="use_marking_selection == 1"
					:data="searchForm.customer_marking_ids"
					:selection="markingSelection"
					@update="
						(v) => {
							searchForm.customer_marking_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@search="getMarkingSelection"
					:loading="loadingMarking"
					:enable_search="true"
					:text_key="`marking`"
					:value_key="`id`"
					:field_type="`marking`"
					:wms="1"
					:custom_selection="1"
					:show_all_label="true"
				>
				</selectField>
				<b-form-input
					v-if="use_marking_selection == 0"
					size="sm"
					:placeholder="$Common.placeHolderHandle($t('COMMON.LBL_CUSTOMER_MARKING'))"
					v-model="searchForm.marking"
					:formatter="
						(v) => {
							return $CoreSettingsJs.onFormatterUpperCase(v);
						}
					"
					lazy-formatter
					@keydown.enter.prevent
				></b-form-input>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="4"
			lg="3"
			xl="2"
			:order="getFieldOrder('delivery_address_id')"
			v-if="!hide('delivery_address_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_DELIVERY_ID')">
				<selectField
					:data="searchForm.delivery_address_ids"
					:selection="delivery_address_selection"
					@update="
						(v) => {
							searchForm.delivery_address_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getDeliveryAddressSelection()"
					:text_key="`address_description`"
					:field_type="`delivery_address`"
					:wms="1"
					:custom_selection="1"
					:show_all_label="true"
				>
				</selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('msm_id')"
			v-if="!hide('msm_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_MAIN_SALESMAN')">
				<selectField
					:data="searchForm.msm_ids"
					:selection="msmSelection"
					@update="
						(v) => {
							searchForm.msm_ids = v;
							searchForm.msm_names = msmSelection
								.filter((x) => v.includes(x.msm_id))
								.map((x) => x.name.split('-')[0].trim());
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getMsmSelection()"
					:value_key="`msm_id`"
					:show_all_label="true"
				>
				</selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('sm_id')"
			v-if="!hide('sm_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_SALESMAN')">
				<selectField
					:data="searchForm.sm_ids"
					:selection="smSelection"
					@update="
						(v) => {
							searchForm.sm_ids = v;
							searchForm.sm_names = smSelection
								.filter((x) => v.includes(x.id))
								.map((x) => x.sm_code);
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getSmSelection()"
					:show_all_label="true"
				>
				</selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('company_id')"
			v-if="!hide('company_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_COMPANY')">
				<selectField
					:data="searchForm.company_ids"
					:selection="companySelection"
					@update="
						(v) => {
							searchForm.company_ids = v;
							searchForm.company_names = companySelection
								.filter((x) => v.includes(x.id))
								.map((x) => x.company_code);
						}
					"
					:multiple="true"
					:clearable="true"
					:show_all_label="true"
					@focus="getCompanySelection()"
				>
				</selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('company_group_id')"
			v-if="!hide('company_group_id')"
		>
			<b-form-group :label="$t('COMMON.LBL_COMPANY_GROUP')">
				<selectField
					:data="searchForm.company_group_ids"
					:selection="companyGroupSelection"
					@update="
						(v) => {
							searchForm.company_group_ids = v;
							searchForm.company_group_names = companyGroupSelection
								.filter((x) => v.includes(x.id))
								.map((x) => x.name);
						}
					"
					:multiple="true"
					:clearable="true"
					:show_all_label="true"
					@focus="getCompanyGroup()"
				>
				</selectField>
			</b-form-group>
		</b-col>

		<b-col
			sm="12"
			md="2"
			lg="2"
			xl="1"
			:order="getFieldOrder('status')"
			v-if="!hide('status')"
		>
			<b-form-group :class="getRequiredFields('status')" :label="$t('COMMON.LBL_STATUS')">
				<selectField
					:data="searchForm.status"
					:selection="$CoreJs.statusSelection"
					@update="
						(v) => {
							searchForm.status = v;
						}
					"
					:value_key="`item`"
					:text_key="`name`"
					:show_all_label="true"
				></selectField>
			</b-form-group>
		</b-col>
		<searchButton
			:order="getFieldOrder('search_button')"
			v-if="!hide('search_button')"
		></searchButton>
	</b-row>
</template>

<script>
import searchButton from "@/view/content/form/SearchFormButton.vue";
export default {
	name: "SearchFormStandardFilter",
	props: {
		searchForm: null,
		use_marking_selection: {
			type: Number,
			default: 1,
		},
		filter_delivery_address: {
			type: Number,
			default: 0,
		},
		allow_search_exists_partner: {
			type: Number,
			default: 0,
		},
		hideField: {
			type: Array,
			default() {
				return [];
			},
		},
		sortField: {
			type: Object,
			default() {
				return {};
			},
		},
		master_page: {
			type: Boolean,
			default() {
				return false;
			},
		},
		includeAllAgent: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			msmSelection: [],
			smSelection: [],
			companySelection: [],
			companyGroupSelection: [],
			markingSelection: [],
			customerSelection: [],
			delivery_address_selection: [],
			searchMarking: null,
			loadingMarking: false,
			enable_customer_manual: 0,
		};
	},

	mounted() {
		// this.getMsmSelection();
		// this.getSmSelection();
		// this.getCompanySelection();
		// this.getCompanyGroup();
		//this.getMarkingSelection();
		//this.getCustomerCompanySelection();
	},
	watch: {
		// searchMarking(val) {
		// 	this.getMarkingSelection(val);
		// }
	},
	components: {
		searchButton,
	},
	methods: {
		async getCustomerCompanySelection() {
			this.customerSelection = await this.$CoreJs.customerSelection();
		},
		async getMarkingSelection(keyword = null, customer_marking_ids = []) {
			this.loadingMarking = true;
			let response = await this.$CoreJs.fullMarkingSelection({
				customer_ids: this.searchForm.customer_ids,
				keyword: keyword,
				allow_all_agent: this.includeAllAgent ? 1 : 0,
				agent_id: this.$CoreSettingsJs.emptyStringValidation(this.searchForm.agent_id)
					? null
					: this.searchForm.agent_id,
				agent_ids: this.$CoreSettingsJs.emptyStringValidation(this.searchForm.agent_ids)
					? []
					: this.searchForm.agent_ids,
				customer_marking_ids: customer_marking_ids,
			});
			this.markingSelection = response;
			this.searchForm.customer_marking_ids = this.$Common.checkResetSelectionObject(
				this.markingSelection,
				this.searchForm.customer_marking_ids
			);
			this.loadingMarking = false;
		},
		async getMsmSelection() {
			let response = await this.$CoreJs.mainSalesmanSelection();
			this.msmSelection = response;
			this.searchForm.msm_ids = this.$Common.checkResetSelectionObject(
				this.msmSelection,
				this.searchForm.msm_ids
			);
		},
		async getCompanySelection() {
			let response = await this.$CoreJs.companySelection({
				msm_ids: this.searchForm.msm_ids,
			});
			this.companySelection = response;
		},
		async getCompanyGroup() {
			try {
				let response = await this.$CoreJs.companyGroupSelection({
					company_ids:
						this.searchForm.company_ids.length > 0 ? this.searchForm.company_ids : null,
				});
				this.companyGroupSelection = response;
				this.searchForm.company_group_ids = this.$Common.checkResetSelectionObject(
					this.smSelection,
					this.searchForm.company_group_ids
				);
			} catch (error) {}
		},

		async getSmSelection() {
			let response = await this.$CoreJs.salesmanSelection({
				msm_ids: this.searchForm.msm_ids,
			});
			this.smSelection = response;
			this.searchForm.sm_ids = this.$Common.checkResetSelectionObject(
				this.smSelection,
				this.searchForm.sm_ids
			);
		},
		async getDeliveryAddressSelection() {
			let response = await this.$CoreJs.deliveryAddressSelection({
				marking_ids: this.searchForm.customer_marking_ids,
				customer_ids: this.searchForm.customer_ids,
				inc_delete_status: 1,
			});
			this.delivery_address_selection = response;
			this.searchForm.delivery_address_ids = this.$Common.checkResetSelectionObject(
				this.delivery_address_selection,
				this.searchForm.delivery_address_ids
			);
		},
		getFieldOrder(field_name) {
			return !this.$CoreSettingsJs.emptyObjectValidation(this.sortField) &&
				!this.$CoreSettingsJs.emptyObjectValidation(this.sortField[field_name])
				? parseInt(this.sortField[field_name])
				: 0;
		},
		hide(field_name) {
			return (!this.$CoreSettingsJs.emptyObjectValidation(this.hideField) &&
				this.hideField.includes(field_name)) ||
				(this.master_page &&
					!["status", "agent_id", "msm_id", "search_button", "warehouse_id"].includes(
						field_name
					))
				? true
				: false;
		},
		getRequiredFields(field_name) {
			return !this.$CoreSettingsJs.emptyObjectValidation(this.required_fields) &&
				this.required_fields.includes(field_name)
				? `required-fields`
				: ``;
		},
	},
};
</script>
