<template>
	<div class="gim-form-section" v-bind:class="classes">
		<div class="gim-form-section-head d-flex flex-nowrap align-items-end mb-1" v-bind:class="setHeadClass" v-if="hasTitleSlot || sectionTitle">
			<div class="gim-form-section-title flex-fill" :class="collapse ? `` : `mt-2`">
				<slot name="title" v-if="hasTitleSlot"></slot>
				<h3 class="gim-form-section-label" v-if="!hasTitleSlot" v-html="sectionTitle"></h3>
			</div>
			<div class="gim-form-section-toolbar">
				<slot name="toolbar"></slot>
			</div>
		</div>
		<div class="gim-form-section-body" v-bind:class="bodyClasses">
			<div class="gim-form-section-content" :class="sectionContentClass">
				<slot name="body"></slot>
			</div>
		</div>
		<div class="gim-form-section-foot" v-bind:class="footClass" v-if="hasFootSlot">
			<slot name="foot"></slot>
		</div>
	</div>
</template>

<style lang="scss">
.gim-form-section + .gim-form-section {
	margin-top: 5px;
}

.gim-form-section {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0 -12px;
}

.gim-form-section.section-bordered {
	border: 1px solid #000000;
}

.gim-form-section > .gim-form-section-head,
.gim-form-section > .gim-form-section-body {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
	width: 100%;
	padding: 0 5px;
}

.gim-form-section > .gim-form-section-body:not(.body-no-gutters) > .gim-form-section-content {
	padding: 8px 5px;
}

.gim-form-section > .gim-form-section-body.body-bordered > .gim-form-section-content {
	border: 1px solid #b8b8b8;
	border-radius: 3px;
}

.gim-form-section > .gim-form-section-head > .gim-form-section-title > .gim-form-section-label {
	font-size: 1.25rem;
	margin-bottom: 5px;
	text-decoration: underline;
}
.gim-form-section > .gim-form-section-head > .gim-form-section-title {
	padding: 0 5px !important;
}
</style>

<script>
export default {
	name: "GimFormSection",
	props: {
		/**
		 * Set extra class for main gim form section
		 */
		sectionClass: String,
		/**
		 * Set gim form section body to fit
		 */
		sectionBordered: Boolean,
		/**
		 * String title
		 */
		sectionTitle: String,
		/**
		 * Set gim form section head size. Eg. md, lg, sm, etc.
		 */
		headSize: String,
		/**
		 * Set extra class for gim form section head
		 */
		headClass: String,
		/**
		 * Set gim form section body to fit
		 */
		bodyBordered: Boolean,
		/**
		 * Set extra class for gim form section body
		 */
		bodyClass: String,
		/**
		 * Set extra class for gim form section head
		 */
		footClass: String,
		collapse: {
			type: Boolean,
			default: false
		},
		sectionContentClass: String
	},
	components: {},
	methods: {},
	computed: {
		classes() {
			const cls = {
				"section-bordered": this.sectionBordered
			};

			// append extra classes
			if (this.sectionClass) {
				cls[this.sectionClass] = true;
			}

			return cls;
		},
		bodyClasses() {
			const cls = {
				"body-bordered": this.bodyBordered
			};

			// append extra classes
			if (this.bodyClass) {
				cls[this.bodyClass] = true;
			}

			return cls;
		},
		hasTitleSlot() {
			return !!this.$slots["title"];
		},
		hasFootSlot() {
			return !!this.$slots["foot"];
		},
		setHeadClass() {
			return (this.collapse ? `` : ` justify-content-between `) + (typeof this.headClass != "undefined" ? this.headClass : ``);
		}
	}
};
</script>
