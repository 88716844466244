const strToUppercase = function(el, _, vnode) {
    // uppercase transform
    el.transformUpper = function() {
            const start = el.selectionStart
            el.value = el.value.toUpperCase()
            el.setSelectionRange(start, start)
            vnode.componentInstance.$emit('input', el.value.toUpperCase())
        }
        // add event listener
    el.addEventListener('input', function() {
        el.transformUpper()
    })
}

const strToUppercase25 = function(el, _, vnode) {
    // uppercase transform
    el.transformUpper = function() {
            const start = el.selectionStart
            el.value = el.value.toUpperCase().slice(0, 25);
            el.setSelectionRange(start, start)
            vnode.componentInstance.$emit('input', el.value.toUpperCase())
        }
        // add event listener
    el.addEventListener('input', function() {
        el.transformUpper()
    })
}

export default {
    strToUppercase,
    strToUppercase25
}