// Simplified Chinese
export const locale = {
	TRANSLATOR: {
		SELECT: "选择你的语言"
	},
	MENU: {
		NEW: "新",
		ACTIONS: "行动",
		CREATE_POST: "创建新帖子",
		PAGES: "页面",
		FEATURES: "特征",
		APPS: "应用",
		DASHBOARD: "仪表板",
		ASIDE: {
			CUSTOMER_MANAGEMENT: "客户管理",
			CUSTOMER: "客户列表",
			DELIVERY_ADDRESS: "收货地址",
			CUSTOMER_DO_HEADER: "客户 Do Header",
			CUSTOMER_SUPPLIER: "客户的供应商",
			CUSTOMER_UPDATE_LOG: "Customer Update Log",
			CUSTOMER_UPDATE_LOG_REJECT_INFO: "Customer Update Log Reject Info",
			USER: "用户管理",
			ROLE: "角色管理",
			DATA_FILTER: "数据过滤管理",
			USER_N_ROLE_MANAGEMENT: "用户和角色管理",
			CHARGES_TYPE: "Charges Type",
			MASTER_DATA: "Master Data",
			TRANSACTION: "Transaction",
			TEMPLATE_SETTING: "Template Setting",
			ANNOUNCEMENT_MANAGEMENT: "Announcement Management",
			NOTICE_MANAGEMENT: "Notice Management",
			SLIDER_MANAGEMENT: "Slider Management",
			BANNER_MANAGEMENT: "Banner Management",
			CMS: "CMS",
			PAGE_MANAGEMENT: "Page Management",
			MENU_MANAGEMENT: "Menu Management",
			MENU_ITEM_MANAGEMENT: "Menu Item Management",
			CUSTOMER: "Customer",
			DASHBOARD: "Dashboard",
			COUNTRIES_REGION: "Countries & Region",
			COUNTRIES: "Countries",
			STATES: "States",
			CITIES: "Cities",
			POSTCODES: "Postcodes",
			CURRENCIES_TAX: "Currencies & Tax",
			TAX_TYPE: "Tax Type",
			CURRENCIES: "Currencies",
			COMPANY: "Company",
			WAREHOUSE: "Warehouse",
			SALESMAN_MANAGEMENT: "Salesman Management",
			MAINSALESMAN: "Main Salesman",
			COSTING: "Costing Management",
			CUSTOMER_COSTING_GROUP: "Customer Costing Group",
			SHIPMENT: "Shipment",
			SHIPMENT_TYPE: "Shipment Type",
			SHIPMENT_MODE: "Shipment Mode",
			MOVEMENT_TYPE: "Movement Type",
			SHIPMENT_TERMS: "Shipment Terms",
			REFERENCE_TYPE: "Reference Type",
			COURIER_COMPANY: "Courier Company",
			CATEGORY_GROUP: "Category Type",
			SENSITIVE_TYPE: "Sensitive Type",
			CATEGORY_PARENT: "Product Category (Parent)",
			CATEGORY: "Product Category (Lvl 2)",
			SHIPPER: "Shipper",
			SHIPPING_LINE: "Shipping Line",
			AREA: "Area",
			CREDIT_TERMS: "Credit Terms",
			SALESMAN: "Salesman",
			SETTINGS: "Settings",
			GENERAL_SETTINGS: "General",
			TRANSACTION: "Transaction",
			NOTICE_TYPE_GROUP: "Notice Type Group",
			NOTICE_TYPE: "Notice Type",
			DOC_TYPE: "Doc Type",
			DOC_NUM: "Doc Numbering",
			QUOTATION_MANAGEMENT: "Quotation Management",
			QUOTATION: "Quotation",
			/* Wesly 04/05/2021 End*/
			VALIDITY: "Validity",
			/* Wesly 04/05/2021 */
			INDEMNIFY: "Indemnify",
			/* Wesly 09/05/2021 */
			FREIGHT_CHARGES: "Freight Charges",
			/* Wesly 09/05/2021 */
			QUOTATION_STATUS: "Quotation Status",
			REQUEST_FOR_QUOTE: "Request For Quote",
			PORT: "Port",
			TERRITORY: "Territory",
			STOCK: "Stock",
			UOM: "Stock UOM",
			INSTRUCTION_TYPE: "Instruction Type",
			INDUSTRIES: "Industries",
			PARTNER_TYPE: "Partner Type",
			REMARK_ID: "Delivery Remark",
			FORWARDER: "Forwarder",
			QUOTATION_MANAGEMENT_LCL: "LCL",
			QUOTATION_MODULE: {
				COND_CONFIRM: "Condition Confirm",
				COND_APPROVE: "Condition Approve",
				COST_CONFIRM: "Costing Confirm",
				REJECT_INQUIRY_QUOTATION: "Rejected/Cancelled Quotation",
				INVALID_QUOTATION: "Invalid Quotation",
				COST_APPROVE: "Costing Approved",
				SALESMAN_QUOTE: "Salesman Quote",
				QUOTE_CONFIRM: "Quote Confirm",
				QUOTE_APPROVE: "Quote Approve",
				QUOTE_PENDING_ISSUE: "Quotation Pending Issue",
				QUOTATION_PENDING_ACKNOWLEDGE: "Quotation Pending Acknowledge",
				QUOTATION_ACKNOWLEDGE: "Acknowledged Quotation",
				INQUIRY_SUMMARY: "Inquiry Summary",
				SCS_CONFIRM: "SCS Confirm",
				QUOTATION_COST: "Quotation Costing"
			},
			QUOTATION_MANAGEMENT_FCL: "FCL/Others LCL",
			WAREHOUSE_LOCATION: "Warehouse Location",
			TAX: "Tax",
			ROUTE: "Route",
			EXCEPTIONAL_COST_MASTER: "Exceptional Cost Type",
			EXCEPTIONAL_COST: "Exceptional Cost",
			WEIGHT_ROUNDING: "Weight Rounding",
			CUSTOMER_MASTER_DATA: "Customer Master Data",
			DELIVERY_ADDRESS: "Delivery Address",
			DAY_CONVERSION: "Day Conversion",
			QUOTATION_SUBJECT: "Quotation Subject",
			CUSTOMER_REJECT_QUOTE_REASON: "Customer Reject Reason",
			ADMIN_REJECT_QUOTE_REASON: "Admin Reject Reason",
			REVERT_QUOTE_REASON: "Revert Quote Reason",
			QUOTE_INVALID_REASON: "Quotation Invalid Reason",
			MAIN_SALESMAN_COST: "Main Salesman Cost",
			CATEGORY_TYPE_COST: "Category Type Cost",
			SALESMAN_COST_GROUP: "Salesman Cost Group",
			SALESMAN_COST_SETUP: "Salesman Cost",
			CUSTOMER_COST: "Customer Cost",
			SPECIAL_PRODUCT_STATUS: "Special Product Status",
			WAREHOUSE_TPYE: "Warehouse Type",
			LANGUAGE: "Language",
			PRODUCT_MANAGEMENT: "Product Management",
			SPECIAL_PRODUCT: "Special Product",
			BASE_COST: "Base Cost",
			SPECIAL_INSTRUCTION_BY_MARKING: "Special Instruction By Marking",
			SPECIAL_INSTRUCTION_BY_EPO: "Special Instruction By Epo",
			TRANSIT_COST: "Transit Cost",
			MARKING_COST: "Marking Cost",
			CUSTOMER_EXCEPTIONAL_COST: "Customer Exceptional Cost",
			EPO_MANAGEMENT: "EPO Management",
			EPO_LISTING: "EPO Listing",
			DISTRICT: "District",
			SPECIAL_INSTRUCTION_MANAGEMENT: "Special Instruction"
		}
	},
	AUTH: {
		GENERAL: {
			OR: "要么",
			SUBMIT_BUTTON: "提交",
			NO_ACCOUNT: "没有账号？",
			SIGNUP_BUTTON: "注册",
			FORGOT_BUTTON: "忘记密码",
			BACK_BUTTON: "背部",
			PRIVACY: "隐私",
			LEGAL: "法律",
			CONTACT: "联系"
		},
		LOGIN: {
			TITLE: "GBIZS SYSTEM 登录",
			BUTTON: "登录"
		},
		FORGOT: {
			TITLE: "Forgot Password?",
			DESC: "Enter your email to reset your password",
			SUCCESS: "Your account has been successfully reset."
		},
		REGISTER: {
			TITLE: "Sign Up",
			DESC: "Enter your details to create your account",
			SUCCESS: "Your account has been successfuly registered."
		},
		INPUT: {
			EMAIL: "Email",
			FULLNAME: "Fullname",
			PASSWORD: "Password",
			CONFIRM_PASSWORD: "Confirm Password",
			USERNAME: "用戶名"
		},
		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			MIN_LENGTH: "{name} minimum length is {min}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {name} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",

			INVALID_WARNING_MSG: "Please, provide correct data!"
		},
		RESPONSE_MSG: {
			LOGIN: {
				200: "Login Successfully",
				2001: "Invalid Username or Password"
			}
		}
	},
	COMMON: {
		BTN_SEARCH: "Search",
		BTN_CANCEL: "Cancel",
		BTN_BACK: "Back",
		BTN_SAVE: "Save",
		BTN_EDIT: "Edit",
		BTN_CREATE: "Create New {btn_name}",
		BTN_SUBMIT: "Submit",
		BTN_ADD: "Add",
		BTN_DELETE: "Delete",
		BTN_COPY: "Copy",
		BTN_CLOSE: "Close",
		BTN_CONFIRM: "Confirm",
		BTN_APPORVE: "Approve",
		BTN_PREVIEW: "Preview",
		BTN_SAVE_AS_DRAFT: "Save",
		BTN_DOWNLOAD_AS_PDF: "Save as PDF",
		BTN_PRINT: "Print",
		BTN_CONFIRM_N_APPROVE: "Confirm & Approve",
		BTN_VIEW: "View",
		BTN_RESET: "Reset",
		BTN_DOWNLOAD_ALL: "Download All",
		BTN_DOWNLOAD_SELECTED: "Download Selected",
		BTN_COST_SETUP: "Cost Setup",
		BTN_LEAVE: "Leave Page",
		BTN_STAY: "Stay on the page",
		BTN_REFRESH: "Refresh",

		LBL_CREATED_BY: "Created By",
		LBL_CREATED_AT: "Creation On",
		LBL_UPDATED_BY: "Modified By",
		LBL_UPDATED_AT: "Modified On",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_STATUS: "Status",
		LBL_PUBLISH: "Publish",
		LBL_PUBLISH_MENU: "Publish Menu",
		LBL_PUBLISH_MENU_ITEM: "Public Menu Item",
		LBL_RECORD_PER_PAGE: "Records",

		PH_ENTER_CREATED_BY: "Enter Created By",
		PH_ENTER_CREATED_AT: "Enter Creation On",
		PH_ENTER_UPDATED_BY: "Enter Modified By",
		PH_ENTER_UPDATED_AT: "Enter Modified On",
		PH_ENTER_APPROVED_BY: "Enter Approved By",
		PH_ENTER_REJECTED_BY: "Enter Rejected By",
		PH_ENTER_FROM: "From",
		PH_ENTER_TO: "To",
		PH_AUTO_DOC_NO: "[Auto]",

		ACTIVE_STATUS_VAL: "Active",
		INACTIVE_STATUS_VAL: "Inactive",
		PENDING_STATUS_VAL: "Pending",
		APPROVED_STATUS_VAL: "Approved",
		REJECTED_STATUS_VAL: "Rejected",
		VERIFYING_STATUS_VAL: "Verifying",

		CLOSE_STATUS_VAL: "Closed",

		MSG_FAILED_CONNECT_SERVER: "Failed To Connect Server. Please Contact System Administrator",

		COL_STATUS: "Status",
		COL_MODIFIEDBY: "Modified By",
		COL_CREATED_BY: "Created By",
		COL_ACTION: "Action",
		COL_PUBLISH: "Publish",
		COL_MODIFIED_ON: "Modified On",

		TXT_ALL: "All",
		TXT_NEW: "New",
		TXT_ERROR: "Error",
		TEXT_WARNING: "Warning",
		TXT_SELECT_ALL: "Select All",

		MSG_CONFIRM_DELETE: "Are you sure to delete?",
		DELETE_MODAL_TITLE: "Confirm Delete Record?",
		SELECTION_OPTION_TITLE: "-- Select Options --",
		SELECTION_NONE: " -- None --",
		CATEGORY_ROOT_SELECTION: " --- Category Lvl1 ---",
		CATEGORYT_SELECTION: "--- Category Lvl2 ---",
		TAX_TYPE_SELECTION: "-- Select Tax Type --",
		TAX_SELECTION: "-- Select Tax --",

		MSG_FAILED_TO_UPDATE: "{mod} has been failed edited",
		MSG_SUCCESS_TO_UPDATE: "{mod} has been successfully edited",

		MSG_FAILED_CREATE: "New {mod} has been failed to created",
		MSG_SUCCESS_TO_CREATE: "New {mod} has been successfully to created",
		MSG_FAILED_DELETE: "{mod} failed to delete",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED: "Image format not supported. Supported format: .jpg, .jpeg, .png",
		MSG_REMOVE_IMAGE: "This image will be removed",
		FAILD_UPLOAD_FILE: "Oops... ..., Failed to upload file to server. Please try again",

		MSG_SUCCESS_TO_DELETE: "{mod} record successful to delete",

		PH_ENTER_TEXTBOX: "Enter {txt_fieldname}",
		PH_DROP_FILE: "Choose a file or drop it here...",
		DROP_PH_DROP_FILE: "Drop file here...",
		MSG_INVALID_EMAIL: "Invalid Email",
		MSG_NOT_ALLOW_DELETE: "Ooops, You are not allow delete this {mod} record",
		LBL_COUNTRIES: "Country",
		LBL_STATES: "State",
		LBL_CITY: "City",
		LBL_POSTCODE: "Postcode",

		SELECT_ALL_TITLE: "Select All",

		SELECTION_OTHERS: "Others",
		DAY_VAL: "{day} days",

		RATE_TYPE_SELECTION: {
			FIX_AMOUNT: "Fix Amount",
			RATE_BY_AMOUNT: "Rate By Amount",
			RATE_BY_PERCENTAGE: "Rate By Percentage"
		},

		FORMULA_SELECTION: {
			BASE_RATE: "Base Rate",
			MULTIPLE_RATE: "Multiple Rate"
		},

		MSG: {
			MSG_ERROR_TITLE: "Oops...",
			NOT_FOUND: "{name} not found",
			API_SERVICE_ERROR: "System Error, Please assist System Admin.",
			SUCCESSFUL_SAVE: "Successful save record",
			ARE_YOU_SURE: "Are You Sure?",
			MSG_UNABLE_UPLOAD_IMAGE: "Unable upload image. Please try again",
			MSG_BEFORE_LEAVE_PAGE: "Changes you made may not saved. If you leave page, these changes will be lost.",
			MSG_BEFORE_LEAVE_PAGE_TITLE: "Are you sure you want to leave this page?",
			MSG_DATA_NOT_FOUND: "No Records Found"
		},

		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			MIN_LENGTH: "{name} minimum length is {min}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {name} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			NUMBER: "{name} must be number",

			INVALID_WARNING_MSG: "Please, provide correct data!",
			DUPLICATE_ENTRY: "{name} is duplicate. Please Try Again",
			UNIQUE_FIELD: "{name} must be unique.",
			DOCUMENT_NUMBERING_NOT_SET: "Document Number Format is not found. Please setup first.",
			ID_NOT_FOUND: "Record not found",

			INVALID_CONTACT_NO_FORMAT: {
				FORMAT_MY: "{name} is not valid, correct format eg: 123456789"
			}
		},

		SEARCHFORM: {
			LBL_MARKING: "Marking",
			LBL_MAIN_SALESMAN: "Main Salesman",
			LBL_SALESMAN: "Salesman",
			LBL_COMPANY: "Company",
			LBL_COMPANY_GROUP: "Company Group",
			LBL_WAREHOUSE: "Warehouse",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_QUOTATION_NO: "Quotation No.",
			LBL_CUSTOMER_NAME: "Customer Company Name"
		},
		NEW_TITLE: "New"
	},
	MASTER_DATA: {
		CHARGESTYPE: {
			BTN_CREATE: "Create New Charges Type",
			MODULE_NAME: "Charges Type",
			LISTING_TITLE: "Charges Type Listing",
			CREATE_TITLE: "Create New Charges Type",
			DETAIL_TITLE: "Charges Type Detail",
			EDIT_TITLE: "Update Charges Type",

			LBL_CHARGES_TYPE_NAME: "Charges Type Name",
			LBL_CHARGES_TYPE_CODE: "Charges Type Code",
			LBL_CHARGES_TYPE_SHORT_FORM: "Charges Type Description",
			LBL_ORDER_SEQUENCE: "Order Sequence",

			PH_ENTER_CHARGES_TYPE_CODE: "Enter Charges Type Code",
			PH_ENTER_CHARGES_TYPE_NAME: "Enter Charges Type Name",
			PH_ENTER_CHARGES_TYPE_SHORTFORM: "Enter Charges Type Description",
			PH_ENTER_ORDER_SEQUENCE: "Enter Order Sequence",
			PH_ENTER_SEQUENCE: "Enter Sequence",

			COL_CHARGES_TYPE_CODE: "Charges Type Code",
			COL_CHARGES_TYPE_NAME: "Charges Type Name",
			COL_ORDER_SEQUENCE: "Order Sequence",

			LBL_BY_UOM: "Charges By UOM",
			LBL_ALLOW_MARKUP: "Allow Markup",
			LBL_BY_AMOUNT: "Charges By Amount",
			LBL_OPTIONAL_CHARGES: "Optional Charges",
			LBL_PRINT_ON_QUOTATION: "Print on Quotation",
			LBL_SUB_CHARGES: "Sub Charges",
			LBL_MIN_CHARGES: "Min Charges",
			LBL_MIN_CHARGES_BY_UOM: "Min Charges By UOM",

			MSG_FAILED_TO_UPDATE: "Charges Type has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "Charges Type has been successfully edited",

			MSG_FAILED_CREATE_CHARGES_TYPE: "New Charges Type has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Charges Type has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Charges Type has been deleted",

			SUBCHARGER: {
				COL_CHARGES_TYPE_NAME: "Charges Type Name",
				COL_CHARGES_TYPE_DESCRIPTION: "Charges Type Description",
				COL_CHARGES_TYPE_SEQUENCE: "Sequence",
				COL_CHARGES_TYPE_CHARGE_TYPE_UOM: "Charges By UOM",
				COL_CHARGES_TYPE_OPTIONAL_CHARGES: "Optional Charges",
				COL_CHARGES_TYPE_ALLOW_MARKUP: "Allow Markup",
				COL_CHARGES_TYPE_PRINT_ON_QUOTATION: "Print on Quotation",
				COL_CHARGES_TYPE_MIN_CHARGES: "Min Charges",
				COL_CHARGES_TYPE_MIN_CHARGES_UOM: "Min Charges By UOM"
			}
		},
		COUNTRIES: {
			LISTING_TITLE: "Country Listing",
			CREATE_TITLE: "Create New Country",
			DETAIL_TITLE: "Country Detail",
			EDIT_TITLE: "Edit Country",
			MODULE_NAME: "Country",

			LBL_COUNTRIES_NAME: "Country Name",
			LBL_COUNTRIES_FULL_NAME: "Full Name",
			LBL_COUNTRIES_ISO_CODE3: "ISO Code 3",
			LBL_COUNTRIES_ISO_CODE2: "ISO Code 2",
			LBL_COUNTRIES_PHONE_CODE: "Phone Code",
			LBL_POPULAR_SEQUENCE: "Popular Sequence",

			BTN_CREATE: "Create New Countries",

			COL_NAME: "Country Name",
			COL_ISO_CODE3: "Iso Code 3",
			COL_ISO_CODE2: "Iso Code 2",
			COL_CALIING_CODE: "Phone Code",
			COL_POPULAR_SEQUENCE: "Popular Sequence",

			PH_ENTER_COUNTRIES_NAME: "Enter Country Name",
			PH_ENTER_COUNTRIES_FULL_NAME: "Enter Country Full Name",
			PH_ENTER_COUNTRIES_ISO_CODE3: "Enter Iso Code 3",
			PH_ENTER_COUNTRIES_ISO_CODE2: "Enter Iso Code 2",
			PH_ENTER_COUNTRIES_PHONE_CODE: "Enter Phone Code",

			MSG_FAILED_TO_UPDATE: "Country has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "Country has been successfully edited",

			MSG_FAILED_CREATE_COUNTRIES: "New Country has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Country has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Country has been deleted",

			MSG_CALLING_CODE_EMPTY: "Calling Code records have been not found"
		},
		STATES: {
			LISTING_TITLE: "States Listing",
			CREATE_TITLE: "Create New State",
			DETAIL_TITLE: "State Detail",
			EDIT_TITLE: "Edit State",
			MODULE_NAME: "State",

			BTN_CREATE: "Create New States",

			LBL_STATES_NAME: "State Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES_CODE: "State Code",

			PH_ENTER_STATES_NAME: "State Name",
			PH_ENTER_STATES_CODE: "State Code",

			COL_NAME: "State Name",
			COL_COUNTRY: "Country",

			MSG_FAILED_TO_UPDATE: "State has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "State has been successfully edited",

			MSG_FAILED_CREATE_STATES: "New State has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New State has been successfully to created",
			MSG_FAILED_DELETE: "State failed to delete",

			MSG_SUCCESS_TO_DELETE: "State record successful to delete"
		},
		CITIES: {
			LISTING_TITLE: "City Listing",
			CREATE_TITLE: "Create New City",
			DETAIL_TITLE: "City Detail",
			EDIT_TITLE: "Edit City Detail",
			MODULE_NAME: "City",
			BTN_CREATE: "Create New City",

			LBL_CITY_NAME: "City Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",

			PH_ENTER_CITY_NAME: "Enter City Name",
			COL_NAME: "Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",

			MSG_FAILED_TO_UPDATE: "City has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully edited",

			MSG_FAILED_CREATE_CITY: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		},
		PORT: {
			LISTING_TITLE: "Port Listing",
			EDIT_TITLE: "Edit Port Detail",
			DETAIL_TITLE: "Port Detail",
			LBL_COUNTRIES: "Country",
			BTN_CREATE: "Create New Port",
			LBL_PORT_CODE: "Port Code",
			LBL_PORT_NAME: "Port Name",
			COL_PORT_CODE: "Port Code",
			COL_PORT_NAME: "Port Name",
			COL_COUNTRY_NAME: "Country Name",
			MODULE_NAME: "Port",
			CREATE_TITLE: "Create New Port"
		},
		TERRITORY: {
			LISTING_TITLE: "Territory Listing",
			EDIT_TITLE: "Edit Territory Detail",
			DETAIL_TITLE: "Territory Detail",
			LBL_COUNTRIES: "Country",
			BTN_CREATE: "Create New Territory",
			LBL_TERRITORY_CODE: "Territory Code",
			LBL_TERRITORY_NAME: "Territory Name",
			COL_TERRITORY_CODE: "Territory Code",
			COL_TERRITORY_NAME: "Territory Name",
			COL_COUNTRY_NAME: "Country Name",
			MODULE_NAME: "Territory",
			CREATE_TITLE: "Create New Territory"
		},
		UOM: {
			LISTING_TITLE: "Stock UOM Listing",
			EDIT_TITLE: "Edit Stock UOM Detail",
			DETAIL_TITLE: "Stock UOM Detail",
			BTN_CREATE: "Create New Stock UOM",
			LBL_UOM_CODE: "Stock UOM Code",
			LBL_UOM_NAME: "Stock UOM Name",
			COL_UOM_CODE: "Stock UOM Code",
			COL_UOM_NAME: "Stock UOM Name",
			LBL_UOM_PERCENTAGE_VALUE: "Percentage",
			LBL_UOM_USE_RECEIVING: "Use for Receiving",
			LBL_UOM_USE_INVOICE: "Use for Invoice",
			LBL_UOM_QUOTATION_DESCRIPTION: "Quotation Description",
			MODULE_NAME: "Stock UOM",
			CREATE_TITLE: "Create New Stock UOM"
		},
		INDUSTRIES: {
			LISTING_TITLE: "Industries Listing",
			EDIT_TITLE: "Edit Industries Detail",
			DETAIL_TITLE: "Industries Detail",
			BTN_CREATE: "Create New Industries",
			LBL_INDUSTRIES_CODE: "Industries Code",
			LBL_INDUSTRIES_NAME: "Industries Name",
			COL_INDUSTRIES_CODE: "Industries Code",
			COL_INDUSTRIES_NAME: "Industries Name",
			MODULE_NAME: "Industries",
			CREATE_TITLE: "Create New Industries"
		},
		PARTNER_TYPE: {
			LISTING_TITLE: "Partner Type Listing",
			EDIT_TITLE: "Edit Partner Type Detail",
			DETAIL_TITLE: "Partner Type Detail",
			BTN_CREATE: "Create New Partner Type",
			LBL_PARTNER_TYPE_CODE: "Partner Type Code",
			LBL_PARTNER_TYPE_NAME: "Partner Type Name",
			COL_PARTNER_TYPE_CODE: "Partner Type Code",
			COL_PARTNER_TYPE_NAME: "Partner Type Name",
			MODULE_NAME: "Partner Type",
			CREATE_TITLE: "Create New Partner Type"
		},
		CUSTOMER_MASTER_DATA: {
			LISTING_TITLE: "Customer Master Data Listing",
			EDIT_TITLE: "Edit Customer Master Data Detail",
			DETAIL_TITLE: "Customer Master Data Detail",
			BTN_CREATE: "Create New Customer Master Data",
			//LBL_PARTNER_TYPE_CODE: "Partner Type Code",
			//LBL_PARTNER_TYPE_NAME: "Partner Type Name",
			//COL_PARTNER_TYPE_CODE: "Partner Type Code",
			//COL_PARTNER_TYPE_NAME: "Partner Type Name",
			MODULE_NAME: "Customer Master Data",
			CREATE_TITLE: "Create Customer Master Data"
		},
		POSTCODES: {
			LISTING_TITLE: "Postcode Listing",
			CREATE_TITLE: "Create New Postcode",
			DETAIL_TITLE: "Postcode Detail",
			EDIT_TITLE: "Edit Postcode Detail",
			MODULE_NAME: "Postcode",
			BTN_CREATE: "Create New Postcode",

			LBL_POSTCODE: "Postcode",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			LBL_DISTIRCT: "District",
			LBL_POSTCODE_OUTSKIRT: "Outskirt",

			PH_ENTER_POSTCODE: "Enter Postcode",

			COL_POSTCODE: "Postcode",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_DISTRICT: "District",
			MSG_FAILED_TO_UPDATE: "City has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully edited",

			MSG_FAILED_CREATE_POSTCODE: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		},
		TAX_TYPE: {
			CREATE_TITLE: "Create New Tax Type",
			DETAIL_TITLE: "Tax Type Detail",
			EDIT_TITLE: "Edit Tax Type",
			LISTING_TITLE: "Tax Type Listing",
			MODULE_NAME: "Tax Type",
			BTN_CREATE: "Create New Tax Type",

			LBL_TAX_TYPE_ID: "Tax Type Code",
			LBL_TAX_TYPE_NAME: "Tax Type Name",
			LBL_COUNTRIES: "Countries",

			PH_ENTER_TAX_TYPE_ID: "Enter Tax Type Code",
			PH_ENTER_TAX_TYPE_NAME: "Enter Tax Type Name",

			COL_TAX_TYPE_ID: "Tax Type Code",
			COL_TAX_TYPE_NAME: "Tax Type Name",

			MSG_FAILED_TO_UPDATE: "Tax Type has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "Tax Type has been successfully edited",

			MSG_FAILED_CREATE_TAX_TYPE: "New Tax Type has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Tax Type has been successfully to created",
			MSG_FAILED_DELETE: "Tax Type failed to delete",

			MSG_SUCCESS_TO_DELETE: "Tax Type record successful to delete"
		},
		CURRENCIES: {
			CREATE_TITLE: "Create New Currency",
			DETAIL_TITLE: "Currency Detail",
			EDIT_TITLE: "Edit Currency",
			LISTING_TITLE: "Currencies Listing",
			MODULE_NAME: "Currencies",
			BTN_NAME: "Currency",

			LBL_CURRENCIES_NAME: "Currency Name",
			LBL_CURRENCIES_CODE: "Currency Code",
			LBL_SYMBOL_LEFT: "Symbol Left",
			LBL_SYMBOL_RIGHT: "Symbol Right",
			LBL_DECIMAL_PLACE: "Exchange Rate Decimal Place",

			COL_CURRENCY_CODE: "Currency Code",
			COL_CURRENCY_NAME: "Currency Name",
			LBL_EXCHANGE_CURRENCY: "Exchange Currency Rate",

			COL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Rate",
			BTN_DAILY_CURRENCY_RATE: "Daily Currency Rate"
		},
		COMPANY: {
			CREATE_TITLE: "Create New Company",
			DETAIL_TITLE: "Company Detail",
			EDIT_TITLE: "Edit Company",
			MODULE_NAME: "Company",
			LISTING_TITLE: "Company Listing",

			LBL_COMPANY_NAME: "Company Name",
			LBL_COMPANY_CODE: "Company Code",
			LBL_COMPANY_REGISTER_NUMBER: "Company Business Register Number",
			LBL_COMPANY_EMAIL: "Email",
			LBL_COMPANY_GROUP_NAME: "Group Name",
			LBL_TAX_CODE: "Tax Code",

			LBL_COMPANY_GROUP: "Company Group",
			LBL_COMPANY_GROUP_CODE: "Company Group Code",

			LBL_CONTACT_NUMBER: "Contact Number",
			LBL_FAX_NUMBER: "Fax Number",

			LBL_BUSINESS_NATURE: "Business Nature",
			LBL_WEBSITE: "Website",
			LBL_COMPANY_ADDRESS: "Company Address",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_COMPANY_LOGO: "Company Logo",
			LBL_TAX_TYPE: "Tax Type",
			LBL_TAX_REGISTERDATE: "Register Date",
			LBL_TAX_NUMBER: "Register Number",
			LBL_TAX_REGISTER_TYPE: "Tax Register Type",
			LBL_TAX_EFFECTIVEDATE: "Effective Date",
			LBL_TAX_VALIDATIONDATE: "Verify Date",
			LBL_TAX_PIC: "Person Incharge",
			LBL_BANK: "Bank(s)",

			COL_COMPANY_GROUP_CODE: "Code",
			COL_GL_SALES_ACCOUNT: "GL Sales Account",
			NO_TAX: "No Tax",

			MSG_FAILED_TOUPLOAD_IMAGE: "Failed To Upload Company Logo.",

			COL_COMPANY_CODE: "Company Code",
			COL_COMPANY_NAME: "Company Name",

			BANK_TABLE: {
				COL_SHOW: "Show",
				COL_ACCOUNT_NAME: "Account Name",
				COL_ACCOUNT_NO: "Account No",
				COL_BANK_NAME: "Bank Name"
			},

			MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_IMAGE_SIZE: "*image size(W x L) 590x208",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		},
		WAREHOUSE: {
			LISTING_TITLE: "Warehouse List",
			CREATE_TITLE: "Create New Warehouse",
			DETAIL_TITLE: "Warehouse Detail",
			EDIT_TITLE: "Edit Warehouse",

			MODULE_NAME: "Warehouse",

			LBL_WAREHOUSE_CODE: "WH Code",
			LBL_WAREHOUSE_NAME: "WH Name",

			COL_WAREHOUSE_CODE: "Warehouse Code",
			COL_WAREHOUSE_NAME: "Warehouse Name",

			LBL_CONTACT: "Contact Person",
			LBL_COMPANY: "Company",
			LBL_WAREHOUSE_ADDRESS: "Address",
			LBL_SUPPORTED_SHIPMENT_TYPE: "Supported Shipment Type",
			LBL_QUOTATION_DESC: "Quotation Desc",
			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_API_URL: "GBIZS WMS API URL",
			LBL_GOODS_RECEIPT_PAYABLE_NOTE_PIC: "Goods Receipt/Payable Note Contact Person",
			LBL_QUOTE_AREA: "Replace {area} with State & City",

			COL_PIC: "Person In Charge",
			COL_CONTACT_NUMBER: "Contact Number",
			COL_WECHAT_ID: "WeChat ID",
			COL_IS_DEFAULT: "Default",
			COL_IS_SHOW: "Show",
			COL_REMARK: "Remark",
			COL_CONTACT_CALLING_CODE: "Contact Number Calling Code",
			LBL_EPO_SPECIAL_REMARK: "EPO Special Remark",
			LBL_EPO_FOOTER_REMARK: "EPO Footer Remark"
		},
		SHIPMENT_TYPE: {
			LISTING_TITLE: "Shipment Type Listing",
			CREATE_TITLE: "Create New Shipment Type",
			DETAIL_TITLE: "Shipment Type Detail",
			EDIT_TITLE: "Edit Shipment Type Detail",

			LBL_SHIPMENT_TYPE_NAME: "Shipment Type Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_CATERGORY_TYPE_COST: "Category Type Cost",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)",

			CHECKBOX_CATERGORY_TYPE_COST_TITLE: "Multiple Route & Shipment Mode",

			MODULE_NAME: "Shipment Type",

			COL_NAME: "Shipment Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type Name",
			MODULE_QUOTATION: "Quotation",
			MODULE_EPO: "EPO"
		},
		SHIPMENT_MODE: {
			LISTING_TITLE: "Shipment Mode Listing",
			CREATE_TITLE: "Create New Shipment Mode",
			DETAIL_TITLE: "Shipment Mode Detail",
			EDIT_TITLE: "Edit Shipment Mode Detail",

			COSTING_STRUCTURE_TITLE: "Base Cost Structure",
			EXCEPTIONAL_COST_STRUCTURE_TITLE: "Exceptional Cost Structure",

			LBL_SHIPMENT_MODE_CODE: "Shipment Mode Code",
			LBL_SHIPMENT_MODE_NAME: "Shipment Mode Name",
			LBL_SHIPMENT_TYPE: "Supported Shipment Type",
			LBL_QUOTATION_REMARK: "{shipment_type} Quotation Remark",
			LBL_COST_SETUP: "Cost Setup?",

			MODULE_NAME: "Shipment Mode",

			COL_CODE: "Shipment Mode Code",
			COL_NAME: "Shipment Mode Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",

			COST_STRUCTURE: {
				LISTING_TITLE: "Costing Structure",
				EDIT_COST_STRUCTURE_TITLE: "Edit Costing Structure",
				BTN_EXCEPTION_COST: "Exptional Cost",
				BTN_BASE_COST: "Base Cost",
				BTN_COST: "Cost Structure",

				COL_M3: "M3",
				COl_CONDITION: "Condition",
				COl_MIN_CHARGES: "Min Charges",
				COl_RATE_TYPE: "Type",
				COl_CHARGES: "Charges",
				COl_ADDITIONAL_CHARGES: "Additional Charges",
				MODULE_NAME: "Cost Structure"
			},
			MODULE_QUOTATION: "Quotation (Default)",
			MODULE_EPO: "EPO (Default)",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)"
		},
		COURIER_COMPANY: {
			LISTING_TITLE: "Courier Company Listing",
			CREATE_TITLE: "Create New Courier Company",
			DETAIL_TITLE: "Courier Company Detail",
			EDIT_TITLE: "Edit Courier Company Detail",

			LBL_COURIER_COMPANY_CODE: "Code",
			LBL_COURIER_COMPANY_NAME: "Name",
			LBL_COURIER_COMPANY_COUNTRY: "Country",
			LBL_COURIER_COMPANY_DESCRIPTION: "Description",

			MODULE_NAME: "Courier Company",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		CATEGORY_GROUP: {
			LISTING_TITLE: "Category Type Listing",
			CREATE_TITLE: "Create New Category Type",
			DETAIL_TITLE: "Category Type Detail",
			EDIT_TITLE: "Edit Category Type Detail",

			LBL_CATEGORY_GROUP_CODE: "Category Type Code",
			LBL_CATEGORY_GROUP_NAME: "Category Type Name",
			LBL_CATEGORY_TYPE_DESCRIPTION: "Category Type Description ",
			BTN_SET_SENSITIVE: "Set Sensitive As Default Wording",
			MODULE_NAME: "Category Type",

			COL_CATEGORY_GROUP_CODE: "Category Type Code",
			COL_CATEGORY_GROUP_NAME: "Category Type Name",
			SENSITIVE_TITLE: "敏感"
		},
		CATEGORY_PARENT: {
			LISTING_TITLE: "Product Category (Parent) Listing",
			CREATE_TITLE: "Create New Product Category (Parent)",
			DETAIL_TITLE: "Product Category (Parent) Detail",
			EDIT_TITLE: "Edit Product Category (Parent) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_GROUP: "Product Category Type",
			LBL_CATEGORY_PARENT: "Product Category Parent",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			ROOT_SELECTION: "Parent"
		},
		SENSITIVE_TYPE: {
			LISTING_TITLE: "Sensitive Type Listing",
			CREATE_TITLE: "Create New Sensitive Type",
			DETAIL_TITLE: "Sensitive Type Detail",
			EDIT_TITLE: "Edit Sensitive Type Detail",

			LBL_CATEGORY_GROUP_TYPE_CODE: "Code",
			LBL_CATEGORY_GROUP_TYPE_NAME: "Name",
			LBL_CATEGORY_GROUP_TYPE_DESCRIPTION: "Description",

			MODULE_NAME: "Sensitive Type",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		CATEGORY: {
			LISTING_TITLE: "Product Category (Lvl 2) Listing",
			CREATE_TITLE: "Create New Product Category (Lvl 2)",
			DETAIL_TITLE: "Product Category (Lvl 2) Detail",
			EDIT_TITLE: "Edit Product Category (Lvl 2) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_GROUP: "Product Category Type",
			LBL_CATEGORY_PARENT: "Product Category Parent",
			LBL_INVOICE_DESC: "Invoice Description",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			ROOT_SELECTION: "Parent"
		},
		MOVEMENT_TYPE: {
			LISTING_TITLE: "Movement Type Listing",
			CREATE_TITLE: "Create New Movement Type",
			DETAIL_TITLE: "Movement Type Detail",
			EDIT_TITLE: "Edit Movement Type",

			MODULE_NAME: "Movement Type",

			LBL_MOVEMENT_TYPE_CODE: "Movement Type Code",
			LBL_MOVEMENT_TYPE_NAME: "Movement Type Name",
			LBL_MOVEMENT_TYPE_WAREHOUSE: "Warehouse",
			LBL_REQUIRED: "Required",
			LBL_OPTIONAL: "Optional",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_QUOTATION_SETTING: "Quotation Settings",
			COL_CODE: "Movement Type Code",
			COL_NAME: "Movement Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",
			COL_SUPPORT_SHIPMENT: "Support Shipment",
			COL_SUPPORT_WAREHOUSE: "Warehouse",

			LBL_SUPPOPRT_SHIPMENT: "Support Shipment",
			QUOTATION_SETTINGS_STANDARD: "Apply All Warehouse",
			QUOTATION_SETTINGS_OTHER: "Only Apply Other Country",
			QUOTATION_SETTING: {
				FORWARDER: "Forwarder",
				SUPPLIER: "Supplier",
				LPT: "Loading Port",
				DPT: "Destination Port",
				PRD_MEASUREMENT: "Product Measurement",
				PRD_AMOUNT: "Product Amount",
				REFERENCE_TYPE: "Reference Type",
				PRD_BRAND: "Product Brand",
				INSURANCE: "Parcel Lost Guarantee",
				SINGLE_PRODUCT: "Single Product",
				SHIPPING_TERMS: "Shipment Terms",
				WAREHOUSE: "Warehouse"
			},
			MODULE_QUOTATION: "Quotation",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)",
			BTN_ALL_WAREHOUSE: "All Warehouse"
		},
		SHIPMENT_TERMS: {
			LISTING_TITLE: "Shipment Terms Listing",
			CREATE_TITLE: "Create New Shipment Terms",
			DETAIL_TITLE: "Shipment Terms Detail",
			EDIT_TITLE: "Edit Shipment Terms",
			MODULE_NAME: "Shipment Terms",

			LBL_SHIPMENT_TERMS_CODE: "Shipment Terms Code",
			LBL_SHIPMENT_TERMS_NAME: "Shipment Terms Name",

			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_DESCRIPTION: "Description",

			COL_CODE: "Shipment Terms Code",
			COL_NAME: "Shipment Terms Name",
			COL_MOVEMENT_TYPE: "Movement Type"
		},
		REFERENCE_TYPE: {
			LISTING_TITLE: "Reference Type Listing",
			CREATE_TITLE: "Create New Reference Type",
			DETAIL_TITLE: "Reference Type Detail",
			EDIT_TITLE: "Edit Reference Type",

			MODULE_NAME: "Reference Type",

			LBL_REF_TYPE_CODE: "Reference Type Code",
			LBL_REF_TYPE_NAME: "Reference Type Name",
			LBL_QUOTATION: "Quotation",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			COL_CODE: "Reference Type Code",
			COL_NAME: "Reference Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type"
		},
		AREA: {
			CREATE_TITLE: "Create New Area",
			LISTING_TITLE: "Area Listing",
			DETAIL_TITLE: "Area Detail",
			EDIT_TITLE: "Edit Area",
			MODULE_NAME: "Area",
			SEARCH_POSTCODE_TITLE: "Search Postcode By Range",

			LBL_AREA_CODE: "Area Code",
			LBL_AREA_NAME: "Area Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "States",
			LBL_POSTCODE_RANGE: "Postcode Range",
			LBL_OUTSKIRT: "Outskirt",

			COL_STATES: "State",
			COL_POSTCODE: "Postcode",
			LBL_CHOOSE_POSTCODE: "Choose Postcode",
			MSG_CONFIRM_POSTCODE: "This will replace postcode selected in previously. Are you sure contiue?",
			COL_AREA_CODE: "Area Code",
			COL_AREA_NAME: "Area Name",
			COL_COUNTRY: "Country Name",
			CONFIRMATION_CHANGE_COUNTRY: "State and Postcode selection will be reset. Are you sure to continue?",

			MSG_STATE_DUPLICATE: "State duplicate.",
			MSG_AREA_CODE_DUPLICATE: "{code} is not available."
		},
		CREDIT_TERMS: {
			LISTING_TITLE: "Credit Terms Listing",
			CREATE_TITLE: "Create New Credit Terms",
			DETAIL_TITLE: "Credit Terms Detail",
			EDIT_TITLE: "Edit Credit Terms Detail",

			LBL_CREDIT_TERMS_CODE: "Credit Terms Code",
			LBL_CREDIT_TERMS_DESC: "Credit Terms Desc",
			LBL_CREDIT_TERMS_TYPE: "Credit Terms Type",
			LBL_CREDIT_TERMS_DAY: "Credit Terms Day",
			LBL_CREDIT_TERMS_NOTICE_TYPE: "Notice Type",
			LBL_CREDIT_TERMS_PAYMENT_TERMS_QUOTE_DESC: "Payment Terms Quote Desc",
			LBL_CREDIT_TERMS_CREDIT_TERMS_QUOTE_DESC: "Credit Terms Quote Desc",

			MODULE_NAME: "Credit Terms",

			COL_CREDIT_TERMS_CODE: "Credit Terms Code",
			COL_CREDIT_TERMS_DESC: "Credit Terms Desc",
			COL_CREDIT_TERMS_DAY: "Credit Terms Day",
			COL_NOTICE_TYPE: "Notice Type",

			CREDIT_TERMS_TYPE_CODE: {
				PURCHASE_AMOUNT: "PA",
				SERVICE_CHARGE: "SC",
				TAX: "TA"
			},

			CREDIT_TERMS_TYPE_SELECTION: {
				PURCHASE_AMOUNT: "PA (Purchase Amount)",
				SERVICE_CHARGE: "SC (Service Charge)",
				TAX: "TA (Tax)"
			}
		},
		/* Wesly 04/05/2021 Start*/
		VALIDITY: {
			CREATE_TITLE: "Create Validity",
			MODULE_NAME: "validity",
			EDIT_TITLE: "Edit Validity",
			DETAIL_TITLE: "Validity Detail",
			LISTING_TITLE: "Validity Listing",

			LBL_VALIDITY_CODE: "Validity Code",
			LBL_VALIDITY_NAME: "Validity Name",
			LBL_VALIDITY_DAY: "Validity Day",
			LBL_DEFAULT: "Default",
			LBL_QUOTATION: "Quotation",
			LBL_INSTRUCTION: "Instruction",

			COL_VALIDITY_CODE: "Validity Code",
			COL_VALIDITY_NAME: "Validity Name"
		},
		/* Wesly 04/05/2021 End*/
		/* Wesly 09/05/2021 Indemnify End*/
		INDEMNIFY: {
			CREATE_TITLE: "Create Indemnify",
			MODULE_NAME: "indemnify",
			EDIT_TITLE: "Edit Indemnify",
			DETAIL_TITLE: "Indemnify Detail",
			LISTING_TITLE: "Indemnify Listing",

			LBL_INDEMNIFY_CODE: "Indemnify Code",
			LBL_INDEMNIFY_NAME: "Indemnify Name",

			COL_INDEMNIFY_CODE: "Indemnify Code",
			COL_INDEMNIFY_NAME: "Indemnify Name",

			LBL_INDEMNIFY_REMARK: "Indemnify Remark"
		},
		/* Wesly 04/05/2021 Indemnify End*/
		/* Wesly 09/05/2021 Freight Charges End*/
		FREIGHT_CHARGES: {
			CREATE_TITLE: "Create Freight Charges",
			MODULE_NAME: "Freight Charges",
			EDIT_TITLE: "Edit Freight Charges",
			DETAIL_TITLE: "Freight Charges Detail",
			LISTING_TITLE: "Freight Charges Listing",

			LBL_FREIGHT_CHARGES_CODE: "Freight Charges Code",
			LBL_FREIGHT_CHARGES_NAME: "Freight Charges Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_TYPE_DESC: "{name} shipment description",

			COL_FREIGHT_CHARGES_CODE: "Freight Charges Code",
			COL_FREIGHT_CHARGES_NAME: "Freight Charges Name"
		},
		/* Wesly 04/05/2021 Freight Charges End*/
		NOTICE_TYPE_GROUP: {
			LISTING_TITLE: "Notice Type Group Listing",
			CREATE_TITLE: "Create New Notice Type Group",
			DETAIL_TITLE: "Notice Type Group Detail",
			EDIT_TITLE: "Edit Notice Type Group Detail",

			LBL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			LBL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc",

			MODULE_NAME: "Notice Type Group",

			COL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			COL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc"
		},
		NOTICE_TYPE: {
			LISTING_TITLE: "Notice Type Listing",
			CREATE_TITLE: "Create New Notice Type",
			DETAIL_TITLE: "Notice Type Detail",
			EDIT_TITLE: "Edit Notice Type Detail",

			LBL_NOTICE_TYPE_CODE: "Notice Type Code",
			LBL_NOTICE_TYPE_DESC: "Notice Type Desc",
			LBL_NOTICE_TYPE_GROUP: "Notice Type Group",
			LBL_NOTICE_TYPE_PAYMENT_TERMS_DESCRIPTION: "Quotation Payment Terms Description",
			LBL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			LBL_ORDER_RECEIPT: "Order Receipt",
			LBL_CASH_PAYMENT: "Cash Payment",
			LBL_DUE_DAYS: "Due Days",
			LBL_EPO_REMARK: "EPO Remark",
			LBL_WAREHOUSE: "Warehouse",
			LBL_EPO_IMPORTANT_NOTICE: "Important Notice",
			LBL_EPO_MARKING_REMARK: "Marking Remark",
			LBL_SUPPLIER_BANK: "Supplier Bank Required",
			LBL_EPO_SUPPLIER_BANK_REMARK: "Supplier Bank Remark",
			MODULE_NAME: "Notice Type",

			COL_NOTICE_TYPE_CODE: "Notice Type Code",
			COL_NOTICE_TYPE_DESC: "Notice Type Desc",
			COL_NOTICE_TYPE_GROUP: "Notice Type Group",
			COL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			COL_ORDER_RECEIPT: "Order Receipt",
			COL_CASH_PAYMENT: "Cash Payment",
			COL_DUE_DAYS: "Due Days",

			BTN_ADD_NEW_EPO_REMARK: "Add New EPO Remark",
			BTN_ADD_REMOVE_EPO_REMARK: "Remove EPO Remark"
		},
		DOC_TYPE: {
			LISTING_TITLE: "Doc Type Listing",
			CREATE_TITLE: "Create New Doc Type",
			DETAIL_TITLE: "Doc Type Detail",
			EDIT_TITLE: "Edit Doc Type Detail",

			LBL_DOC_TYPE_CODE: "Doc Type Code",
			LBL_DOC_TYPE_DESC: "Doc Type Desc",
			LBL_DOC_TYPE_NOTICE_TYPE: "Notice Type",
			LBL_POSTING_SIDE: "Posting Site",
			LBL_USE_SAME_ID: "Use Same ID",

			MODULE_NAME: "Doc Type",

			COL_DOC_TYPE_CODE: "Doc Type Code",
			COL_DOC_TYPE_DESC: "Doc Type Desc",
			COL_POSTING_SIDE: "Posting Side",
			COL_USE_SAME_ID: "Use Same ID",

			SELECTION: {
				NO_POSTING: "No Posting",
				CREDIT: "Credit",
				DEBIT: "Debit"
			}
		},
		DOC_NUM: {
			LISTING_TITLE: "Doc Numbering Listing",
			CREATE_TITLE: "Create New Doc Numbering",
			DETAIL_TITLE: "Doc Numbering Detail",
			EDIT_TITLE: "Edit Doc Numbering Detail",

			LBL_NUM_DESC: "Doc Numbering Desc",
			LBL_DOC_TYPE: "Doc Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group?",
			LBL_COMPANY_SUBGROUP: "Company Sub Group",
			LBL_NUM_PREFIX: "Numbering Prefix",
			LBL_NUM_PREFIX_WITH_WAREHOUSE_COUNTRY: "Prefix with Warehouse Country?",
			LBL_NUM_POSTFIX: "Numbering Postfix",
			LBL_NUM_RESET: "Reset",
			LBL_NUM_DIGIT_COUNT: "Digit Count",
			LBL_NUM_LAST_NUM_INT: "Last Numbering (In Digit)",
			LBL_NUM_LAST_NUM_FORMAT: "Last Numbering (In Full)",

			MODULE_NAME: "Doc Numbering",

			COL_NUM_DESC: "Doc Numbering Desc",
			COL_DOC_TYPE: "Doc Type",
			COL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group ?",
			COL_COMPANY_SUBGROUP: "Company Sub Group",
			COL_NUM_PREFIX: "Numbering Prefix",
			COL_NUM_POSTFIX: "Numbering Postfix",
			COL_NUM_RESET: "Reset",
			COL_NUM_DIGIT_COUNT: "Digit Count",
			COL_NUM_LAST_NUM_INT: "Last Numbering (In Number)",
			COL_NUM_LAST_NUM_FORMAT: "Last Numbering (In Full)",

			SELECTION: {
				NO_RESET: "None",
				BY_YEAR: "By Year",
				BY_MONTH: "By Month"
			},

			DOC_NUM_FORMAT: "{prefix}{date}{number}{postfix}"
		},
		INSTRUCTION_TYPE: {
			CREATE_TITLE: "Create New Instruction Type",
			DETAIL_TITLE: "Instruction Type Detail",

			LBL_INSTRUCTION_TYPE: "Instruction Type Code",
			LBL_NAME: "Instruction Type Name",
			LBL_STOP_RECEIVED: "Stop Received",
			LBL_STOP_PAYMENT: "Stop Payment",
			LBL_STOP_LOADING: "Stop Loading",
			LBL_COLOR_INDICATOR: "Color Indicator",
			LBL_REMARK: "Remark",

			COL_INSTRUCTION_TYPE: "Instruction Type Code",
			COL_INSTRUCTION_TYPE_NAME: "Instruction Type Name",
			COL_COLOR_INDICATOR: "Color Indicator",

			MODULE_NAME: "Instruction Type",
			LISTING_TITLE: "Intruction Type",
			EDIT_TITLE: "Edit Intruction Type"
		},
		SPECIAL_INSTRUCTION_BY_MARKING: {
			CREATE_TITLE: "Create New Special Instruction By Marking",
			DETAIL_TITLE: "Special Instruction By Marking Detail",

			LBL_SPECIAL_INSTRUCTION_BY_MARKING: "New Special Instruction By Marking",
			LBL_INSTRUCTION_REF_NO: "Instruction Ref No",
			LBL_INSTRUCTION_DATE: "Instruction Date",

			LBL_REMARK: "Remark",
			LBL_INSTRUCTION_TYPE_REMARK: "Instruction Type Remark",

			COL_INSTRUCTION_TYPE: "Instruction Type",
			COL_INSTRUCTION_TYPE_NAME: "Name",
			COL_INSTRUCTION_REF_NO: "Instruction Ref No",
			COL_VALID_DAY: "Valid Day",
			COL_DELIVERY_ID: "Delivery ID",
			COL_INSTRUCTION_DATE: "Instruction Date",
			COL_MARKING: "Marking",
			COL_START_FROM: "Start From",
			COL_NOTICE_TYPE: "Notice Type",

			MODULE_NAME: "Special Instruction By Marking",
			DELIVERY_ID: "Delivery ID",
			WAREHOUSE: "Warehouse",
			INSTRUCTION_REF_NO: "Instruction Ref No",
			INSTRUCTION_DATE: "Instruction Date",
			MARKING: "Marking",
			COMPANY_GROUP: "Company Group",
			NOTICE_TYPE: "Notice Type",
			START_FROM: "Start From",
			VALID_DATE: "Valid Day",
			INSTRUCTION_TYPE: "Instruction Type",
			APPLY_TO_DEL_ADD: "Apply To All Del Add",

			LISTING_TITLE: "Special Instruction By Marking",
			EDIT_TITLE: "Edit Special Instruction By Marking",
			PH_ENTER_INSTRUCTION_DATE_TO: "Instruction Date To",
			PH_ENTER_INSTRUCTION_DATE_FROM: "Instruction Date From",
			PH_ENTER_INSTRUCTION_DATE: "Instruction Date",
			PH_ENTER_START_FROM: "Start From"
		},
		SPECIAL_INSTRUCTION_BY_EPO: {
			CREATE_TITLE: "Create New Special Instruction By EPO",
			DETAIL_TITLE: "Special Instruction EPO Detail",

			LBL_SPECIAL_INSTRUCTION_BY_EPO: "New Special Instruction By EPO/PO/SO",
			LBL_INSTRUCTION_REF_NO: "Instruction Ref No",
			LBL_INSTRUCTION_DATE: "Instruction Date",

			LBL_REMARK: "Remark",

			COL_INSTRUCTION_TYPE: "Instruction Type",
			COL_INSTRUCTION_TYPE_NAME: "Name",
			COL_INSTRUCTION_REF_NO: "Instruction Ref No",
			COL_VALID_DAY: "Valid Day",
			COL_DELIVERY_ID: "Delivery ID",
			COL_INSTRUCTION_DATE: "Instruction Date",
			COL_MARKING: "Marking",
			COL_START_FROM: "Start From",
			COL_NOTICE_TYPE: "Notice Type",
			COL_EPO_NO: "EPO/PO No.",
			COL_REMARK: "Remark",
			COL_CASH_PAY: "Cash Payment",
			COL_EST_DELIVERY_DATE: "Est Delivery Date",
			COL_PO_AMOUNT: "PO Amount",
			COL_CURRANCY_RATE: "Currancy Rate",

			BTN_ADD_NEW_EPO: "Add New EPO",

			MODULE_NAME: "Special Instruction By EPO",
			DELIVERY_ID: "Delivery ID",
			WAREHOUSE: "Warehouse",
			INSTRUCTION_REF_NO: "Instruction Ref No",
			INSTRUCTION_DATE: "Instruction Date",
			MARKING: "Marking",
			COMPANY_GROUP: "Company Group",
			NOTICE_TYPE: "Notice Type",
			START_FROM: "Start From",
			VALID_DATE: "Valid Day",
			INSTRUCTION_TYPE: "Instruction Type",
			APPLY_TO_DEL_ADD: "Apply To All Del Add",

			LISTING_TITLE: "Special Instruction By EPO",
			EDIT_TITLE: "Edit Special Instruction By EPO",
			PH_ENTER_INSTRUCTION_DATE_TO: "Instruction Date To",
			PH_ENTER_INSTRUCTION_DATE_FROM: "Instruction Date From",
			PH_ENTER_INSTRUCTION_DATE: "Instruction Date",
			PH_ENTER_START_FROM: "Start From",

			INSTRUCTION_DATE_IS_REQUIRED: "Instruction Date is Required",
			START_FORM_IS_REQUIRED: "Start From is Required",
			MARKING_IS_REQUIRED: "Marking is Required",
			VALIDITY_IS_REQUIRED: "Valid Day is Required",
			DELIVERY_ADDRESS_IS_REQUIRED: "Delivery Address is Required",
			NOTICE_TYPE_IS_REQUIRED: "Notice Type is Required",
			WAREHOUSE_IS_REQUIRED: "Warehouse is Required",
			INSTRUCTION_TYPE_IS_REQUIRED: "Instruction Type is Required",
			PO_DIFFERENT_MARKING: "This {epo_no} EPO No. belong other customer marking."
		},
		WAREHOUSE_LOCATION: {
			MODULE_NAME: "Warehouse Location",
			LISTING_TITLE: "Warehouse Location Listing",
			LBL_WAREHOUSE_LOCATION_CODE: "Location Code",
			LBL_WAREHOUSE_LOCATION_NAME: "Location Name",
			LBL_WAREHOUSE: "Warehouse",
			CREATE_TITLE: "Create New Warehouse Location",
			DETAIL_TITLE: "Warehouse Location Detail",
			EDIT_TITLE: "Edit Warehouse Location",

			COL_LOCATION_CODE: "Location Code",
			COL_LOCATION_NAME: "Location Name",
			COL_WAREHOUSE_NAME: "Warehouse Name"
		},
		CURRENCIES_RATE: {
			TITLE: "Currency Rate",
			LABEL_DATE: "Date",
			LABEL_CURRENCY: "Currency",
			LABEL_EXCHANGE_CURRENCY: "Exchange Currency",
			ERROR_MESSAGE_GET_CURRENCY_LIST: "No Currency Record Has Been Found",
			LABEL_EXCHANGE_CURRENCY_RATE: "Exchange Currency Rate",
			MESSAGE_SUCCESS_SAVE: "New Currency Rate successfull to save",
			MESSAGE_FAILED_SAVE: "Failed to Save New Currency Date",
			BTN_CURRENCT_RATE_HISTORY: "Currency Rate History",
			HISTORY_TITLE: "Currency Rate History",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "To",

			COL_DATE: "Date",
			COL_CURRENCY: "Currency",
			COL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Rate"
		},
		TAX: {
			LISTING_TITLE: "Tax Listing",
			CREATE_TITLE: "Create New Tax",
			DETAIL_TITLE: "Tax Detail",
			EDIT_TITLE: "Edit Tax Detail",

			MODULE_NAME: "Tax",

			LBL_TAX_CODE: "Tax Code",
			LBL_TAX_NAME: "Tax Name",
			LBL_TAX_TYPE: "Tax Type",
			LBL_CHARGES_TO_COST: "Charges To Cost",
			LBL_FOR_ADJUSTMENT: "For Adjustment",
			LBL_GST03: "GST03",
			LBL_GST03_REMARK: "GST03 Remark",
			LBL_TAX_RATE: "Tax Rate (%)",
			LBL_TAX_QUOTE_DESC: "Tax Quote Remark",

			COL_TAX_CODE: "Tax Code",
			COL_TAX_NAME: "Tax Name",
			COL_TAX_TYPE: "Tax Type",
			COL_TAX_RATE: "Tax Rate (%)"
		},
		DAY_CONVERSION: {
			LISTING_TITLE: "Day Conversion Listing",
			CREATE_TITLE: "Create New Day Conversion",
			DETAIL_TITLE: "Day Conversion Detail",
			EDIT_TITLE: "Edit Day Conversion",
			MODULE_NAME: "Day Conversion",

			LBL_NUMBER_OF_DAY: "Day",
			LBL_DAY_ENGLISH_WORD: "Day's Word",

			COL_DAY: "Day",
			COL_DAY_OF_WORD: "Day's Word"
		},
		QUOTATION_SUBJECT: {
			LISTING_TITLE: "Quotation Subject Listing",
			CREATE_TITLE: "Create New Quotation Subject",
			DETAIL_TITLE: "Quotation Subject Detail",
			EDIT_TITLE: "Edit Quotation Subject",

			MODULE_NAME: "Quotation Subject",
			LBL_QUOTATION_SUBJECT_CODE: "Quotation Subject Code",
			LBL_QUOTATION_SUBJECT_NAME: "Quotation Subject Name",
			LBL_QUOTATION_SUBJECT_TITLE: "Quotation Subject Title",
			LBL_QUOTATION_SUBJECT_CONTENT: "Quotation Subject Content",

			COL_CODE: "Quotation Subject Code",
			COL_NAME: "Quotation Subject Name",
			COL_SUBJECT_CONTENT: "Subject Description",

			LBL_QUOTATION_SUBJECT_DEFAULT: "Default for",
			NEW_QUOTATION_TITLE: "New Quotation",
			REVISED_QUOTATION_TITLE: "Revise Quotation"
		},
		CUSTOMER_REJECT_QUOTE_REASON: {
			LISTING_TITLE: "Customer Reject Reason Listing",
			CREATE_TITLE: "Create New Customer Reject Reason",
			DETAIL_TITLE: "Customer Reject Reason Detail",
			EDIT_TITLE: "Edit Customer Reject Reason",

			MODULE_NAME: "Customer Reject Reason",

			COL_REJECT_REASON_CODE: "Reject Reason Code",
			COL_REJECT_REASON_NAME: "Reject Reason Name",

			LBL_REJECT_REASON_CODE: "Reject Reason Code",
			LBL_REJECT_REASON_NAME: "Reject Reason Name"
		},
		ADMIN_REJECT_QUOTE_REASON: {
			LISTING_TITLE: "Admin Reject Reason Listing",
			CREATE_TITLE: "Create New Admin Reject Reason",
			DETAIL_TITLE: "Admin Reject Reason Detail",
			EDIT_TITLE: "Edit Admin Reject Reason",

			MODULE_NAME: "Admin Reject Reason",

			COL_REJECT_REASON_CODE: "Reject Reason Code",
			COL_REJECT_REASON_NAME: "Reject Reason Name",

			LBL_REJECT_REASON_CODE: "Reject Reason Code",
			LBL_REJECT_REASON_NAME: "Reject Reason Name"
		},
		REVERT_QUOTE_REASON: {
			LISTING_TITLE: "Revert Reason Listing",
			CREATE_TITLE: "Create New Revert Reason",
			DETAIL_TITLE: "Revert Reason Detail",
			EDIT_TITLE: "Edit Revert Reason",

			MODULE_NAME: "Revert Reason",

			COL_REVERT_REASON_CODE: "Revert Reason Code",
			COL_REVERT_REASON_NAME: "Revert Reason Name",

			LBL_REVERT_REASON_CODE: "Revert Reason Code",
			LBL_REVERT_REASON_NAME: "Revert Reason Name"
		},
		REMARK_ID: {
			LISTING_TITLE: "Remark ID Listing",
			CREATE_TITLE: "Create New Remark ID",
			DETAIL_TITLE: "Remark ID Detail",
			EDIT_TITLE: "Edit Remark ID Detail",

			LBL_REMARK_ID_NAME: "Name",
			LBL_REMARK_ID_DESCRIPTION: "Description",

			MODULE_NAME: "Remark ID",

			COL_NAME: "Remark ID Name"
		},
		CUSTOMER_UPDATE_LOG_REJECT_INFO: {
			LISTING_TITLE: "Customer Update Log Reject Info Listing",
			CREATE_TITLE: "Create New Customer Update Log Reject Info",
			DETAIL_TITLE: "Customer Update Log Reject Info Detail",
			EDIT_TITLE: "Edit Customer Update Log Reject Info Detail",
			TITLE_CREATE: "Create New Customer Update Log Reject Info",

			LBL_REJECT_INFO_NAME: "Customer Update Log Reject Info Name",
			LBL_REJECT_INFO_DESCRIPTION: "Description",

			MODULE_NAME: "Customer Update Log Reject Info",

			COL_NAME: "Customer Update Log Reject Info Name"
		},
		FORWARDER: {
			LISTING_TITLE: "Forwarder Listing",
			CREATE_TITLE: "Create New Forwarder",
			DETAIL_TITLE: "Forwarder Detail",
			EDIT_TITLE: "Edit Forwarder Detail",

			LBL_FORWARDER_CODE: "Code",
			LBL_FORWARDER_NAME: "Name",
			LBL_FORWARDER_DESCRIPTION: "Description",
			LBL_FORWARDER_CONTACT_PIC: "Contact Pic",
			LBL_FORWARDER_CONTACT_NO: "Contact No",

			MODULE_NAME: "Forwarder",

			COL_CODE: "Forwarder Code",
			COL_NAME: "Forwarder Name"
		},
		SHIPPER: {
			LISTING_TITLE: "Shipper Listing",
			CREATE_TITLE: "Create New Shipper",
			DETAIL_TITLE: "Shipper Detail",
			EDIT_TITLE: "Edit Shipper Detail",
			MODULE_NAME: "Shipper",

			LBL_SHIPPER_ID: "Shipper ID",
			LBL_SHIPPER_WAREHOUSE_ID: "Warehouse",
			LBL_SHIPPER_NAME: "Shipper Name",
			LBL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPER_ADDRESS: "Address",
			LBL_SHIPPER_EMAIL: "Email",
			LBL_SHIPPER_TEL_NO: "Tel No.",
			LBL_SHIPPER_FAX_NO: "Fax No.",
			LBL_SHIPPER_POSTCODE: "Postcode.",
			LBL_SHIPPER_OTHER_POSTCODE: "Other Postcode",
			LBL_SHIPPER_CITY_ID: "City",
			LBL_SHIPPER_OTHER_CITY: "Other City",
			LBL_SHIPPER_STATE_ID: "State",
			LBL_SHIPPER_COUNTRY_ID: "Country",
			LBL_SHIPPER_URL: "Url",
			LBL_SHIPPER_REMARK: "Remark",

			COL_SHIPPER_NAME: "Shipper Name",
			COL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPER_EMAIL: "Shipper Email"
		},
		SHIPPING_LINE: {
			LISTING_TITLE: "Shipping Line Listing",
			CREATE_TITLE: "Create New Shipper",
			DETAIL_TITLE: "Shipping Line Detail",
			EDIT_TITLE: "Edit Shipping Line Detail",
			MODULE_NAME: "Shipping Line",

			LBL_SHIPPING_LINE_ID: "Shipping Line ID",
			LBL_SHIPPING_LINE_WAREHOUSE_ID: "Warehouse",
			LBL_SHIPPING_LINE_NAME: "Shipping Line Name",
			LBL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPING_LINE_ADDRESS: "Address",
			LBL_SHIPPING_LINE_EMAIL: "Email",
			LBL_SHIPPING_LINE_TEL_NO: "Tel No.",
			LBL_SHIPPING_LINE_FAX_NO: "Fax No.",
			LBL_SHIPPING_LINE_POSTCODE_ID: "Postcode.",
			LBL_SHIPPING_LINE_OTHER_POSTCODE: "Other Postcode",
			LBL_SHIPPING_LINE_CITY_ID: "City",
			LBL_SHIPPING_LINE_OTHER_CITY: "Other City",
			LBL_SHIPPING_LINE_STATE_ID: "State",
			LBL_SHIPPING_LINE_COUNTRY_ID: "Country",
			LBL_SHIPPING_LINE_URL: "Url",
			LBL_SHIPPING_LINE_REMARK: "Remark",

			COL_SHIPPING_LINE_NAME: "Shipping Line Name",
			COL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPING_LINE_EMAIL: "Shipping Line Email"
		},
		INVALID_QUOTE_REASON: {
			LISTING_TITLE: "Invalid Reason Listing",
			CREATE_TITLE: "Create New Invalid Reason",
			DETAIL_TITLE: "Revert Invalid Detail",
			EDIT_TITLE: "Edit Invalid Reason",

			MODULE_NAME: "Invalid Reason",

			COL_REASON_CODE: "Invalid Reason Code",
			COL_REASON_NAME: "Invalid Reason Name",

			LBL_REASON_CODE: "Invalid Reason Code",
			LBL_REASON_NAME: "Invalid Reason Name"
		},
		SPECIAL_PRODUCT_STATUS: {
			CREATE_TITLE: "Create New Special Product Status",
			LISTING_TITLE: "Special Product Listing",
			MODULE_NAME: "Special Product Status",
			DETAIL_TITLE: "Special Product Status Detail",
			EDIT_TITLE: "Edit Special Product Status",

			LBL_CODE: "Special Product Status Code",
			LBL_NAME: "Special Product Status Name",

			LBL_IS_REJECT: "Reject",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		WAREHOUSE_TYPE: {
			LISTING_TITLE: "Warehouse Type Listing",
			CREATE_TITLE: "Create New Warehouse Type",
			DETAIL_TITLE: "Warehouse Type Detail",
			EDIT_TITLE: "Update Warehouse Type",
			MODULE_NAME: "Warehouse Type",
			LBL_CODE: "Warehouse Type Code",
			LBL_NAME: "Warehouse Type Name",
			LBL_INTERNAL_WAREHOUSE: "Internal Warehouse",
			LBL_DEFAULT: "Default",
			LBL_DESCRIPTION: "Warehouse Type Description",
			COL_WAREHOUSE_TYPE_CODE: "Warehouse Type Code",
			COL_WAREHOUSE_TYPE_NAME: "Warehouse Type Name"
		},
		DISTRICT: {
			LISTING_TITLE: "District Listing",
			CREATE_TITLE: "Create New District",
			DETAIL_TITLE: "District Detail",
			EDIT_TITLE: "Edit District Detail",
			MODULE_NAME: "District",

			LBL_DISTRICT_NAME: "District Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",

			COL_NAME: "Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",

			MSG_FAILED_TO_UPDATE: "City has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully edited",

			MSG_FAILED_CREATE_CITY: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		}
	},
	SALESMAN_MANAGEMENT: {
		MAIN_SALESMAN: {
			CREATE_TITLE: "Create New Main Salesman",
			MODULE_NAME: "Main Salesman",
			EDIT_TITLE: "Edit Main Salesman",
			DETAIL_TITLE: "Main Salesman Detail",
			LISTING_TITLE: "Main Salesman Listing",

			LBL_MSM_CODE: "Main Salesman Code",
			LBL_MSM_NAME: "Main Salesman Name",
			LBL_COMPANY: "Company",

			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_WECHATID: "Wechat ID",

			LBL_SALESMAN_COSTING_GROUP: "Salesman Costing Group",

			COL_GROUP_CODE: "Group Code",
			COL_GROUP_NAME: "Group Name",
			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",
			COL_MSM_CODE: "Main Salesman Code",
			COL_MSM_NAME: "Main Salesman Name",
			BTN_GROUP_LIST: "Group List",

			COST_SETUP: {
				TITLE: "Main Salesman & Salesman Group Cost",

				LBL_MSM: "Main Salesman",
				LBL_SHIPMENT_TYPE: "Shipment Type",
				LBL_MAIN_ROUTE: "Main Route",
				LBL_SHIPMENT_MODE: "Shipment Mode",
				LBL_CATEGORY_GROUP: "Category Type",
				LBL_MAIN_SALESMAN_COST: "Main Salesman Cost",
				LBL_SALESMAN_GROUP_COST: "Salesman Group Cost",
				LBL_COST: "Cost",

				PH_MAIN_SALESMAN_COST: "Markup or %",
				COL_SALESMAN_GROUP: "Salesman Group",
				COL_MARKUP: "Markup"
			},
			MSG: {
				EMAIL_DUPLICATE: "Email Not Available.",
				USERNAME_DUPLICATE: "Username Not Available"
			}
		},
		SALESMAN: {
			LISTING_TITLE: "Salesman Listing",
			DETAIL_TITLE: "Salesman Detail",
			EDIT_TITLE: "Edit Salesman Detail",
			CREATE_TITLE: "Create New Salesman",
			MODULE_NAME: "Salesman",

			LBL_SM_CODE: "Salesman Code",
			LBL_SM_NAME: "Salesman Name",

			COL_SM_CODE: "Salesman Code",
			COL_SM_NAME: "Salesman Name",
			COL_MSM_NAME: "Main Salesman Name",

			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",
			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NO_CALLING_CODE: "Office Number Calling Code",
			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_MOBILE_NO_CALLING_CODE: "Mobile Number Calling Code",
			LBL_WECHATID: "Wechat ID",
			LBL_MAINSALESMAN: "Main Salesman",
			LBL_SALESMAN_COST: "Salesman Cost",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SM_COST_GROUP: "Salesman Group Cost",
			COL_SM_MIN_COST_GROUP: "Salesman Group Min Cost",

			MSG: {
				EMAIL_DUPLICATE: "Email Not Available.",
				USERNAME_DUPLICATE: "Username Not Available",
				BLOCK_REMOVE_MAINSALESMAN: "You are not allow change main salesman or salesman costing group",
				SALESMAN_COST_ROUTE_DUPLICATE: "Salesman Cost route duplicate. You only allow select 1 only from selection",
				NO_COST_SELECTED: "Please select at least a cost.",
				MSG_ROUTE_DUPLICATION: "Salesman Cost Route exists. Please select from exist listing"
			},

			COST_HEADER: {
				COL_ROUTE: "Route",
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_SALESMAN_MIN_GROUP: "Min Cost Group",
				COL_SALESMAN_GROUP: "Cost Group"
			},

			BTN_EXISTING: "Add From Exist",
			BTN_ADD_NEW_COST: "Add New Cost",

			COST_TITLE: "Existing Cost"
		}
	},
	COSTING: {
		CUSTOMER_COST: {
			LISTING_TITLE: "Customer Cost Summary",

			LBL_CATEGORY: "Category",
			LBL_HAS_TAX: "Tax",
			LBL_SPECIAL_COST: "Special Cost",
			LBL_FINANCIAL_RATE: "Financial Rate",
			LBL_ROUTE: "Route",
			LBL_CUSTOMER_COST_GROUP: "Customer Cost Group",
			LBL_CUSTOMER_MIN_COST_GROUP: "Customer Min Cost Group",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_MARKING: "Marking",
			COL_ROUTE: "From/To",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_PRODUCT_CATEGORY: "Product Category (Parent)",
			COL_PRODUCT_CATEGORY_LVL2: "Product Category (Lvl 2)",
			COL_TAX: "Tax",
			COL_SPECIAL_COST: "Special Cost",
			COL_FINANCIAL_RATE: "Financial Rate",
			COL_CUSTOMER_COST_GROUP: "Cost Group",
			COL_COST: "Cost",
			COL_QUOTATION: "Quotation No.",
			COL_PRODUCT_DESC: "Product",
			COL_CUSTOMER_MIN_COST_GROUP: "Min Cost Group",
			COL_CHARGES: "Charges",
			COL_MIN_CHARGES: "Min Charges",
			COL_MIN_CHARGES_MARKUP: "Min Chg Markup",
			COL_MARKUP: "Markup",
			COL_CUSTOMER: "Customer",
			COL_RANGE: "Range",
			COL_TRANSIT_DESC: "Transit Description",
			COL_WAREHOUESE: "Warehouse",
			COL_WEIGHT_COND: "Weight Cond",
			COL_WEIGTH: "Weight",
			COL_CATEGORY_TYPE_COST: "Category Type",
			COL_WEIGHT_ROUNDING: "Weight Rounding",
			COL_FORMULA: "Formula",
			COL_WEIGHT_METHOD: "Method",
			COL_CHARGES_TYPE: "Charges Type",
			COL_CHARGES_NAME: "Charges Name"
		},
		CUSTOMER_COSTING_GROUP: {
			TITLE: "Customer Cost Group Setup",
			MODULE_NAME: "Customer Cost Group Setup",

			COST_GROUP_TAB_TITLE: "Cost Markup",
			MIN_COST_GROUP_TAB_TITLE: "Min Cost Markup",

			COL_GROUP_CODE: "Group Code",
			COL_GROUP_NAME: "Group Name",
			COL_UOM: "Uom",
			COL_MARKUP: "Markup",
			COL_MARKUP_TYPE: "Markup Type"
		},
		/**Start Main Route */
		ROUTE: {
			LISTING_TITLE: "Route Listing",
			CREATE_TITLE: "Create New Route",
			DETAIL_TITLE: "Route Detail",
			EDIT_TITLE: "Edit Route",
			MODULE_NAME: "Route",

			LBL_ROUTE_CODE: "Route Code",
			LBL_ROUTE_NAME: "Route Name",
			LBL_ORIGIN_COUNTRY: "Origin Country",
			LBL_ORIGIN_AREA: "Origin",
			LBL_DESTINATION_AREA: "Destination",
			LBL_DESTINATION_Country: "Destination Country",
			LBL_WAREHOUSE: "Warehouse",
			BTN_BASE_COST: "Base Cost",

			COL_MAIN_ROUTE_CODE: "Route Code",
			COL_MAIN_ROUTE_NAME: "Route Name",
			COL_FROM: "Origin",
			COL_TO: "Destination",
			COL_TRANSIT_ROUTE: "Transit Route",
			MSG_MAIN_ROUTE_DUPLICATE: "Route not allow duplicated. ",
			LBL_TRANSIT_ROUTE: "Transit Route",
			LBL_TRANSIT_DESCRITION: "Transit Description",
			MSG_ROUTE_DUPLICATION: "{code} is not available."
		},
		/**End Route */
		/**Start Exceptional Cost */
		EXCEPTIONAL_COST: {
			LISTING_TITLE: "Exceptional Cost Listing",
			FORM_TITLE: "Exceptional Cost",
			CREATE_TITLE: "Create New Exceptional Cost",
			EDIT_TITLE: "Edit Exceptional Cost",
			DETAIL_TITLE: "Exceptional Cost Detail",
			MODULE_NAME: "Exceptional Cost",
			BUTTON_COST_SETUP: "New Exceptional Cost",

			COL_DESCRIPTION: "Exceptional Cost Type",
			COL_GROUP: "Group",

			LBL_CHARGES_NAME: "Charges Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_CATEGORY_GROUP: "Category Type",
			LBL_SAVE_AS_DEFAULT: "Save As Default",
			LBL_SAVE_AS_DEFAULT_STRUCTURE: "Save As Default Structure",
			LBL_COSTING: "Cost Setup",
			LBL_CHARGES_TITLE: "Charges Title",
			LBL_CHARGES_TYPE: "Charges Type",
			LBL_CHARGES_QUOTE_TITLE: "Quote Title",

			COL_M3: "M3",
			COl_CONDITION: "Condition",
			COl_CONDITION_QUOTE_DESC: "Condition Quote Desc",
			COl_MIN_CHARGES: "Min Charges",
			COl_RATE_TYPE: "Type",
			COl_CHARGES: "Charges",
			COL_CHARGES_NAME: "Charges Name",
			COL_COST: "Cost",
			COl_ADDITIONAL_CHARGES: "Additional Charges",
			COL_CONDIION_START: "Start",
			COL_CONDIION_END: "End",
			COL_SHIPMENT_TYPE: "Shipt. Type",
			COL_SHIPMENT_MODE: "Shipt. Mode",
			PH_ENTER_LENGTH: "Length",
			PH_ENTER_WIDTH: "Width",
			PH_ENTER_HEIGHT: "Height",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",

			VALIDATION: {
				RANGE_START_SMALLER_THAN_END: "Condition Start must be smaller than Condition End"
			}
		},
		/**End Exceptional Cost */
		/**Start Base Cost */
		BASE_COST: {
			MODULE_NAME: "Base Cost",
			TITLE: "Base Cost",
			LISTING_TITLE: "Base Cost Summary Listing",
			EDIT_TITLE: "Edit Base Cost",
			VIEW_TITLE: "View Base Cost",

			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			MODULE_NAME: "Base Cost",
			TITLE: "Base Cost",
			LISTING_TITLE: "Base Cost Listing",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_CATEGORY_GROUP: "Category Type",
			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_START_DAY: "Start Day",
			LBL_LEAD_TIME_END_DAY: "End Day",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_COST_UOM: "Min Charges UOM",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			BTN_BASE_COST_SETUP: "Base Cost Setup",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",
			CREATE_TITLE: "Create New Base Cost",
			BUTTON_NEW_COST: "New Base Cost",
			LBL_MIN_ADJUST_CHARGES: "Min Charges' adjust cost",
			LBL_EXISTING_MIN_CHARGES: "Existing Min Charges",
			LBL_ADJUST_CHARGES: "Adjust Charges",
			LBL_EXISTING_CHARGES: "Existing Charges",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select.",
				ADJUST_COST_GREATER_THEN_EXISTS_COST: "{name} cannot greater than {name1}",
				NEW_MIN_CHARGES_MUST_BE_GREATER_THAN_ZERO: "New Min Charges must be greater than 0",
				NEW_CHARGES_MUST_BE_GREATER_THAN_ZERO: "New Charges must be greater than 0"
			}
		},
		/**End Base Cost */
		/**Start Transit Cost */
		TRANSIT_COST: {
			MODULE_NAME: "Transit Cost",
			TITLE: "Transit Cost",
			LISTING_TITLE: "Transit Cost Summary Listing",
			EDIT_TITLE: "Edit Transit Cost",
			VIEW_TITLE: "View Transit Cost",

			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			MODULE_NAME: "Transit Cost",
			TITLE: "Transit Cost",
			LISTING_TITLE: "Transit Cost Listing",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_CATEGORY_GROUP: "Category Type",
			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_START_DAY: "Start Day",
			LBL_LEAD_TIME_END_DAY: "End Day",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",
			CREATE_TITLE: "Create New Transit Cost",
			BUTTON_NEW_COST: "New Transit Cost",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select."
			}
		},
		/**End Transit Cost */
		/**Start Makring Cost */
		MARKING_COST: {
			MODULE_NAME: "Marking Cost",
			TITLE: "Marking Cost",
			LISTING_TITLE: "Marking Cost Summary Listing",
			EDIT_TITLE: "Edit Marking Cost",
			VIEW_TITLE: "View Marking Cost",

			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges",
				COL_MARKING: "Marking",
				COL_MSM_CODE: "Main Salesman",
				COL_SM_CODE: "Salesman"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_CATEGORY_GROUP: "Category Type",
			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_START_DAY: "Start Day",
			LBL_LEAD_TIME_END_DAY: "End Day",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_COST_UOM: "Min Charges UOM",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			BTN_BASE_COST_SETUP: "Base Cost Setup",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",
			CREATE_TITLE: "Create New Base Cost",
			BUTTON_NEW_COST: "New Marking Cost",
			LBL_MARKING: "Marking",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select."
			}
		},
		/**End Makring Cost */
		/** Start Main Salesman Cost */
		MAIN_SALESMAN_COST: {
			LISTING_TITLE: "Main Salesman Cost Summary Listing",
			BUTTON_COST_SETUP: "New Cost Setup",
			CREATE_TILE: "Create New Main Salesman Cost ",
			MAIN_SALESMAN_DETAIL: "Main Salesman Cost",
			MODULE_NAME: "Cost",

			LBL_MSM: "Main Salesman",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_ROUTE: "Route",
			LBL_COST: "Charges Markup",

			COL_MSM: "Main Salesman",
			COL_SHIPMENT_TYPE: "Shipt. Type",
			COL_SHIPMENT_MODE: "Shipt. Mode",
			COL_ROUTE: "Route",
			COL_MIN_CHARGES: "Min Markup",
			COL_CHARGES: "Markup",

			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate. Please re-select."
			},

			MSG: {
				SUCESS_SAVE: "Main Salesman cost has been save"
			}
		},
		/** End Main Salesman Cost */
		/** Start Salesman Cost Group */
		SALESMAN_COST_GROUP: {
			TITLE: "Salesman Cost Group Setup",
			MODULE_NAME: "Salesman Cost Group",
			LISTING_TITLE: "Salesman Cost Group Listing",
			BUTTON_COST_SETUP: "Cost Setup",
			BUTTON_COST_SETUP_BY_ROUTE_SHIPMENT: "Single Route & Shipment Cost Setup",
			SALESMAN_COST_GROUP_SETUP: "Salesman Cost Group Setup",

			COST_GROUP_TAB_TITLE: "Cost Markup",
			MIN_COST_GROUP_TAB_TITLE: "Min Cost Markup",

			LBL_MAIN_ROUTE: "Main Route",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_COST: "Cost Setup",
			LBL_SALESMAN_GROUP: "Salesman Group",
			LBL_MIN_COST_MARKUP: "Min Cost Markup",
			LBL_COST_MARKUP: "Markup",
			LBL_MARKUP_TYPE: "Markup Type",

			SUMMARY_HEADER: {
				COL_MAIN_ROUTE: "Main Route",
				COL_SHIPMENT_TYPE: "Shipment Type",
				COL_SHIPMENT_MODE: "Shipment Mode",
				COL_SALESMAN_GROUP: "Salesman Group",
				COL_MIN_MARKUP: "Min Markup",
				COL_MARKUP: "Markup"
			}
		},
		/** End Salesman Cost Group */
		/** Start Assign Salesman Cost*/
		SALESMAN_COST: {
			LISTING_TITLE: "Salesman Cost Listing",
			SALESMAN_COST_SETUP_TITLE: "Salesman Cost Setup",
			SEARCH_SALESMAN_TITLE: "Filter List",
			CREATE_TILE: "New Salesman Cost",
			DETAIL_TITLE: "Salesman Cost",
			LBL_ROUTE: "Route",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MAIN_SALESMAN: "Main Salesman",
			LBL_SALESMAN: "Salesman",
			LBL_SALESMAN_COST_GROUP: "Cost Group",
			LBL_SALESMAN_MIN_COST_GROUP: "Min Cost Group",
			LBL_APPLY_ALL: "Apply All",
			LBL_GROUP_SELECTION_NAME: "{name} (Min M/P: {min_markup}, M/P: {markup})",
			MARKUP_VALUE: "{currency}{cost}{uom}",
			LBL_MIN_MARKUP: "Min M/P",
			LBL_MARKUP: "M/P",

			PH_ENTER_SALESMAN_CODE_NAME: "Enter Salesman code or Name",

			SUMMARY_HEADER: {
				COL_SM: "Salesman",
				COL_ROUTE: "Route",
				COL_SHIPMENT_TYPE: "Shipment Type",
				COL_SHIPMENT_MODE: "Shipment Mode",
				COL_SALESMAN_GROUP: "Cost Group",
				COL_MIN_SALESMAN_GROUP: "Min Cost Group",
				COL_MIN_MARKUP: "Min Markup",
				COL_MARKUP: "Markup",
				COL_MSM: "Main Salesman"
			},

			BUTTON_COST_SETUP: "Cost Setup",
			BUTTON_COST_SETUP_ROUTE_MAIN_ROUTE: "Cost Setup By Route & Shipment",
			BTN_SEARCH_SALESMAN: "Search Salesman",

			MSG_CONFIRM_APPLY_ALL: "Other Salesman Cost Group Salesman Selection will remove. Are you sure to continue?",
			MSG_REPLACE_SM_LIST: "This {sm_group} group salesman selection will replace.",
			MSG_REPLACE_SM_LIST_N_APPLY_ALL: "This {sm_group} group salesman selection will replace and Other Salesman Cost Group Salesman Selection will remove.",
			MSG_DUPLICATE_SM: "This {sm_name} assigned already assigned a cost group.",
			MSG_SALESMAN_COST_GROUP_NOT_FOUND: "No Salesman Cost Group record has been found",
			MSG_SALESMAN_RECORD: "No Salesman Records have been found",
			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate . Please re-select."
			},
			MSG: {
				SUCCESS_SAVE: "Salesman Cost has been save"
			}
		},
		/** End Assgin Salesman Group */
		/** Start Category Type Cost */
		CATEGORY_TYPE_COST: {
			TITLE: "Category Type Cost Listing",
			BUTTON_COST_SETUP: "New Cost Setup",
			BUTTON_COST_SETUP_SHIPMENT: "Create New Category Type Cost By Shipment",
			CATEGORY_TYPE_SETUP_TITLE: "Category Type Cost Setup",
			MODULE_NAME: "Category Type Cost",

			LBL_CATEGORY_TYPE_CODE: "Category Type Code",
			LBL_CATEGORY_TYPE_NAME: "Category Type Name",
			LBL_COST: "Cost",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MIN_CHARGES: "Min Cost",
			LBL_CHARGES: "Charges",
			LBL_ROUTE: "Route",

			LBL_COST_SETUP: "Cost Setup",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_MIN_CHARGES_TYPE: "Min Cost Type",
			COL_MIN_CHARGES: "Min Cost",
			COL_CHARGES: "Cost",
			COL_COND: "Condition",
			COL_MAIN_ROUTE: "Route",
			COL_SETUP_COND: "Setup Type",
			COL_COST: "Cost",

			PH_ENTER_RANGE_START: "Start Range",
			PH_ENTER_RANGE_END: "End Range",

			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate. Please re-select."
			},

			MSG: {
				CATEGORY_TYPE_NOT_EXISTS: "Category Type not exists",
				CATEGORY_TYPE_COST_SUCCESS_SAVE: "Category Type cost has been save",
				CATEGORY_SHIPMENT_DUPLICATE: "Category Type & Shipment Duplicate, Please re-select again."
			},

			COST_SETUP_TYPE: {
				BY_ROUTE: "By Route",
				BY_CONDITION: "By Condition",
				WITH_INSURANCE: "With Insurance",
				WITH_OUTSKIRT: "With Outskirt"
			},

			BTN_COPY_ROUTE: "Copy Route",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_REMOVE_ROUTE: "Remove Route"
		},
		/** End Category Type Cost */
		/** Start Customer Exceptional Cost */
		CUSTOMER_EXCEPTIONAL_COST: {
			LISTING_TITLE: "Customer Exceptional Cost Summary",

			LBL_CHARGES_TYPE: "Charges Type",
			LBL_CHARGES: "Exceptional Charges",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_MARKING: "Marking",
			COL_ROUTE: "From/To",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_COST: "Cost",
			COL_QUOTATION: "Quotation No.",
			COL_CHARGES: "Charges",
			COL_MIN_CHARGES: "Min Charges",
			COL_MARKUP: "Markup",
			COL_CUSTOMER: "Customer",
			COL_RANGE: "Range",
			COL_WAREHOUESE: "Warehouse",
			COL_CHARGES_TYPE: "Charges Type",
			COL_CHARGES_NAME: "Charges Name"
		},
		/** End Customer Exceptional Cost */
		MIN_MARKUP_SELECTION: {
			BY_AMOUNT: "By Amount",
			BY_VALUE: "By Value(m3 or kg)"
		}
	},
	SETTINGS: {
		GENERAL: {
			TITLE: "General Settings",

			LBL_EMAIL_FROM: "Sender Email",
			LBL_DEFAULT_SALESMAN: "Default Salesman",
			LBL_WEIGHT_DECIMAL: "Weight Decimal (KG)",
			LBL_M3_DECIMAL: "Weight Decimal (M3)",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_OTHER_WAREHOUSE_COMPANY: "Other Warehouses' Company",
			LBL_OTHER_WAREHOUSE_MOVE_TYPE: "Other Warehouses' Movement Type",

			MODULE_NAME: "General Settings"
		},
		QUOTATION_STATUS: {
			LISTING_TITLE: "Quotation Status",
			DETAIL_TITLE: "Quotation Status Detail",
			EDIT_TITLE: "Edit Quotation Status Detail",
			NOTIFICATION_SETTINGS_TITLE: "Notification Settings",
			MODULE_NAME: "Quotation Status",

			LBL_QUOTATION_NAME: "Quotation Status Name",
			LBL_QUOTATION_ADMIN_DESCRIPTION: "Admin Description",
			LBL_QUOTATION_CUSTOMER_DESCRIPTION: "Customer Description",
			LBL_INVALID_QUOTE_SELECTION: "Invalid Quotation Selection",

			COL_NAME: "Name",
			COL_DESCRIPTION: "Description",

			BTN_NOTIFICATION_SETTINGS: "Notification Settings",

			EMAIL_SETTINGS: {
				INTERNAL_EMAIL_TITLE: "Internal Notification",
				SALESMAN_EMAIL_TITLE: "Salesman Notification",
				INTERNAL_NOTIFIY_TO_TITLE: "Internal Notify To",
				LBL_EMAIL_CONTENT: "Email Content",
				LBL_EMAIL_SUBJECT: "Email Subject",
				CUSTOMER_EMAIL_TITLE: "Customer Notification",
				SELECTION_EMAIL: "Email",
				SELECTION_INBOX: "Notification",
				SELECTION_SMS: "Sms",
				SELECTION_MOBILE: "Mobile Notification",
				LBL_SUPPORT_TYPE: "Support Type",
				LBL_NOTIFICATION: "Notification",
				LBL_NOTIFICATION_TITLE: "Notification Title",

				LBL_NOTIFICATION_CONTENT: "Notification Content",
				LBL_COMPANY: "Company: ",
				COL_COMPANY_GROUP: "Company Group",
				COL_SENT_TO: "Email Address",
				MSG_SUCCESSFUL: "Successfull save",
				MSG_ERROR: "Failed To Save"
			}
		},
		/**Start Exceptional Cost */
		EXCEPTIONAL_COST: {
			LISTING_TITLE: "Exceptional Cost Type Listing",
			DETAIL_TITLE: "Exceptional Cost Type Detail",
			EDIT_TITLE: "Exceptional Cost Type Edit",
			MODULE_NAME: "Exceptional Cost Type",
			LBL_EXCEPTIONAL_COST_NAME: "Exceptional Cost Name",
			LBL_EXCEPTIONAL_COST_DESC: "Exceptional Cost Description",
			LBL_EXCEPTIONAL_COST_GROUP: "Group",

			COL_NAME: "Exceptional Cost Name",
			COL_DESCRIPTION: "Exceptional Cost Description",
			COL_GROUP: "Group"
		},
		/**End Exceptional Cost */
		/**Stat Weight Rounding */
		WEIGHT_ROUNDING: {
			LISTING_TITLE: "Weight Rounding",
			CREATE_TITLE: "Create New Weight Rounding",
			DETAIL_TITLE: "Weight Rounding Detail",
			EDIT_TITLE: "Edit Weight Rounding",
			MODULE_NAME: "Weight Rounding",

			LBL_CODE: "Weight Rounding Code",
			LBL_NAME: "Weight Rounding Name",
			LBL_ROUNDING: "Rounding",

			LBL_RATE: "Rate",
			LBL_ROUND_STYLE: "Round Up/Down",

			COL_CODE: "Weight Rounding Code",
			COL_NAME: "Weight Rounding Name",
			COL_RATE: "Rate",
			COL_ROUND_STYLE: "Round Up/Down",

			ROUNDUP_SELECTION: "Round Up",
			ROUNDDOWN_SELECTION: "Round Down"
		},
		/**End Weight Rounding */
		/**Start Language */
		LANGUAGE: {
			LISTING_TITLE: "Language Listing",
			CREATE_TITLE: "Create New Language",
			EDIT_TITLE: "Edit Language",
			DETAIL_TITLE: "Language Detail",
			MODULE_NAME: "Language",
			LBL_CODE: "Language Code",
			LBL_NAME: "Language Name",
			LBL_LOCALE: "Locale",
			LBL_SUPPORT_AT: "Support At",
			LBL_SORT_ORDER: "Sort Order",
			LBL_IMAGE: "Image",

			COL_CODE: "Language Code",
			COL_NAME: "Language Name",
			COL_SUPPORT_AT: "Support At",
			COL_SORT_ORDER: "Sequence",
			COL_FLAG: "Flag",

			SUPPORT_AT_SELECTION: {
				ALL: "Admin Portal & Customer Portal",
				ADMIN: "Admin Portal Only",
				CUSTOMER: "Customer Portal Only"
			}
		}
		/**End Language */
	},
	EPO_MANAGEMENT: {
		EPO_FORMAT: {
			EPO_FORMAT_PRINT_TITLE: "Print EPO"
		},
		EPO_LISTING: {
			LISTING_TITLE: "EPO Listing",
			CREATE_TITLE: "Create New EPO",
			DETAIL_TITLE: "EPO Detail",
			EDIT_TITLE: "Edit EPO Detail",

			MODULE_NAME: "EPO",

			EPO_MODULE_NAME: "EPO",
			CREATE_EPO_TITLE: "Create New EPO",

			LBL_EPO_DATE: "EPO Date",
			LBL_DELIVERY_ID: "Delivery Id",

			PH_ENTER_EPO_DATE_FROM: "Enter EPO Date From",
			PH_ENTER_EPO_DATE_TO: "Enter EPO Date To",

			COL_EPO_NO: "EPO No",
			COL_MARKING: "Marking",
			COL_DELIVERY_ID: "Delivery Id",
			COL_COMPANY: "Company",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_EPO_DATE: "Epo Date",
			COL_STATUS: "Status",

			BTN_VIEW: "View",
			BTN_DELETE: "Delete",

			TEXT_SAVE_AS_DRAFT: "Save As Draft"
		},
		ORDER: {
			LISTING_TITLE: "EPO Listing",
			CREATE_ORDER_TITLE: "Create new Order",
			EPO_ORDER_TITLE: "EPO Order for {epo_no}",
			EPO_INFO_TITLE: "EPO Info",
			WAREHOUSE_DETAILS_TITLE: "Warehouse Details",
			FROM_WAREHOUSE_TITLE: "From Which Warehouse",
			WAY_TO_SHIP_TITLE: "How would you like to ship?",
			LOADING_PORT_TITLE: "Loading Port",
			FORWARDER_INFORMATION_TITLE: "Forwarder Details",
			DELIVERY_ADDRESS_TITLE: "Destination",
			DESTINATION_PORT_TITLE: "Destination Port",
			SUPPLIER_DETAILS_TITLE: "Supplier Info",
			PRODUCT_DETAILS_TITLE: "Courier / Product Details",
			SPECIAL_REQUEST_TITLE: "Special Request",
			LBL_EPO_NO: "EPO No",
			LBL_EPO_TYPE: "EPO Type",
			LBL_PO_EXISTS: "PO Exists?",
			LBL_CURRENCY: "Currency",
			LBL_COMPANY_NAME: "Company Name",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_CONTACT_NUMBER: "Mobile Contact No.",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_OFFICE_CONTACT: "Office Contact No.",
			LBL_OFFICE_FAX_CONTACT: "Office Fax No.",
			LBL_CUSTOMER: "Customer",
			LBL_MARKING: "Customer Marking",
			LBL_SUPPLIER: "Supplier",
			LBL_SUPPLIER_ORDER_NO: "Supplier Order No",
			LBL_SUPPLIER_BANK_NAME: "Supplier Bank Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NAME: "Supplier Bank Account Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NO: "Supplier Bank Account No.",
			LBL_EXISTS_PO_NO: "Exists PO No.",
			LBL_DEPOSIT: "Deposit",
			LBL_ESTIMATE_DATE_DELIVERY: "Estimate Delivery Date",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_PRODUCT_REMARK: "Remarks",
			LBL_EPO_SUBMIT_DATE: "EPO Date",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "Date To",
			LBL_EPO_STATUS: "EPO Status",

			LBL_SUB_TOTAL: "Sub Total",
			LBL_TOTAL_DISCOUNT: "Total Discount",
			LBL_GRAND_TOTAL_AMOUNT: "Grand Total",

			EPO_TYPE_SELECTION_ORDER: "Order",
			EPO_TYPE_SELECTION_COURIER: "Courier",

			WAREHOUSE_SELECTION_OTHERS: "Others",

			PH_PIC: "Name",
			PH_SPECIAL_REQUEST: "Write a Message...",
			PH_LOADING_PORT: "Enter Loading Port",
			PH_DESTINATION_PORT: "Enter Destination Port",

			BTN_NEW_SUPPLIER: "New Supplier",

			COL_EPO_NO: "EPO No",
			COL_CREATED_AT: "Created On",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_SUPPLIER: "Supplier",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity Desc",
			COL_REF_TYPE: "Container Type",
			COL_MARKING: "Customer Marking",

			SHIPPING: {
				LBL_TYPE: "Shipment Type",
				LBL_MODE: "Shipment Mode",
				LBL_MOVEMENT: "Movement Type",
				LBL_TERM: "Shipment Term"
			},

			SUPPLIER: {
				LBL_NAME: "Supplier/Forwarder Name",
				LBL_ADDRESS: "Address",
				LBL_POSTCODE: "Postal Code",
				LBL_CITY: "City",
				LBL_STATE: "State",
				LBL_CONTACT_NUMBER: "Supplier/Forwarder Contact Number",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Supplier/Forwarder Calling Code"
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "Destination Address",
				LBL_ADDRESS: "Address",
				LBL_BUILDING_NAME: "Building Name",
				LBL_UPSTAIRS: "upstairs",
				LBL_COUNTRY: "Country",
				LBL_STATE: "State",
				LBL_CITY: "City",
				LBL_POSTCODE: "Postal Code",
				LBL_BRN: "Business Register Number"
			},

			PRODUCT: {
				COL_COURIER: "Courier",
				COL_ITEM_CODE: "Item Code",
				COL_PRODUCT_NAME: "Product Name",
				COL_BRAND: "Brand",
				COL_CATEGORY_GROUP: "Type",
				COL_CATEGORY_GROUP_TYPE: "Type Selection",
				COL_CATEGORY_GROUP_OTHERS: "Type Others",
				COL_QUANTITY: "Qty",
				COL_UOM: "Uom",
				COL_UNIT_PRICE: "Unit Price",
				COL_DISCOUNT_AMOUNT: "Discount Amount",
				COL_TOTAL_AMOUNT: "Total Amount",
				COL_REMARKS: "Remark",
				COL_PHOTO: "Photo",

				LBL_CONSIGNMENT_NO: "Consignment No",

				BTN_COPY: "Copy",
				BTN_ADD: "Add",
				BTN_DELETE: "Delete",
				BTN_UPLOAD: "Upload",
				BTN_CHANGE_CATEGORY_GROUP: "Modify Category Group",
				BTN_ADD_CONSIGNMENT_NO: "Add Consignment No",
				BTN_UPLOAD_PHOTO: "Upload Product Image",
				BTN_SUPPORT_DOCUMENT: "Upload Support Document",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "For Example Commodity Desc: Shirt, Shoes, Toy",
				EXAMPLE_ENTER_BRAND: "For Example Enter Multiple Brand: Addidas, Nike",

				PH_SELECT_CATEGORY_GROUP: "Please Select Category Group",
				PH_GOODS_AMOUNT_FROM: "Enter Amount From",
				PH_GOODS_AMOUNT_TO: "Enter Amount To",
				PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Enter Currency Symbol",

				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From Required"
			},

			CATEGORY_GROUP_TYPE: {
				MODAL_TITLE: "Category Type Details",

				LBL_CATEGORY_GROUP: "Goods Type",
				LBL_CATEGORY_GROUP_TYPE: "Selection of {category_group_name} product",
				LBL_OTHER_CATEGORY_GROUP_TYPE: "Discribe for {category_group_name} product",
				LBL_VALUABLE_OBJECTS: "Valuable objects",
				LBL_FRAGILE_ITEMS: "Fragile items",
				LBL_OTHER_SENSITIVE_ITEMS: "Other Sensitive Items",
				LBL_FAKE_BRANDS: "Fake Brands",

				PH_OTHER_CATEGORY_GROUP_TYPE: "Please input and discribe for {category_group_name} product",
				PH_FAKE_BRANDS: "Please input and discribe for Fake Brands"
			},
			UPLOAD: {
				PRODUCT_IMAGE: "Upload Product Image",
				SUPPORTED_DOCUMENT: "Upload Supported Document",
				LBL_UPLOAD_PHOTO: "Click to select the images.",
				LBL_UPLOAD_FILE: "Click to select the file.",
				SUPPORT_IMAGE_SIZE: "Supported size should be less than 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and format .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "Supported size should be less than 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "Supported size should be less than 10 MB! and format .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},

			NEW_SUPPLIER: {
				MODAL_TITLE: "Create New Supplier"
			},

			BTN_SUBMIT: "Submit",
			BTN_RESET: "Reset",
			SUCCESS: "EPO Order has been generate and EPO No: {epo_no}.",
			FAILED: "Oopps ... ..., Sorry EPO Order Failed to Generate. Please try again.",
			MSG_FAILED_UPLOAD_IMGAE: "Sorry Product Image Failed to Upload",
			MSG_FAILED_UPLOAD_DOCUMENT: "Sorry Document Failed to Upload",
			MSG_NO_SUPPLIER: "Supplier not found. Please create a new supplier before create order",
			MSG_NO_ADDRESS: "Delivery Addresses not found. Please create a new delivery address before requesting for a quote",
			MSG_BILL_ADDRESS_NOT_FOUND: "Billing Address not found. Please set.",

			MSG_GRAND_TOTAL_AMOUNT_NEGATIVE: "Grand Total amount cannot be negative",
			MSG_PRODUCT_TOTAL_AMOUNT_NEGATIVE: "Product Total amount cannot be negative",
			MSG_PRODUCT_UNIT_PRICE_EMPTY: "Warning! your product unit price contain zero or empty value",
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "Successful save as draft",
			MSG_BLOCK_EDIT: "You are not allow to edit this EPO Order.",
			MSG_DOCUMENT_TYPE: "No Document Type matched for current Marking. Please contact admin",
			MSG_DOCUMENT_NUMBER: "No Document Number matched for current Marking. Please contact admin",

			MSG_CONFIRMATION_SUBMIT: "Please ensure all details are correct before submitting.",
			MSG_PERSONAL_DATA_INCOMPLETE: "Personal Data Incomplete. Please complete first",
			RESET_FORM_CONFIRMATION: "All Information will be reset. Are you sure?",
			MSG_BILL_ADDRESS_INCOMPLETE: "Billing Address Incomplete"
		}
	},
	TRANSACTION: {
		QUOTATION_MANAGEMENT: {
			LISTING_TITLE: "Request For Quote List",
			PREVIEW_QUOTATION_TITLE: "Preview Quotation",
			VIEW_SPECIAL_PRODUCT_TITLE: "View Special Product",
			CREATE_FCL_TITLE: "Create New Fcl/Other LCL Quotation",
			CREATE_LCL_TITLE: "Create New LCL Quotation",
			REVISE_LCL_TITLE: "Revise LCL Quotation",
			HISTORY_TITLE: "{quotation_no} History",
			REVISE_QUOTATION_BATCH_TITLE: "Quotation Batch Revise",
			DRAFT_STATUS: "Draft",
			SUMMARY: {
				COL_INQUIRY_NO: "Inquiry No",
				COL_INQUIRY_DATE: "Inquiry On",
				COL_QUOTATION_NO: "Quotation No",
				COL_CUSTOMER: "Customer Company Name",
				COL_MARKING: "Marking",
				COL_SHIPMENT_TYPE: "Shpt Type",
				COL_SHIPMENT_MODE: "Shpt Mode",
				COL_SHIPMENT_TERMS: "Shpt Terms",
				COL_MOVEMENT_TYPE: "Movement",
				COL_COUNTRY: "From/To Country",
				COL_ORIGIN_DESTIONATION: "Warehouse To Area",
				COL_ISSUED_ON: "Issued On",
				COL_SALESMAN: "Salesman",
				COL_MAINSALESMAN: "Main Salesman",
				COL_AREA: "Destination Area",
				COL_COMPANY_NAME: "Company Name",
				COL_COMPANY_GROUP: "Company Group",
				COL_LOADIND_PORT: "LPT",
				COL_DESTINATION_PORT: "DPT",
				LBL_DESTINATION_AREA: "{state}, {city}",
				COL_PRODUCT_NAME: "Commodity",
				COL_REF_TYPE: "Ref Type",
				COL_DELIVERY_ID: "Delivery Id",
				COL_CATEGORY_PARENT: "Category (Lvl 1)",
				COL_CATEGORY: "Category (Lvl 2)",
				COL_GOODS_AMOUNT_FROM: "Commodity Amt",
				COL_REMARKS: "Remarks",
				COL_REVISION: "Revision",
				COL_CREDIT_LIMITS: "Credit Limit",
				COL_BASE_ROUTE: "Base Route",
				COL_TRANSIT_ROUTE: "Transit Route",
				COL_EXCEPTIONAL_CHARGES_TYPE: "Exceptional Charges Type",
				COL_EXCEPTIONAL_CHARGES: "Exceptional Charges",
				COL_TAX: "Tax",
				COL_SPECIAL_CHARGES: "Special Add. Chrg",
				COL_SALESMAN_MIN_GROUP: "Salesman Min Markup Grp",
				COL_SALESMAN_GROUP: "Salesman Markup Grp",
				COL_CUST_MIN_MARKUP_GROUP: "Cust. Min Markup Grp",
				COL_CUST_MARKUP_GROUP: "Cust. Markup Grp",
				COL_COMBINE_CREDIT_LIMITS: "Combine Credit Limit",

				COL_DESTINATION_STATE: "Destination State",
				COL_NOTICE_TYPE: "Notice Type",
				COL_GROUP_SUM: "Grp Sum",
				COL_QUOTE_CURRENCY: "Cur.",

				COL_MIN_CHARGES_TYPE: "Min Chg Type",
				COL_MIN_CHARGES_UOM: "Min Chg UOM",
				COL_MIN_CHARGES: "Min Chg",
				COL_QUOTE_MIN_CHARGES: "Min Quote Amt",

				COL_CHARGES_TYPE: "Chg Type",
				COL_CHARGES_BY: "Chg By",
				COL_CHARGES_UOM: "Chg UOM",
				COL_CHARGES: "Chg",
				COL_QUOTE_CHARGES: "Quote Amt",

				COL_TTL_MIN_CHARGES: "Ttl Min Chg",
				COL_TTL_MIN_QUOTE_CHARGES: "Ttl Min Quote Chg",

				COL_TTL_CHARGES: "Ttl Chg",
				COL_TTL_QUOTE_CHARGES: "Ttl Quote Chg",

				COL_CREATED_BY: "Created By",
				COL_CREATED_ON: "Created On",

				COL_CONFIRM_BY: "Confirmed By",
				COL_CONFIRM_ON: "Confirmed On",

				COL_APPROVED_BY: "Approved By",
				COL_APPROVED_ON: "Approved On",

				COL_ISSUE_BY: "Issued By",

				COL_REVERT_BY: "Reverted By",
				COL_REVERT_ON: "Reverted On",
				COL_REVERT_REASON: "Reverted Reason",

				COL_REJECTED_BY: "Rejected By",
				COL_REJECTED_ON: "Rejected On",
				COL_REJECT_REASON: "Rejected Reason",

				COL_INVALID_BY: "Invalided By",
				COL_INVALID_ON: "Invalided On",
				COL_INVALID_REASON: "Invalided Reason"
			},

			LBL_INQUIRY_NO: "Inquiry No.",
			LBL_QUOTATION_NO: "Quotation No.",
			LBL_REVESION: "Revision",
			LBL_QUOTATION_FORMAT: "Format",
			LBL_EXPIRED_ON: "Expired On: ",
			LBL_INQUIRY_DATE: "Inquiry Date",
			LBL_CUSTOMER_NAME: "Customer Company Name",

			LBL_CUSTOMER_EMAIL: "Email",
			LBL_CUSTOMER_COMPANY: "Company",
			LBL_CUSTOMER_PIC: "Person In Charge",
			LBL_CUSTOMER_CONTACT: "Mobile No.",
			LBL_CUSTOMER_OFFICE_CONTACT: "Office Contact No.",
			LBL_CUSTOMER_CONTACT_ALT: "Alt Mobile No.",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_SHIPMENT_TERMS: "Shipment Terms",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_COUNTRY_FROM_TO: "From {origin_country} To {dest_country}",
			LBL_ORIGIN_COUNTRY: "Origin Country",
			LBL_DEST_COUNTRY: "Destination Country",
			LBL_WAREHOUSE: "Origin Warehouse",
			LBL_CUSTOMER_BILL_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_BILL_CONTACT_PERSON: "Contact Person",
			LBL_CUSTOMER_BILL_ADDRESS: "Billing Address",
			LBL_CUSTOMER_BILL_ADDRESS_ADDRESS: "Address",
			LBL_CUSTOMER_REVERT_REJECT: "Customer Cancel Reject",
			LBL_DELIVERY_ID: "Delivery ID",
			LBL_REJECTED_REASON: "Rejected Reason",
			LBL_REVERTED_REASON: "Reverted Reason",
			LB_INVALID_REASON: "Invalid Reason",
			LBL_INCLUDE_DRAFT: "Include Draft",

			DETAIL_TITLE: "{module_name} Detail ",
			EDIT_TITLE: "Edit {module_name}",
			BUTTON_REJECTED: "Reject",
			BUTTON_REVERT: "Revert",
			BTN_ISSUE: "Approve & Issue",
			BTN_INVALID_QUOTATION: "Invalid Quotation",
			BTN_ENABLE_EDIT_TRANSMIT_DESC: "Enabled Edit Transmit Desc",
			BTN_DISABLE_EDIT_TRANSMIT_DESC: "Disabled Edit Transmit Desc",
			BTN_ENABLE_EDIT_SHIPMENT_DESC: "Enabled Edit Shipment Desc",
			BTN_DISABLE_EDIT_SHIPMENT_DESC: "Disabled Edit Shipment Desc",
			BTN_ENABLE_EDIT_DESTINATION_PORT: "Enabled Edit Destination Port",
			BTN_DISABLE_EDIT_DESTINATION_PORT: "Disabled Edit Destination Port",
			BTN_ENABLE_EDIT_SHIPPING_TERMS: "Enabled Edit Shipment Terms",
			BTN_DISABLE_EDIT_SHIPPING_TERMS: "Disabled Edit Shipment Terms",
			BTN_ENABLE_EDIT_LOADING_PORT: "Enabled Edit Loading Port",
			BTN_DISABLE_EDIT_LOADING_PORT: "Disabled Edit Loading Port",
			BTN_DISABLE_EDIT_SERVICE_CHARGES_QUOTE: "Disabled Edit Service Charges",
			BTN_ENABLE_EDIT_SERVICE_CHARGES_QUOTE: "Enabled Edit Service Charges",
			BTN_ENABLE_EDIT_MOVEMENT_TYPE: "Enabled Edit Movement Type",
			BTN_DISABLE_EDIT_MOVEMENT_TYPE: "Disabled Edit Movement Type",
			BTN_RESET_TERMS_COND: "Reset Credit Terms",
			BTN_CREATE_MARKING: "New Marking",
			BTN_ENABLE_EDIT_QUOTE_TITLE: "Enabled Edit Exceptional Charge Quote Title",
			BTN_DISABLE_EDIT_QUOTE_TITLE: "Disabled Edit Exceptional Charge Quote Title ",
			BTN_CREATE_QUOTE: "Create Inquiry Quotation",
			BTN_REVISE_QUOTATION: "Revise Quotation",
			BTN_CONFIRM_N_ISSUE: "Confirm & Issued",
			BTN_HISTORY: "History",
			BTN_ADD_TRANSIT_ROUTE: "Add Transit Route",
			BTN_RESET_TRANSIT_DESCRIPTION: "Reset Transit Description",
			SUB_TITLE: {
				CUSTOMER: "Customer Information",
				GENERAL: "General Information",
				SHIPPING: "Shipment Information",
				PRODUCT: "Product & Special Request Information",
				ORIGIN_ADDRESS: "Origin Address",
				DESTINATION_ADDRESS: "Destination Address",
				TERM_CONDITION: "Terms & Condition",
				LCL_COST: "Quote Price",
				LBL_EXCEPTIONAL_CHARGES: "Exceptional Charges",
				ORIGIN_DESTINATION_ADDRESS: "Origin & Destination"
			},

			LBL_GENERAL_COMPANY: "Company",
			LBL_GENERAL_COMPANY_GROUP: "Company Group",
			LBL_GENERAL_MAINSALESMAN: "Main Salesman",
			LBL_GENERAL_SALESMAN: "Salesman",
			LBL_GENERAL_MARKING: "Marking",
			LBL_GENERAL_FULL_MARKING: "Full Marking",
			LBL_GENERAL_DELIVERY_ID: "Delivery ID",
			LBL_GENERAL_QUOTATION_DATE: "Issued On",
			LBL_GENERAL_EFFECTIVE_DATE: "Effective On",
			LBL_GENERAL_VALIDITY_UNTIL: "Validity Until",
			LBL_GENERAL_RE_QUOTATION: "Re:Quotation",
			LBL_SHIPMENT_DESC_ROUTE: "Shipment Route",
			INCLUDE_DELIVERY_ID: "Print",

			LBL_PRODUCT_SPECIAL_REQUEST: "Special Request",

			PH_ENTER_SALESMAN_CODE_NAME: "Enter Salesman code or Name",
			PH_ENTER_INQUIRY_DATE_FROM: "Enter Inquiry Date From",
			PH_ENTER_INQUIRY_DATE_TO: "Enter Inquiry Date To",
			PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Currency Symbol",
			PH_GOODS_AMOUNT_FROM: "FROM",
			PH_GOODS_AMOUNT_TO: "To",

			PRODUCT_TABLE: {
				PRODUCT_DESC: "Category/Product Desc",
				BRAND_DESC: "Brand Desc",
				SPECIAL_MATERIAL: "Special Material",
				CATEGORY_GROUP: "Category Type",
				SALESMAN_COST: "Salesman Cost",
				SALESMAN_GROUP: "Customer Group",
				MIN_CHARGES: "Min Charges",
				ATTACHMENT: "Attachment",
				REMARK: "Remark",
				SALESMAN_COST: "Salesman Cost",
				SALESMAM_GROUP: "Customer Cost Group",
				MIN_CHARGES: "Min Charges",
				QUOTE_PRICE: "Quote Price",
				ATTACHMENT: "Attachments",
				REMARK: "Remark",
				REF_TYPE: "Reference Type",
				MARKUP: "Markup",
				TOTAL_CHARGES: "Total Charges",
				LBL_EXTRA_CHARGES: "Extra Charges"
			},

			PRODUCT_DETAIL: {
				LBL_REMARK: "Remarks",
				LBL_PRODUCT_DESC: "Commodity Desc",
				LBL_PRODUCT_QUOTE_DESC: "Category Quote Desc",
				LBL_CATEGORY: "Product Category",
				LBL_CATEGORY_LVL1: "Product Category(Parent)",
				LBL_CATEGORY_LVL2: "Product Category(Lvl 2)",
				LBL_SPECIAL_MATERIAL: "Special Material",
				LBL_PRODUCT_REMARK: "Product Remark",
				LBL_ATTACHMENTS: "Attachment",
				LBL_BRAND_DESC: "Brand Desc",
				LBL_SPECIAL_MATERIAL: "Sepcial Material",
				NO_IMAGE_MSG: "No Product Image has been uploaded by the customer",
				LBL_CATEGORY_GROUP: "Category Type",
				LBL_SALESMAN_COST: "Salesman Cost",
				LBL_MARKUP: "Markup",
				LBL_MIN_CHARGES: "Min Charges",
				BTN_RESET_COST: "Reset Cost",
				LBL_REFERENCE_TYPE: "Reference Type",
				LBL_DIMENSION: "Total M3",
				LBL_WEIGHT: "Total Weight ({measure})",
				LBL_TOTAL_CARTON: "Total Carton",
				LBL_GOODS_AMT: "Amount",
				LBL_TOTAL_CHARGES: "Total Charges",
				LBL_SALESMAN_COST_MARKUP: "Salesman Cost Markup ({uom})",
				LBL_ADDITIONAL_CAHREGS: "Additional Charges",
				LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guanrantee",
				LBL_OUTSKIRT: "Outskirt",
				LBL_SERVICE_CHARGES: "Service Charges Remark",
				LBL_EXTRA_CHARGES: "Extra Charges",
				LBL_SHIPPING_REMARK: "SCS Remark",
				LBL_PRODUCT_SPECIAL_REMARK: "AR Remark",
				LBL_PRODUCT_SPECIAL_DESC: "Special Product Description",
				LBL_SERVICE_CHARGES_QUOTE_DESC: "Service Charges Quote Description",

				LBL_SPECIAL_PRODUCT: "Special Product",
				LBL_SC_REJECT: "SCS Reject",
				LBL_SPECIAL_PROD_STATUS_ID: "Special Product Status",
				PRODUCT_IMAGE_TITLE: "Product Images",
				SUPPORTED_DOC_TITLE: "Supported Document",
				UPLOAD_IMAGE_DOCUMENT_SPECIAL_PROD: "Upload Special Product Image / Supported Document",
				UPLOAD_IMAGE: "Upload Product Image",
				UPLOAD_FILE_TITLE: "Click here select file",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "File supported size should be less than 10 MB(Format: .xls, .xlsx, .pdf, .doc, .docs, .csv).",
				TOOLTIP_UPLOAD_VIEW_IMAGE_SUPPORT_DOCUMENT: "View Product Image/Supported Document",

				CURRENCY: {
					LBL_QUOTE_PRICE_BEFORE_CONVERT: "<strong>Before Convert</strong>: {curreny_name} {value} {curreny_name1}",
					LBL_CURRENCY_RATE: "<strong>Currency Rate</strong>: {rate}",
					LBL_MIN_CHARGES_BEFORE_CONVERT: "<strong>Before Convert</strong>: {curreny_name} {value} {curreny_name1}"
				},

				BUTTON_VIEW_SPECIAL_PRODUCT: "Click Here Check Special Product"
			},

			FCLCOSTING: {
				LBL_CHARGES_TYPE: "Charges Type",
				LBL_GROUP_SUM: "Group Sum",
				LBL_CURRENCY: "Currency",
				LBL_UOM: "UOM",
				LBL_COSTING: "Quote Price",
				LBL_MARKUP: "Markup",
				LBL_QUOTE_PRICE: "Quote Price",
				LBL_COST_TITLE: "Cost",
				LBL_MIN_COST: "Min Quote Price",
				LBL_MIN_MARKUP: "Min Markup",
				LBL_MIN_QUOTE_PRICE: "Min Quote Price",
				LBL_ENABLE_MIN_CHARGES: "Enable/Disabled<br />Min Charges",
				LBL_MIN_COST_TYPE: "Min Chg By",
				LBL_MIN_COST_UOM: "UOM",
				LBL_COST: "Cost",

				PH_COST: "Enter Cost",
				PH_MARKUP: "Enter Markup",
				PH_QUOTE_PRICE: "Enter Quote Price",
				PH_MIN_COST: "Enter Min Cost",

				BTN_ADD_NEW_CHARGES: "Add New Charges",
				BTN_ADD_SUB_CHARGES: "Add New Sub {charges_name}",
				LBL_COST_REMARK_SUMUP: "** &#10003; checkbox for sum up cost",

				VALIDATION: {
					CHARGES_TYPE_DUPLICATE: "Charges Type duplicate",
					QUOTE_PRICE_GREATER_EQUAL: "Quote Price must greater than or equal Cost"
				},

				MSG_GROUP_SUM_ERROR: "This {charges_name} currency/cost uom/min cost type/min cost uom different than {group} group ",
				LBL_TOTAL_COST: "Total Cost",
				COL_TOTAL_COST: "Cost",
				COL_TOTAL_MIN_COST: "Min Cost",
				COL_TOTAL_MARKUP: "Markup",
				COL_TOTAL_QUOTE_PRICE: "Quote Price",
				COL_TOTAL_MIN_MARKUP: "Min Markup",
				COL_TOTAL_MIN_QUOTE_PRICE: "Min Quote Price",

				MIN_COST_TYPE: {
					BY_AMOUNT: "Amount",
					BY_M3_KG: "M3/KG",
					BY_PERCENTAGE: "Percentage"
				},

				CHARGES_BY_TYPE: {
					BY_AMOUNT: "Amount",
					BY_PERCENTAGE: "Percentage"
				}
			},

			LBL_AUTORISED_PERSONNEL: "Authorised Personnel",
			LBL_APPROVE_By_PERSONNEL: "Approved By",
			LBL_REJECTED_PERSONNEL: "Rejected By",
			LBL_REVERTED_PERSONNEL: "Reverted By",
			LBL_SET_INVALID_PERSONNEL: "Set Invalid By",
			ATTACHMENT_TITLE: "Views",
			OTHER_TITLE: "Others",
			LBL_COUNTRY: "Country",
			LBL_AREA: "Area",
			LBL_DEST_ADDRESS: "Destination Address",
			LBL_FORWARDER_NAME: "Forwarder Name",
			FORWARDER_TITLE: "Forwarder Information",
			LBL_FORWARDER_PERSON_INCHARGE: "Forwarder Person In Charge",
			LBL_FORWARDER_CONTACT_NO: "Forwarder Contact No",

			LISTING_TITLE: {
				LCL_CONDITION_CONFIRM: "LCL Quotation - Condition Confirm",
				LCL_CONDITION_APPROVE: "LCL Quotation - Condition Approve",
				LCL_QUOTE_CONFIRM: "LCL Quotation - Quote Confirm",
				LCL_QUOTE_APPROVE: "LCL Quotation - Quote Approve",
				LCL_QUOTE_ISSUE: "LCL Quotation - Quote Pending Issue",
				LCL_QUOTATION_PENDING_ACKNOWLEDGE: "LCL Quotation - Quote Pending Acknowledge",
				LCL_REJECT: "LCL Quotation - Rejected/Cancelled Inquiry Quotation",
				LCL_ACKNOWLEDGE: "LCL Quotation - Acknowledged Quotation",
				LCL_INQUIRY_SUMMARY: "LCL Inquiry Quotation Summary",
				LCL_SALESMAN_QUOTE: "Lcl Quotation - Salesman Quote",
				LCL_SCS_CONFIRM: "Lcl Quotation - SCS Confirm",
				LCL_INVALID_QUOTATION: "Lcl Quotation - Invalid Quotation",
				LCL_QUOTATION: "LCL Quotation",

				FCL_CONDITION_CONFIRM: "FCL/Others LCL Quotation - Condition Confirm",
				FCL_CONDITION_APPROVE: "FCL/Others LCL Quotation - Condition Approve",
				FCL_COST_CONFIRM: "FCL/Others LCL Quotation - Cost Confirm",
				FCL_COST_APPROVE: "FCL/Others LCL Quotation - Cost Approve",
				FCL_QUOTE_CONFIRM: "FCL/Others LCL Quotation - Quote Confirm",
				FCL_QUOTE_APPROVE: "FCL/Others LCL Quotation - Quote Approve",
				FCL_QUOTE_ISSUE: "FCL/Others LCL Quotation - Quote Pending Issue",
				FCL_QUOTATION_PENDING_ACKNOWLEDGE: "FCL/Others LCL Quotation - Quote Pending Acknowledge",
				FCL_REJECT: "FCL/Others LCL Quotation - Rejected/Cancelled Inquiry Quotation",
				FCL_ACKNOWLEDGE: "FCL/Others LCL Quotation - Acknowledged Quotation",
				FCL_INVALID_QUOTATION: "FCL/Others LCL Quotation - Invalid Quotation",
				FCL_SALESMAN_QUOTE: "FCL/Other LCL Quotation - Salesman Quote",
				FCL_INQUIRY_SUMMARY: "FCL/Other LCL Inquiry Quotation Summary",
				FCL_QUOTATION_COST: "FCL/Other LCL Quotation - Quotation Cost Summary"
			},
			LBL_ADDRESS: "Address",
			LBL_STATES: "States",
			LBL_CITIES: "City",
			LBL_POSTCODE: "Postcode",
			LBL_LOADIND_PORT: "Loading Port",
			LBL_DESTINATION_PORT: "Destination Port",
			ORIGIN_SUPPLIER: "Supplier Information",
			LBL_SUPPLIER_NAME: "Supplier Name",
			LBL_SUPPLIER_CONTACT: "Supplier Contact No",
			LBL_COMBINE_CREDIT_LIMIT: "Combine Credit Limit",
			LBL_CREDIT_LIMIT: "Credit Limit",
			LBL_FINANCIAL_RATE: "Financial Rate (%)",
			LBL_SALESMAN_FINANCIAL_RATE: "Salesman Financial Rate (%)",
			LBL_PAYMENT_TERMS: "Notice Type",
			LBL_CREDIT_TERMS: "Credit Terms",
			LBL_LOST_INDEMNIFY: "Indemnify of Lost Goods",
			LBL_FREIGHT_CHARGE: "Freight Charges",
			LBL_INDEMNIFY_REMARK: "Indemnify of Lost Goods Remark",
			LBL_REMARK: "Quotation Remark",
			LBL_SHIPMENT_DESC: "Shipment Description",
			LBL_SHIPMENT_REMARK: "Shipment Remark",
			LBL_TAX_TYPE: "Tax Type",
			LBL_TAX: "Tax",
			LBL_TAX_REMARK: "Tax Remark",
			LBL_ROUTE: "Route",
			LBL_TRANSIT: "Transit",
			LBL_TRANSIT_DESCRITION: "Transit Description",
			LBL_INCLUCE_PARCEL_LOST_GUARANTEE: "Include Parcel Lost Guanrantee",
			LBL_DESTINATION_ADDRESS: "Destination Address",
			CREDIT_TERMS: {
				COL_TYPE_CHARGE: "Type of Charge",
				COL_DAY: "Day",
				COL_OVERDUE: "Overdue (%)",
				COL_MODE: "Mode",
				COL_FROM: "From",
				COL_REMARK: "Remark",
				CHARGES_TYPE_SELECTION: {
					PURCHASE_AMOUNT: "PA (Purchase Amount)",
					SERVICE_CHARGE: "SC (Service Charge)",
					TAX: "TA (Tax)"
				},
				MODE_SELECTION: {
					MONTHLY: "Monthly",
					YEARLY: "Yearly"
				},
				FROM_SELECTION: {
					TT_DATE: "By TT Date",
					INVOICE_DATE: "By Invoice Date",
					SO_RECEIVE_DATE: "By So Received Date",
					ERC_DATE: "By ERC Date"
				},
				TITLE_ADD_CREDIT_DAY: "Add New Day Conversion"
			},

			REJECT: {
				TITLE: "Reject Inquiry Quotation",
				MSG_CONFIRM_REJECT: "Are you sure to reject this {inquiry_no} inquiry quotation?",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_REJECT_REASON: "Reject Reason",
				MSG_SUCCESSFULL: "{inquiry_no} has been successful rejected.",
				MSG_NOT_ALLOW_REJECT: "Ooops.. .. You are not allow reject this {inquiry_no}."
			},

			INVALID: {
				TITLE: "Invalid Quotation",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_QUOTATION_NO: "Quotation No",
				LBL_INVALID_REASON: "Invalid Reason",
				MSG_SUCCESSFULL: "{quotation_no} has been successful set to invalid.",
				MSG_NOT_ALLOW_INVALID: "Ooops.. .. You are not allow this {quotation_no} to invalid.",
				LBL_QUOTATION_STATUS: "Set Quotation Status"
			},

			REVERT: {
				TITLE: "Revert Inquiry Quotation",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_REVERT_REASON: "Revert Reason",
				MSG_SUCCESSFULL: "{inquiry_no} has been successful revert to {status_name}.",
				MSG_NOT_ALLOW_REVERT: "Ooops.. .. You are not allow revert this {inquiry_no}.",
				MSG_CONFIRMATION: "Are you sure to revert this {inquiry_no} inquiry quotation?"
			},

			TOOLTIP_VIEW_IMAGE: "View Product image",
			TOOLTIP_DOWNLOAD_SUPPORTED_DOCUMENT: "Download Document",
			TOOLTIP_UPLOAD_IMAGE_SUPPORT_DOCUMENT: "Upload Special Product Image/Supported Document",

			ERROR: {
				MAIN_ROUTE_NOT_EXISTS: "Route Record no found. Please setup.",
				COST_NOT_EXISTS: "Base cost record no found. Please setup.",
				SM_GROUP_NOT_EXISTS: "Salesman's Cost Group not assign. Please Check.",
				SM_GROUP_COST_NOT_EXISTS: "Salesman Group Cost not found. Please Setup.",
				SM_GROUP_MIN_COST_NOT_EXISTS: "Salesman Group Min Cost not found. Please Setup.",
				EXCEPTIONAL_CHARGES_NOT_EXISTS: "{name} Charges not found. Please setup",
				MSM_COST_NOT_EXISTS: "Main Salesman Cost not found. Please Setup.",
				CUSTOMER_GROUP_COST_NOT_EXISTS: "Customer Group cost record not found. Please Setup.",
				CUSTOMER_GROUP_MIN_COST_NOT_EXISTS: "Customer Group Min cost record not found. Please Setup.",
				MSG_FAILED_CONFIRM: "Failed Confirm",
				MSG_FAILED_APPROVE: "Failed Approved",
				MSG_RATE_NOT_EXISTS: "Currency Rate not found. Please set ",
				MSG_MARKING_NOT_AVAILABLE: "Marking Duplicate",
				MSG_FAILED_APPROVE: "Failed Approve",
				MSG_FAILED_ISSUE: "Failed Issue",
				MSG_FAILED_SALESMAN_QUOTE: "Failed Save",
				MSG_FAILED_TO_SAVE_DRAF: "Inquiry Quotation failed To save",
				SHIPMENT_DESC_WRONG_MSG: "This shipment from supplier warehouse. You are not allow select {shipment_type_desc}",
				SUPPLIER_SHIPMENT_DESC_WRONG_MSG: "This shipment from our warehouse. You are not allow select {shipment_type_desc}",
				MSG_BLOCK_EDIT_MARKUP: "Admin in quoting this {inquiry_no} inquiry quotation. You are not allow adjust markup. Please assist admin.",
				MSG_FILE_FORMAT_NOT_SUPPORTED: "File format not supported",
				MSG_IMAGE_FORMAT_NOT_SUPPORTED: "Image format not supported. Supported format: .jpg, .jpeg, .png",
				FAILD_UPLOAD_FILE: "Oops... ..., Failed to upload file to server. Please try again",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Image size should be less than 1 MB",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "File size should be less than 10 MB",
				NO_DOCUMENT_SELECTED: "No Document has been selected",
				MSG_SPECIAL_PRODUCT_IMAGE_REQUIRED: "Special Product Image Required.",
				MSG_NO_ROW_SELECTED: "No row has been selected",
				MSG_NOT_ALLOW_VIEW_QUOTATION: "You are not allow to view {inquiry_no} inquiry quotation",
				MSG_NOT_ALLOW_SWITCH_SALESMAN: "The customer's other inquiry quotation, already assign another salesman. You are not allowed to switch salesman.",
				MSG_BLOCK_ENTER_MARKING: "The customer's other inquiry quotation, already assign another marking. You are not allowed to edit marking",
				MSG_BASE_COST_ALLOW_ONE: "You are not allow add more than 1 base cost route",
				MSG_ROUTE_ALLOW_DUPLICATE: "Route not allow duplicate",
				MSG_ROUTE_COST: "Route Cost is required. Please setup.",
				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From is required",
				MSG_CATEGORY_PARENT_TYPE_DIFF: "Please make sure selected category parent's type must be same",
				MSG_CATEGORY_TYPE_DIFF: "Please make sure selected category's type must be same"
			},

			SUCCESS: {
				MSG_SUCCESS_CONFIRM: "Quotation confirmed",
				MSG_SUCCESS_APPROVE: "Quotation approved",
				MSG_SUCCESS_ISSUE: "Quotation Issued",
				MSG_SUCCESS_SAVE_AS_DRAF: "Inquiry Quotation successful to save",
				MSG_SUCCESS_UPDATED_MARKUP: "Quotation successful save",
				MSG_SUCCESS_REVISED: "Quotation Successful revised & issued"
			},

			MODULE_NAME: {
				COST_CONFIRM: "Cost Confirm",
				SALESMAN_QUOTE: "Salesman Quote",
				CONDITION_CONFIRM: "Condition Confirm",
				SCS_CONFIRM: "Scs Confirm"
			},

			QUOTE_TYPE_SELECTION: {
				BY_AMOUNT: "Fix Amount",
				BY_PERCENTAGE: "Rate(%)"
			},

			QUOTE_CHARGE_TYPE_SELECTION: {
				PURCHASE_AMOUNT: "Purchase Amount",
				SERVICE_CHARGES: "Service Charges"
			},

			FCL_QUOTATION_TPL_SELECTION: {
				STANDARD: "Normal Quotation",
				STANDARDV2: "Normal Quotation V2"
			},

			LCL_QUOTATION_TPL_SELECTION: {
				STANDARD: "Normal Quotation"
			},

			SHIPMENT_DESC_SELECTION: {
				WTD: "Warehouse to Customer Door Step",
				WTP: "Warehouse to Destination Port",
				STD: "Supplier Warehouse To Customer Door Step",
				STP: "Supplier Warehouse To Destination Port",
				PTP: "Origin Port To Destination Port",
				PTD: "Origin Port To Customer Door Step",
				DPTD: "Destination Port To Door Step"
			},

			EXTRA_CHARGES: {
				LBL_OUTSKIRT: "OutSkirt",
				LBL_CHARGES_TYPE: "Exceptional Charges Type",
				LBL_CHARGES: "Exceptional Charges",
				LBL_CHARGES_TITLE: "Charges Quote Title",

				EXCEPTION_COST: {
					COL_CONDITION: "Condition",
					COL_MIN_CHARGES: "Min Charges",
					COL_CHARGES: "Charges",
					COL_ADDTIONAL_CHARGES: "Addtional Charges"
				},

				BUTTON_ADD_NEW_EXCEPTIONAL_CHARGES: "Add New Exceptional Charges",
				BUTTON_REMOVE_EXCEPTION_CHARGES: "Remove Exception Charges"
			},

			LCL_COST: {
				LBL_CATEGORY_TYPE_MIN_CHARGES: "Category Type Min Addon Charges ",
				LBL_CATEGORY_TYPE_CHARGES: "Category Type Addon Charges",
				ROUTE_TRANSMIT_TITLE: "Transmit From {oc}, {oa} to {dc}, {da}",
				LBL_RANGE: "Range",
				LBL_MAIN_SALESMAN: "Main Salesman",
				LBL_SALESMAN: "Salesman",
				LBL_CHARGES: "Charges",

				LBL_MIN_MARKUP: "Min Markup",
				LBL_MIN_CHARGES: "Min Charges",
				LBL_MARKUP: "Markup",
				LBL_QUOTE_PRICE: "Quote Price",
				LBL_SPECIAL_ADDITIONAL_CHARGES: "Special Add. Chrg",
				LBL_CUSTOMER_GROUP: "Cust Markup",
				LBL_CUSTOMER_GROUP_MIN_COST: "Cust Min Markup",
				LBL_CUSTOMER: "Customer",
				LBL_ROUTE_CHARGES: "Charges"
			},

			MSG_REMOVE_FILE: "This file will be removed.",
			MSG_REMOVE_IMAGE: "This Image will be removed.",

			REVISE_QUOTATION_NOTE: "**NOTE: <br />Leave the field empty if you do not wish to perform update on the particular field."
		}
	},
	CUSTOMER_MANAGEMENT: {
		CUSTOMER: {
			LISTING_TITLE: "Customer Listing",
			CREATE_TITLE: "Create New Customer",
			DETAIL_TITLE: "Customer Detail",
			EDIT_TITLE: "Edit Customer Detail",

			CREATE_CUSTOMER_MARKING_TITLE: "Create New Customer Marking",

			TITLE_CUSTOMER_LOGIN_INFO: "Login Information",
			TITLE_CUSTOMER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_BILLING_ADDRESS_INFO: "Billing Address Information",
			TITLE_CUSTOMER_COMPANY_INFO: "Company Information",

			LBL_CUSTOMER_SELECT_TYPE: "N",

			LBL_CUSTOMER_LOGIN_INFO_TYPE: "Customer Login",
			LBL_CUSTOMER_LOGIN_EXITING_INFO: "Customer Login Selection",

			LBL_CUSTOMER_MARKING: "Marking",
			LBL_CUSTOMER_DEBTOR_ID: "Debtor Id",
			LBL_CUSTOMER_PARTNER_NAME: "Partner Name",
			LBL_CUSTOMER_NAME: "Partner Name",
			LBL_CUSTOMER_CUSTOMER_TYPE: "Customer Type",
			LBL_CUSTOMER_NRIC_OR_BRN: "NRIC / BRN",
			LBL_CUSTOMER_EMAIL: "Email",
			LBL_CUSTOMER_NICKNAME: "Login Nickname",
			LBL_CUSTOMER_MOBILE_NO: "Mobile No",
			LBL_CUSTOMER_PASSWORD: "Password",
			LBL_CUSTOMER_CONFIRM_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_CONTROL_ACCOUNT: "Control Account",
			LBL_CUSTOMER_MAINSALESMAN: "Main Salesman",
			LBL_CUSTOMER_SALESMAN: "Salesman",
			LBL_CUSTOMER_COMPANY: "Company",
			LBL_CUSTOMER_COMPANY_GROUP: "Company Group",
			LBL_CUSTOMER_CURRENCY: "Currency",
			LBL_CUSTOMER_CREDIT_TERM: "Credit Term",
			LBL_CUSTOMER_NOTICE_TYPE: "Notice Type",
			LBL_CUSTOMER_CREDIT_TERM_DAYS: "Term Days",
			LBL_CUSTOMER_CREDIT_TERM_DAYS: "Term Days",
			LBL_CUSTOMER_CREDIT_TERM_DAYS: "Term Days",
			LBL_CUSTOMER_IS_DEFAULT: "Is Default",
			LBL_CUSTOMER_IS_VERIFIED: "Is Verified",

			LBL_CUSTOMER_GST_NO: "GST No",
			LBL_CUSTOMER_GST_VERIFY_DATE: "GST Verify Date",
			LBL_CUSTOMER_GST_USE_NONE_DEFAULT_CODE: "Use None Default GST Code",
			CHECKBOX_USE_NONE_DEFAULT: "Use None Default",
			LBL_CUSTOMER_DEFAULT_INVOICE_FORMAT: "Default Invoice Format",
			LBL_SPECIAL_RATE: "Special Rate #",
			LBL_SPECIAL_RATE_REMARK: "Special Rate",
			LBL_SM_FINANCE_RATE: "Salesman Finance Rate",
			LBL_FINANCE_RATE: "Finance Rate",

			LBL_CUSTOMER_USERNAME: "Email",
			LBL_CUSTOMER_PASSWORD: "Password",
			LBL_CUSTOMER_RE_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_OVERDUE_LIMIT: "Overdue Limit",
			LBL_CUSTOMER_BILLING_ADDRESS: "Billing Address",
			LBL_CUSTOMER_CONTACT_PERSON_NAME: "Contact Person Name",
			LBL_CUSTOMER_CONTACT_EMAIL: "Contact Email",
			LBL_CUSTOMER_CONTACT_MOBILE_NO: "Contact Mobile No",
			LBL_CUSTOMER_CONTACT_OFFICE_NO: "Contact Office No",
			LBL_CUSTOMER_CONTACT_FAX_NO: "Contact Fax No",
			LBL_CUSTOMER_COMPANY_NAME: "Company Name",
			LBL_CUSTOMER_COMPANY_WEBSITE: "Company Website",
			LBL_CUSTOMER_TAX_TYPE: "Tax Type",
			LBL_CUSTOMER_TAX: "Tax",
			LBL_CUSTOMER_CTOS_NO: "CTOS Account",
			LBL_CUSTOMER_CTOS_DATE: "CTOS Date",

			LBL_CUSTOMER_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_BILLING_ADD_ALT_EMAIL: "Alt Email",

			LBL_CUSTOMER_MARKING_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_MARKING_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_MARKING_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_MARKING_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_MARKING_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_MARKING_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Alt Email",
			LBL_CUSTOMER_MARKING_USE_BILLING_MARKING: "Use Marking Billing?",

			LBL_CUSTOMER_PREFERENCE_TERRITORY: "Territory",
			LBL_CUSTOMER_PREFERENCE_INDUSTRIES: "Industries",
			LBL_CUSTOMER_PREFERENCE_PARTNER_TYPE: "Partner Type",
			LBL_CUSTOMER_PREFERENCE_GL_SALES_ACCOUNT: "GL Sales Account",
			LBL_CUSTOMER_PREFERENCE_STATEMENT_TYPE: "Statement Type",

			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT: "Credit Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT_OVER_ACTION_TYPE: "Action On Credit Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT: "Combine Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT_OVER_ACTION_TYPE: "Action On Combine Limit was over",

			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_DAYS: "Overdue Days",
			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_LIMIT: "Overdue Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_LIMIT_OVER_ACTION_TYPE: "Action On Overdue Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_LIMIT: "Total Overdue Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_LIMIT_OVER_ACTION_TYPE: "Action On Total Overdue Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_DAYS: "Total Overdue Days",

			LBL_CUSTOMER_NOTE_INVOICE_NOTE: "Invoice Note",
			LBL_CUSTOMER_NOTE_AR_NOTE: "AR Note",

			LBL_CUSTOMER_TRANSACTION_DESC_INVOICE_DESC: "Invoice Description",
			LBL_CUSTOMER_TRANSACTION_DESC_DN_DESC: "DN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_CN_DESC: "CN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_PAYMENT_DESC: "Transaction Description",

			LBL_CUSTOMER_INTEREST_PENALTY_PA_TITLE: "PA (Purchase)",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_MODE: "PA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_RATE: "PA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_TERMS_DAY: "PA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_ALLOW_DAY: "PA Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_SC_TITLE: "SC (Service Charge)",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_MODE: "SC Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_RATE: "SC Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_TERMS_DAY: "SC Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_ALLOW_DAY: "SC Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_TA_TITLE: "TA (TAX)",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_MODE: "TA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_RATE: "TA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_TERMS_DAY: "TA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_ALLOW_DAY: "TA Penalty Allow Day",

			LBL_CUSTOMER_CTOS_CTOS_DATE: "CTOS Date",
			LBL_CUSTOMER_CTOS_CTOS_ACCOUNT: "CTOS ACC",
			LBL_CUSTOMER_CTOS_MONITORING_DATE: "Monitoring Date",
			LBL_CUSTOMER_CTOS_ETR_SUBMISSION_DATE: "eTR Submission Date",

			TAB_TITLE_ADDRESS: "Address",
			TAB_TITLE_CONTACT: "Contact",
			TAB_TITLE_MARKING_BILLING: "Marking Billing",
			TAB_TITLE_PREFERENCE: "Preference",
			TAB_TITLE_CREDIT_CONTROL: "Credit Control",
			TAB_TITLE_NOTE: "Note",
			TAB_TITLE_TRANSACTION_DESC: "Transaction Desc",
			TAB_TITLE_INTEREST_PENALTY: "Interest Penalty",
			TAB_TITLE_CTOS: "CTOS",
			TAB_TITLE_NEW_MARKING: "New Marking",

			MODULE_NAME: "Customer",
			CUSTOMER_MARKING_MODULE_NAME: "Customer Marking",

			BTN_CREATE_NEW_CUSTOMER_MARKING: "New Marking",
			BTN_SAVE_NEW_CUSTOMER_MARKING: "Save New Marking",

			COL_COMPANY: "Company",
			COL_MAINSALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_NAME: "Name",
			COL_MARKING: "Marking",
			COL_MARKING_STATUS: "Marking Status",

			DESC_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Able to enter multiple email by semicolon(;), etc:gim_admin@gim.com;gim_admin2@gim.com;gim_admin3@gim.com",

			MESSAGE_SWITCH_NEW_MARKING_TAB: "Current marking info changes will lose. (Ignore if you didn't make any changes or saved)"
		},
		DELIVERY_ADDRESS: {
			LISTING_TITLE: "Delivery Address Listing",
			CREATE_TITLE: "Create New Delivery Address",
			DETAIL_TITLE: "Delivery Address Detail",
			EDIT_TITLE: "Edit Delivery Address Detail",

			TITLE_DELIVERY_ADDRESS_BASIC_INFO: "Basic Information",
			TITLE_DELIVERY_ADDRESS_ADDRESS_AND_INFO: "Address & Contact",
			TITLE_DELIVERY_ADDRESS_OTHER_DETAILS: "Other Details",

			LBL_DELIVERY_ADDRESS_DELIVERY_ID: "Delivery Address Id",
			LBL_DELIVERY_ADDRESS_DELIVERY_ADDRESS_DESCRIPTION: "Delivery Address Description / Company name",
			LBL_DELIVERY_ADDRESS_DEFAULT_BILLING: "Default Billing Address ?",
			LBL_DELIVERY_ADDRESS_MARKING: "Marking",
			LBL_DELIVERY_ADDRESS_CUSTOMER: "Customer",
			LBL_DELIVERY_ADDRESS_PAYMENT_TYPE: "Payment Type",
			LBL_DELIVERY_ADDRESS_NOTICE_TYPE_ID: "Notice Type ID",
			LBL_DELIVERY_ADDRESS_PRINT_ADDRESS_IN_INVOICE: "Print Address In Invoice",
			LBL_DELIVERY_ADDRESS_PRINT_DELIVERY_ADDRESS: "Print Delivery Address",
			LBL_DELIVERY_ADDRESS_CONTACT_NAME: "Contact Name",
			LBL_DELIVERY_ADDRESS_CONTACT_EMAIL: "Email",
			LBL_DELIVERY_ADDRESS_CONTACT_WEBSITE: "Contact Website",
			LBL_DELIVERY_ADDRESS_BUILDING_NAME: "Building Name",
			LBL_DELIVERY_ADDRESS_ADDRESS: "Address",
			LBL_DELIVERY_ADDRESS_POSTCODE: "Postcode",
			LBL_DELIVERY_ADDRESS_OTHER_POSTCODE: "Other Postcode",
			LBL_DELIVERY_ADDRESS_CITY: "City",
			LBL_DELIVERY_ADDRESS_OTHER_CITY: "Other City",
			LBL_DELIVERY_ADDRESS_STATE: "State",
			LBL_DELIVERY_ADDRESS_COUNTRY: "Country",
			LBL_DELIVERY_ADDRESS_TEL_NO: "Office Contact No",
			LBL_DELIVERY_ADDRESS_TEL_PIC: "Office Contact Person In Charge",
			LBL_DELIVERY_ADDRESS_TEL_NO_WITH_PIC: "Tel No + PIC",
			LBL_DELIVERY_ADDRESS_MOBILE_NO: "Mobile Contact No",
			LBL_DELIVERY_ADDRESS_CONTACT_NAME: "Mobile Contact Person In Charge",
			LBL_DELIVERY_ADDRESS_MOBILE_PIC: "PIC (MOBILE)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_WITH_PIC: "Mobile No + PIC",
			LBL_DELIVERY_ADDRESS_FAX_NO: "Fax No",
			LBL_DELIVERY_ADDRESS_RECEIVING_HOUR: "Receiving Hour",
			LBL_DELIVERY_ADDRESS_REMARK_ID: "Remark ID",
			LBL_DELIVERY_ADDRESS_REMARK: "Remark",
			LBL_DELIVERY_ADDRESS_USE_CUSTOMER_DO: "Use Customer DO ?",
			LBL_DELIVERY_ADDRESS_CUSTOMER_DO_HEADER_ID: "Customer Do Header ID",
			LBL_DELIVERY_ADDRESS_RESHIP: "Reship?",
			LBL_DELIVERY_ADDRESS_EXP_FORWARDER_ID: "Exp Forwarder ID",
			LBL_DELIVERY_ADDRESS_FORWARDER_COSTING_TYPE: "Forwarder Costing Type",
			LBL_DELIVERY_ADDRESS_AUTHORISED_LETTER: "Authorised Letter ?",
			LBL_DELIVERY_ADDRESS_HAS_ESYSTEM_ID: "Has E-System ID ?",
			LBL_DELIVERY_ADDRESS_GO_UPSTAIRS: "Go Upstairs ?",
			LBL_DELIVERY_ADDRESS_UPSTAIRS_COSTING_TYPE: "U/P Costing Type",
			LBL_DELIVERY_ADDRESS_FOLLOW_DELIVERY_ID_DUE_DAY: "Follow Delivery ID Due Day ?",
			LBL_DELIVERY_ADDRESS_DUE_DAYS: "Due Days",
			LBL_DELIVERY_ADDRESS_BY_AIR_SPECIAL_BILLING: "By Air Special Billing ?",
			LBL_DELIVERY_ADDRESS_BRN: "National Registration Identity Card / Business Register Number",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT: "Mobile Contact No (Alt)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT_PIC: "Mobile Contact (Alt) Person In Charge",
			LBL_DELIVERY_ADDRESS_CUSTOMER_NAME: "Customer Name",

			MODULE_NAME: "Delivery Address",

			COL_DELIVERY_ID: "Delivery ID",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_MARKING: "Marking",
			COL_CODE: "Code",
			COL_NAME: "Name",
			COL_COMPANY_NAME: "Delivery Address Description / Company Name",
			COL_COMPANY_GROUP: "Company Group",
			COL_MSM_NAME: "Main Salesman",
			COL_SM_NAME: "Salesman",
			COL_CONTACT_NO: "Mobile Contact No",
			COL_MOBILE_PIC: "Person In Charge",
			COL_UPSTAIRS: "Upstairs?",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_POSTCODE: "Postcode"
		},
		CUSTOMER_SUPPLIER: {
			LISTING_TITLE: "Customer Supplier Listing",
			CREATE_TITLE: "Create New Customer Supplier",
			DETAIL_TITLE: "Customer Supplier Detail",
			EDIT_TITLE: "Edit Customer Supplier",
			MODULE_NAME: "Customer Supplier",

			LBL_CUSTOEMR_SUPPLIER_CUSTOMER: "Customer",
			LBL_CUSTOEMR_SUPPLIER_NAME: "Customer Supplier Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_PIC_NAME: "Billing Mobile Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_NO: "Billing Mobile Contact No",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_PIC_NAME: "Billing Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_NO: "Billing Mobile Alt Contact No",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_PIC_NAME: "Billing Office Tel PIC Name",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_NO: "Billing Office Tel No",
			LBL_CUSTOMER_SUPPLIER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_SUPPLIER_ADDRESS: "Address",
			LBL_CUSTOMER_SUPPLIER_POSTCODE: "Postcode",
			LBL_CUSTOMER_SUPPLIER_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_SUPPLIER_CITY: "City",
			LBL_CUSTOMER_SUPPLIER_OTHER_CITY: "Other City",
			LBL_CUSTOMER_SUPPLIER_STATE: "State",
			LBL_CUSTOMER_SUPPLIER_COUNTRY: "Country",
			LBL_CUSTOMER_SUPPLIER_EMAIL: "Email",
			LBL_CUSTOMER_SUPPLIER_REMARK: "Remark",

			BTN_CREATE: "Create New Countries",

			COL_NAME: "Customer Supplier Name",
			COL_ISO_CODE3: "Iso Code 3",
			COL_ISO_CODE2: "Iso Code 2",
			COL_CALIING_CODE: "Phone Code",

			PH_ENTER_CUSTOEMR_SUPPLIER_NAMEE: "Enter Customer Supplier Name",

			MSG_FAILED_TO_UPDATE: "Customer Supplier has been failed edited",
			MSG_SUCCESS_TO_UPDATE: "Customer Supplier has been successfully edited",

			MSG_FAILED_CREATE_COUNTRIES: "New Customer Supplier has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Customer Supplier has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Customer Supplier has been deleted",

			MSG_CALLING_CODE_EMPTY: "Calling Code records have been not found"
		},
		CUSTOMER_DO_HEADER: {
			LISTING_TITLE: "Customer Do Header Listing",
			CREATE_TITLE: "Create New Customer Do Header",
			DETAIL_TITLE: "Customer Do Header Detail",
			EDIT_TITLE: "Edit Customer Do Header Detail",

			TITLE_CUSTOMER_DO_HEADER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_DO_HEADER_ADDRESS_AND_INFO: "Address & Contact",

			LBL_CUSTOMER_DO_HEADER_CODE: "Code",
			LBL_CUSTOMER_DO_HEADER_NAME: "Name",
			LBL_CUSTOMER_DO_HEADER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_DO_HEADER_ADDRESS: "Address",
			LBL_CUSTOMER_DO_HEADER_POSTCODE: "Postcode",
			LBL_CUSTOMER_DO_HEADER_CITY: "City",
			LBL_CUSTOMER_DO_HEADER_STATE: "State",
			LBL_CUSTOMER_DO_HEADER_COUNTRY: "Country",
			LBL_CUSTOMER_DO_HEADER_TEL_NO: "Office No",
			LBL_CUSTOMER_DO_HEADER_FAX_NO: "Fax No",
			LBL_CUSTOMER_DO_HEADER_WEBSITE: "Website",
			LBL_CUSTOMER_DO_HEADER_DO_NUMBER_FORMAT: "Do Number Format",
			LBL_CUSTOMER_DO_HEADER_REMARK: "Remark",
			LBL_CUSTOMER_DO_HEADER_CONTACT_PIC: "Contact Pic",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NO: "Contact No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_EMAIL: "Email",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO: "Mobile No.",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT: "Mobile No.(Alt) ",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT_PIC: "Mobile No.(Alt) Pic",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NAME: "Mobile No. Pic",
			LBL_CUSTOMER_DO_HEADER_TEL_PIC: "Office Contact Pic",
			LBL_CUSTOMER_DO_HEADER_CONTACT_WEBSITE: "Website",

			MODULE_NAME: "Customer Do Header",

			COL_CODE: "Customer Do Header Code",
			COL_NAME: "Customer Do Header Name"
		},
		CUSTOMER_UPDATE_LOG: {
			LISTING_TITLE: "Customer Update Log Listing",
			CREATE_TITLE: "Create New Customer Update Log",
			DETAIL_TITLE: "Customer Update Log Detail",
			EDIT_TITLE: "Edit Customer Update Log Detail",

			TITLE_CUSTOMER_UPDATE_LOG_ACCOUNT_INFO: "New / Update Information",
			TITLE_CUSTOMER_UPDATE_LOG_CURRENT_INFO: "Current Information",
			TITLE_CUSTOMER_UPDATE_LOG_ADDRESS_AND_INFO: "Address & Contact",

			LBL_CUSTOMER_UPDATE_LOG_REQUEST_TYPE: "Request Type",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_NAME: "Customer Name",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_TYPE: "Customer Type",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_NRIC_OR_BRN: "NRIC / BRN",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_CONTACT_PIC_NAME: "Billing Mobile Contact PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_CONTACT_NO: "Billing Mobile Contact No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_ALT_CONTACT_PIC_NAME: "Billing Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_ALT_CONTACT_NO: "Billing Mobile Alt Contact No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OFFICE_TEL_PIC_NAME: "Billing Office Tel PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OFFICE_TEL_NO: "Billing Office Tel No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_ADDRESS: "Address",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_POSTCODE: "Postcode",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_CITY: "City",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OTHER_CITY: "Other City",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_STATE: "State",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_COUNTRY: "Country",
			LBL_CUSTOMER_UPDATE_LOG_EMAIL: "Email",
			LBL_CUSTOMER_UPDATE_LOG_STATUS: "Status",

			LBL_CUSTOMER_UPDATE_LOG_REMARK_ID: "Remark ID",
			LBL_CUSTOMER_UPDATE_LOG_REMARK: "Remark",
			LBL_CUSTOMER_UPDATE_LOG_REJECT_REMARK: "Other Reject Remark",
			REQUEST_TYPE_SELECTION_CREATE_NEW: "Create New",
			REQUEST_TYPE_SELECTION_UPDATE: "Update",

			MODULE_NAME: "Customer Update Log",

			COL_REQUEST_TYPE: "Request Type",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_NEW_NAME: "New Name",
			COL_ID: "ID",
			COL_NRIC_OR_BRN: "NRIC/BRN",
			COL_UPDATE_AT: "Update at",
			COL_HISTORY: "History",
			COL_NEW_NRIC_OR_BRN: "New NRIC/BRN",
			COL_REQUESTED_ON: "Requested On",

			TEXT_REDIRECT_TO_CUSTOER_PAGE: "Are you sure want to go Customer Editor Page."
		}
	},
	USER_N_ROLE_MANAGEMENT: {
		USER: {
			LISTING_TITLE: "User Listing",
			CREATE_TITLE: "Create New User",
			DETAIL_TITLE: "User Detail",
			EDIT_TITLE: "Edit User Detail",

			LBL_USER_NAME: "Name",
			LBL_USER_USERNAME: "Username",
			LBL_USER_EMAIL: "Email",
			LBL_USER_PASSWORD: "Password",
			LBL_USER_ROLE: "Role",
			LBL_USER_DATA_FILTER: "Data Filter",
			LBL_USER_IGNORE_FILTER: "Ignore Filter",

			MODULE_NAME: "User",

			COL_USERNAME: "User Name",
			COL_NAME: "Name"
		},
		ROLE: {
			LISTING_TITLE: "Role Listing",
			CREATE_TITLE: "Create New Role",
			DETAIL_TITLE: "Role Detail",
			EDIT_TITLE: "Edit Role Detail",

			LBL_ROLE_NAME: "Name",
			LBL_ROLE_DESCRIPTION: "Description",
			LBL_ROLE_PERMISSION_PRIVILEGE: "Permission & Privilege",

			MODULE_NAME: "Role",

			COL_NAME: "Role Name"
		},
		DATA_FILTER: {
			LISTING_TITLE: "Data Filter Listing",
			CREATE_TITLE: "Create New Data Filter",
			DETAIL_TITLE: "Data Filter Detail",
			EDIT_TITLE: "Edit Data Filter Detail",

			LBL_DATA_FILTER_NAME: "Name",
			LBL_DATA_FILTER_DESCRIPTION: "Description",
			LBL_DATA_FILTER_COMPANY: "Company",
			LBL_DATA_FILTER_WAREHOUSE: "Warehouse",
			LBL_DATA_FILTER_MSM: "Main Salesman",
			LBL_DATA_FILTER_SM: "Salesman",
			LBL_DATA_FILTER_PERMISSION_PRIVILEGE: "Permission & Privilege",

			MODULE_NAME: "Data Filter",

			COL_NAME: "Data Filter Name"
		}
	},
	TEMPLATE_SETTINGS: {
		ANNOUNCEMENT_MANAGEMENT: {
			LISTING_TITLE: "Announcement Listing",
			CREATE_TITLE: "Create Announcement",
			EDIT_TITLE: "Edit Announcement",
			MODULE_NAME: "Announcement",

			ANNOUNCEMENT_NAME: "Announcement Name",
			ANNOUNCEMENT_URL: "Announcement URL",
			ANNOUNCEMENT_TITLE_ENG: "Announcement Title (English)",
			ANNOUNCEMENT_TITLE_CHI: "Announcement Ttile (Chinese)",
			ANNOUNCEMENT_TEXT_ENG: "Announcement Text (English)",
			ANNOUNCEMENT_TEXT_CHI: "Announcement Text (Chinese)",
			ANNOUNCEMENT_START_DATE: "Announcement Start Date",
			ANNOUNCEMENT_END_DATE: "Announcement End Date",

			LIMIT_ACTIVE_ERROR: "Only one announcement can be set as active"
		},
		NOTICE_MANAGEMENT: {
			LISTING_TITLE: "Notice Listing",
			CREATE_TITLE: "Create Notice",
			EDIT_TITLE: "Edit Notice",
			DETAIL_TITLE: "Notice Detail",
			MODULE_NAME: "Notice",

			NOTICE_NAME: "Notice Name",
			NOTICE_URL: "Notice URL",
			NOTICE_TITLE_ENG: "Notice Title (English)",
			NOTICE_TITLE_CHI: "Notice Ttile (Chinese)",
			NOTICE_TEXT_ENG: "Notice Text (English)",
			NOTICE_TEXT_CHI: "Notice Text (Chinese)",
			NOTICE_START_DATE: "Notice Start Date",
			NOTICE_END_DATE: "Notice End Date",

			LIMIT_ACTIVE_ERROR: "Only one notice can be set as active"
		},
		SLIDER_MANAGEMENT: {
			LISTING_TITLE: "Slider Listing",
			ADD_TITLE: "Add New Slider",
			DETAIL_TITLE: "Slider Detail",
			EDIT_TITLE: "Edit Slider",
			MODULE_NAME: "Slider",

			SLIDER_NAME: "Slider Name",
			SLIDER_SEQUENCE: "Slider Sequence",
			SLIDER_CAPTION: "Slider Caption",
			SLIDER_IMAGE_ENGLISH: "Slider Image (English version)",
			SLIDER_IMAGE_CHINESE: "Slider Image (Chinese version)",
			SLIDER_URL: "Slider URL",
			SLIDER_START_DATE: "Slider Start Date",
			SLIDER_END_DATE: "Slider End Date",

			MSG_FAILED_TOUPLOAD_IMAGE: "Not able to upload image",
			MSG_FAILED_TOUPDATE: "Not able to update details",

			MSG_SUPPORT_IMAGE_FORMAT: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_SUPPORT_IMAGE_SIZE: "*image size(W x L) 1920x690",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		},
		BANNER_MANAGEMENT: {
			LISTING_TITLE: "Banner Listing",
			ADD_TITLE: "Add New Banner",
			DETAIL_TITLE: "Banner Detail",
			EDIT_TITLE: "Edit Banner",
			MODULE_NAME: "Banner",

			BANNER_NAME: "Banner Name",
			BANNER_CAPTION: "Banner Caption",
			BANNER_IMAGE_ENGLISH: "Banner Image (English version)",
			BANNER_IMAGE_CHINESE: "Banner Image (Chinese version)",
			BANNER_SEQUENCE: "Banner Sequence",
			BANNER_URL: "Banner URL",
			BANNER_START_DATE: "Banner Start Date",
			BANNER_END_DATE: "Banner End Date",

			MSG_FAILED_TOUPLOAD_IMAGE: "Not able to upload image",

			MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_IMAGE_SIZE: "*image size(W x L) 590x208",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		}
	},
	CMS: {
		PAGE: {
			LISTING_TITLE: "Page Listing",
			ADD_NEW_PAGE: "Add New Page",
			ADD_CMS: "Add New CMS Page",
			DETAIL_TITLE: "Page Detail",
			EDIT_TITLE: "Edit Page",
			PAGE_NAME: "Page Name",
			PAGE_LANGUAGE: "Page Language",
			PAGE_SLUG: "Page Slug",
			PAGE_DESCRIPTION: "Page Description",
			PAGE_LAYOUT: "Page Layout",
			PAGE_MENU: "Page Menu",
			PAGE_TITLE: "Page Title",
			PAGE_CONTENT: "Page Content",

			MODULE_NAME: "Page",

			PAGE_LAYOUT_SELECTION: {
				NORMAL: "Normal Layout",
				BARE: "Bare Layout"
			}
		},
		MENU: {
			LISTING_TITLE: "Menu Listing",
			ADD_NEW_MENU: "Add New Menu",
			ADD_CMS: "Add New CMS Menu",
			DETAIL_TITLE: "Menu Detail",
			EDIT_TITLE: "Edit Menu",
			LBL_MENU_NAME: "Menu Name",
			LBL_MENU_DESCRIPTION: "Description",
			LBL_MENU_TYPE: "Menu Type",

			COL_MENU_NAME: "Menu Name",
			COL_MENU_TYPE: "Menu Type",

			MODULE_NAME: "Menu",
			MENU_TYPE: {
				CMS_PAGE: "CMS Page",
				HEADER_PAGE: "Header (System Use Unselectable)",
				FOOTER_PAGE: "Footer (System Use Unselectable)",
				DASHBOARD_PAGE: "Dashboard (System Use Unselectable)"
			},
			BTN_MENU_ITEMS: "Menu Items"
		},
		MENU_ITEMS: {
			LISTING_TITLE: "Menu Item Listing",
			ADD_NEW_MENU_ITEMS: "Add New Menu Item",
			ADD_CMS: "Add New CMS Menu Item",
			DETAIL_TITLE: "Menu Item Detail",
			EDIT_TITLE: "Edit Menu Item",
			LBL_MENU_ITEMS_NAME: "Menu Item Name",
			LBL_MENU_ITEMS_TITLE: "Title",
			LBL_MENU_ITEMS_TYPE: "Menu Item Type",
			LBL_MENU_ITEMS_CMS_PAGE: "CMS Page",
			LBL_MENU_ITEMS_URL: "Menu URL",
			LBL_MENU_ITEMS_REQUIRED_TYPE: "Required Type",
			LBL_MENU_ITEMS_REDIRECT: "Redirect",
			LBL_LANGUAGE_SELECTION: "Language Selection",
			REDIRECT_OPEN_NEW_TAB: "Open with new tab",
			LBL_MENU_ITEMS_ORDER_SEQUENCE: "Order Sequence",

			COL_MENU_ITEMS_NAME: "Menu Name",
			COL_MENU_ITEMS_TITLE: "Title",
			COL_MENU_ITEMS_TYPE: "Menu Item Type",
			COL_MENU_ITEMS_REDIRECT: "Redirect",

			MODULE_NAME: "Menu Item",
			MENU_TYPE: {
				EXTERNAL_LINK: "External Link",
				SYSTEM_PAGE: "System Page",
				CMS_PAGE: "CMS Page"
			}
		}
	},
	PRODUCT_MANAGEMENT: {
		SPECIAL_PRODUCT: {
			LISTING_TITLE: "Special Product Listing",
			DETAIL_TITLE: "Special Product Detail",
			EDIT_TITLE: "Edit Special Product",
			MODULE_NAME: "Special Product",
			CREATE_TITLE: "Create Special Product",
			SUMMARY: {
				COL_PRODUCT_NAME: "Product Name",
				COL_PRODUCT_STATUS: "Product Status",
				COL_PRODUCT_IMAGE: "Product Image",
				COL_VERIFIED_BY: "Verified By",
				COL_VERIFIED_ON: "Verified On",
				COL_PRODUCT_CATEGORY: "Product Category Lvl 2",
				COL_PRODUCT_CATEGORY_PARENT: "Product Category Parent",
				COL_INQUIRY_QUOTATION: "Inquiry Quotation No.",
				COL_PRODUCT_REMARK: "Product Remark"
			},

			LBL_PRODUCT_NAME: "Product Name",
			LBL_CATEGORY: "Product Category",
			LBL_PRODUCT_STATUS: "Product Status",
			LBL_CATEGORY_PARENT: "Product Category (Parent)",
			LBL_REMARK: "Remark",
			LBL_PRODUCT_IMAGE: "Product Image"
		}
	}
};
