export const locale = {
	WEEKDAYS: {
		MONDAY: "Monday",
		TUESDAY: "Tuesday",
		WEDNESDAY: "Wednesday",
		THURSDAY: "Thursday",
		FRIDAY: "Friday",
		SATURDAY: "Saturday",
		SUNDAY: "Sunday",
	},
	COMMON: {
		LBL_AGENT: "GBizs Agent",
		LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
		LBL_MOBILE_CONTTACT_PIC: "Mobile Contact Pic",

		LBL_ALT_MOBILE_CONTACT_NO: "Mobile Contact No. (Alt)",
		LBL_ALT_MOBILE_CONTTACT_PIC: "Mobile Contact Pic (Alt)",

		LBL_OFFICE_NO: "Office No. ",
		LBL_OFFICE_NO_PIC: "Office No. PIC",
		LBL_SET_INVALID_PERSONNEL: "Invalid By",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_ACKNOWLEDGE_BY: "Acknowledge By",
		LBL_VERIFY_BY: "Verify By",
		LBL_ACTION_TIME: "Action Time",
		LBL_REFERENCE_NO: "Ref. No.",
		LBL_HISTORY_DATE: "Modified Date",

		LBL_FAX_NO: "Fax No.",
		LBL_FAX_NO_PIC: "Fax No. PIC",

		TXT_ALL: "All",
		TOTAL_TITLE: "Total",

		SELECTION_OTHERS: "Others",
		PH_ENTER_TEXTBOX: "Enter {txt_fieldname}",
		PH_ENTER_DATE: "Enter Date",
		PH_ENTER_DATE_FROM: "Enter {name} From",
		PH_ENTER_DATE_TO: "Enter {name} To",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT:
			"Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED:
			"Image format not supported. Supported format: .jpg, .jpeg, .png",
		MSG_SUPPORT_DOC_SIZE_FORMAT:
			"Supported Document size should be less than 10 MB",

		STATUS: {
			INACTIVE: "Inactive",
			ACTIVE: "Active",
			SUSPEND: "Suspend",
			UNVERIFIED: "Un-verify",
			YES: "Yes",
			WGI: "WGI",
			NO: "No",
			DRAFT: "Save As Draft",
			PENDING: "Pending",
			APPROVED: "Approved",
			REJECTED: "Rejected",
			VERIFY: "Verifying",
			CLOSED: "Closed",
			OPEN: "Open",
			ACKNOWLEDGE: "Acknowledge",
			NEW: "New"
		},


		MSG: {
			API_SERVER_DOWN: "Server Connection Refused. Please contact System Admin.",
			MSG_DATA_NOT_FOUND: "No Records Found",
			DATA_NOT_AVAILABLE: "No Data Available",
		},

		BTN_DOWNLOAD_ALL_AS_PDF: "Download All As PDF",
		BTN_DOWNLOAD_AS_PDF: "Download As PDF",
		BTN_ENABLE_EDIT: "Enable Edit {name}",
		BTN_DISABLE_EDIT: "Disable Edit {name}",
		BTN_REJECT: "Reject",
		BTN_VERIFY: "Verify",
	},
	AUTH: {
		BTN_SIGN_OUT: "Sign Out",
		USERNAME_TITLE: "Hi, {username}",
	},
	EPO: {
		STATUS: {
			ACTIVE: "Active",
			SAVE_AS_DRAFT: "Save As Draft",
			DELETED: "Deleted",
			CUSTOMER_DELETE: "Inactive",
			PENDING: "Pending",
		},

		FORMAT_CN: "Chinese Format",
		FORMAT_EN: "English Format",
		FORMAT_IN: "Indonesian Format",
		FORMAT_TH: "Thai Format",
		FORMAT_VN: "Vietnamese Format",
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "Create New",
			UPDATE_TYPE: "Update",
		},
		STATUS: {
			PENDING: "Pending",
			VERIFYING: "Verifying",
			APPROVED: "Approved",
			REJECTED: "Rejected",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "Save As Delivery Address",
	},
	TT: {
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			INTERMEDIATE_TITLE: "Intermediate Bank Details",
			INTERMEDIATE_CHECKBOX: "Intermediate Bank?",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN",
			INTER_BANK_NAME: "Intermediate Bank Name",
			INTER_BANK_ACC: "Intermediate Account No",
			INTER_SWIFT_CODE: "Intermediate BIC / Swift Code",
			INTER_IBAN_CODE: "Intermediate IBAN Code",
			BENE_DETAIL_NAME: "Beneficiary Detail Name"
		},
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			RECEIPT: "Receipt"
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",
			OTHER: "Other",
			PN: "PN",
			TOTAL: "Total",
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			ACCOUNT_NAME: "Account Name",
			ACCOUNT_NO: "Account No.",
			BANK_ADDRESS: "Bank Branch",
			BANK_NAME: "Bank Name"
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			CURRENCY: "Currency",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",

			TOTAL_AMT: "Order Amount",
			DISCOUNT: "Discount",
			DEPOSIT: "Deposit",
			STICKER_FEE: "Deduct Sticker Fee",
			BALANCE_AMT: "BALANCE AMOUNT",
			TOTAL: "Total"
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	REPORT: {
		PACKING_LIST_FORMAT: {
			TITLE: "Packing List Details (C) New",
			SO_NO: "S/O No.",
			SUPPLIER_NAME: "Sup Name",
			T_M3: "T/M3<br />(T/CM3)",
			T_KG: "T/Kg<br />(Vol Weight)",
			T_QTY: "T/Pqty",
			BILL_AMT: "Bill Amt",
			LOADED_AMOUNT: "Loaded Amt",
			CUS_DEPOSIT: "Cus Deposit",
			MY_PAYMENT: "My P'ment",
			FCP_AMT: "FCP Amount",
			SUP_DEPOSIT: "Sup Deposit",
			LOCAL_PAYMENT: "Local P'ment",

			STOCK_DESC: "Stock Short Desc",
			M3: "M3",
			KG: "Kg",
			P_QTY: "P Qty",
			QUANTITY: "Quantity",
			UOM: "Uom",
			UNIT_PRICE: "Unit Price",
			AMOUNT: "Amount",
			RECEIVE_DATE: "Received Date",
			REF_ID: "Ref ID",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",

			REPORT_FILTER: "Report Filter",
			SHOW_M3: "Show M3?",
			SHOW_KG: "Show Kg?",
			SHOW_BILL_AMOUNT: "Show Bill Amount?",
			SHOW_STOCK_DESC1: "Show Stock Desc 1?",
			SHOW_STOCK_DESC2: "Show Stock Desc 2?",
			SHOW_SUPPLIER: "Show Supplier",
			YES: "Yes",
			NO: "No",
			CURRENCY_ID: "Currency ID",
			REMARK: "Remark",
			DEPOSIT_DATE: "D. Date",
			DEPOSIT_NO: "PN #",
			DEPOSIT_RATE: "TR",
			DEPOSIT_AMOUNT: "TS Amt",
			DEPOSIT_CHARGE: "FTC",
			DEPOSIT_CHARGE_MYR: "TC (RM)",
			DEPOSIT_IR: "IR %",
			DEPOSIT_OR: "AOR No.",

			SUB_TTL_T_M3: "T/M3",
			SUB_TTL_T_KG: "T/Kg",
			SUB_TTL_T_PQTY: "T/Pqty",
			SUB_TTL_REF: "Ref ",
			SUB_TTL_VOL_WEIGHT: "Vol Weight",
			SUBTOTAL: "SubTotal",
			CURRENCY: "Currency"
		},
		STOCK_BALANCE_FORMAT: {
			TITLE: "Stock Balance Report with Payment Info",
			DETAIL: "Detail",
			SUMMARY: "Summary",
			SERIAL_NO: "S/N",
			CURRENCY_ID: "Cur ID",
			SO_NO: "S/O No.",
			RECEIVE_DATE: "Rec Date",
			NOTICE_ID: "Notice ID",
			TOTAL_M3: "Total M3",
			TOTAL_PACK: "Total Pack",
			BAL_AMT: "Balance Amount",
			CUS_DEPOSIT: "Cust Deposit",
			MY_PAYMENT: "My Payment",
			SUP_DEPOSIT: "Sup Deposit",
			CASH_PAYMENT: "Cash P'ment",
			CREDIT_PAYMENT: "Credit P'ment",
			FCP_AMOUNT: "FCP Amount",
			PAYABLE_AMT: "Payable Amt",

			PO_DATE: "PO Date",
			STOCK_SHORT: "Stock Short",
			STOCK_DESC: "Stock Desc",
			M3: "M3",
			BAL_PACK: "Bal Pack",
			QUANTITY: "Quantity",
			PURCHASE_PRICE: "Purchase Price",
			AMOUNT: "Amount",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",
			OR_DATE: "O/R Date",
			LOADED_DATE: "Loaded Date",
			// REPORT_FILTER: "All / KCG Only / Exclude KCG",
			FROM: "From",
			TO: "To",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			DELIVERY_ID_TOTAL: "Delivery ID Total",
			MARKING_TOTAL: "Marking Total",
			SALESMAN_TOTAL: "Salesman Total",
			MAIN_SALESMAN_TOTAL: "Main Salesman Total",
			CURRENCY_TOTAL: "Currency Total"
		},
		DO_FORMAT: {
			FORMAT_TITLE: "DELIVERY ORDER",
			TEL_NO: "Tel No.",
			GOODS_RECEIVE_CONDITION: "Goods received in good order & condition",
			SIGNATURE_TITLE: "Authorised Signature & Co Stamp",
			FOOTER_E_DESC: "Computer Generated, no signature required",
			FOOTER_END: "== End ==",

			DONO: "D/Order #",
			REF_ID: "Yr Ref",
			DO_DATE: "Date",
			TRANPORTER: "T-Ref",
			PAGE: "Page",
			MOBILE_NO: "Mobile No.",
			FORWARDER: "Forwarder",
			FAX: "Fax",
			REMARK1: "Remark1",
			REMARK2: "Remark2",
			SERIAL_NO: "S/N",
			SO_NO: "",
			DESCRIPTION: "Description",
			QUANTITY: "Quantity",
			UNIT: "Unit",
			PKG: "Pkg",
			QTY: "Qty",
			TOTAL_M3: "Total M3",
			TOTAL_KG: "Total KG",
			TOTAL_PACKING: "Total Packing",
		}
	}
};
