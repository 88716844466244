<template>
	<div>
		<v-data-table
			:items="items"
			:headers="headers"
			:footer-props="footer_props"
			:options.sync="options"
			:loading="loading"
			:server-items-length="totalRecord == null ? items.length : totalRecord"
			:item-key="itemKey"
			fixed-header
			class="mt-1"
			:class="vtableClass"
			dense
			:single-select="singleSelect"
			:show-select="disabled_select_row == 1 ? false : true"
			v-model="singleRow"
			@click:row="singleRowSelect"
			v-if="!['customer supplier', 'doSummary'].includes(moduleName)"
			:hide-default-footer="hide_footer == 1"
			:hide-default-header="hide_header"
			:height="!$CoreSettingsJs.emptyStringValidation(height, 1) ? height : dataTableHeight"
			:item-class="itemClass"
		>
			<template #header="{ props: { headers } }" v-if="['base_cost', 'transit_cost', 'marking_cost', 'category_type_cost'].includes(moduleName) && !$CoreSettingsJs.emptyObjectValidation(items)">
				<slot name="custom_header" v-bind="headers"></slot>
			</template>
			<template
				#item="{ item, index }"
				v-if="['base_cost', 'transit_cost', 'marking_cost', 'category_type_cost', 'sm_cost', 'customer_cost'].includes(moduleName) && !$CoreSettingsJs.emptyObjectValidation(items)"
			>
				<slot name="row_item" v-bind="{ item, index }"></slot>
			</template>

			<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="header">
				<slot :name="header" v-bind="header"></slot>
			</template>

			<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
				<slot :name="name" v-bind="item"></slot>
				<slot :name="item" v-bind="item"></slot>
			</template>

			<template v-if="disableBox == 1" v-slot:item.data-table-select="{ item, isSelected, select }">
				<v-simple-checkbox :value="isSelected" :readonly="true" :disabled="true" @input="singleRowSelect($event)"></v-simple-checkbox>
			</template>
			<template
				v-if="
					['pnPayment', 'customerDoHeader', 'customer', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest', 'receipt_maintainance', 'receipt_payment'].includes(
						moduleName
					)
				"
				v-slot:[`item.salesman`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['salesman']"></span>
			</template>
			<template
				v-if="
					['pnPayment', 'customerDoHeader', 'customer', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest', 'receipt_maintainance', 'receipt_payment'].includes(
						moduleName
					)
				"
				v-slot:[`item.main_salesman`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['main_salesman']"></span>
			</template>
			<template
				v-if="
					['pnPayment', 'customerDoHeader', 'customer', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest', 'receipt_maintainance', 'receipt_payment'].includes(
						moduleName
					)
				"
				v-slot:[`item.marking`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['marking']"></span>
			</template>
			<template
				v-if="
					[
						'pnPayment',
						'customerDoHeader',
						'customer',
						'specialInstructionByEPO',
						'specialInstructionByMarking',
						'depositRequest',
						'payment_advice',
						'receipt_maintainance',
						'receipt_payment',
						'post_receipt'
					].includes(moduleName)
				"
				v-slot:[`item.full_marking`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['full_marking']"></span>
			</template>
			<template v-if="moduleName == `customer`" v-slot:[`item.credit_term_code`]="{ item }">
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['credit_terms']"></span>
			</template>
			<template v-if="['customer', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest'].includes(moduleName)" v-slot:[`item.notice_type_code`]="{ item }">
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['notice_type']"></span>
			</template>
			<template
				v-if="
					['pnPayment', 'customerDoHeader', 'customer', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest', 'receipt_maintainance', 'receipt_payment'].includes(
						moduleName
					)
				"
				v-slot:[`item.company_group`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['company_group']"></span>
			</template>
			<template
				v-if="['pnPayment', 'customerDoHeader', 'specialInstructionByEPO', 'specialInstructionByMarking', 'depositRequest', 'receipt_maintainance', 'receipt_payment'].includes(moduleName)"
				v-slot:[`item.customer_name`]="{ item }"
			>
				<span v-if="!$CoreSettingsJs.emptyObjectValidation(item.marking_set)" v-text="item.marking_set['customer_name']"></span>
			</template>
			<template v-if="moduleName == `epo`" v-slot:[`item.epo_type`]="{ item }">
				<span v-text="getEpoTypeDesc(item)"></span>
			</template>
			<template v-if="moduleName == `epo`" v-slot:[`item.epo_date`]="{ item }">
				<span v-text="$CoreSettingsJs.UnixTimeToDate(item.epo_date)"></span>
			</template>
			<template v-if="moduleName == `epo`" v-slot:[`item.grand_total`]="{ item }">
				<span v-text="`${item.currency_code} ${$CoreSettingsJs.onFormatterNo(item.grand_total)}`" v-show="item.currency_code != null"></span>
				<span v-show="item.currency_code == null" v-text="`${$CoreSettingsJs.onFormatterNo(item.grand_total)}`"></span>
			</template>
			<template v-if="moduleName == `epo`" v-slot:[`item.total_deposit`]="{ item }">
				<span v-text="`${item.currency_code} ${$CoreSettingsJs.onFormatterNo(item.total_deposit)}`" v-show="item.currency_code != null"></span>
				<span v-show="item.currency_code == null" v-text="`${$CoreSettingsJs.onFormatterNo(item.total_deposit)}`"></span>
			</template>
			<template v-if="moduleName == `epo`" v-slot:[`item.request_plg`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.request_plg)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.request_plg)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `invoice`" v-slot:[`item.inv_format`]="{ item }">
				<span v-text="getInvoiceFormat(item.inv_format)"></span>
			</template>
			<template v-if="moduleName == `currencies`" v-slot:[`item.tt_charges_settings`]="{ item }">
				<span v-text="getTTChargesSettingsDesc(item.tt_charges_settings)"></span>
			</template>
			<template v-if="moduleName == 'salesman'" v-slot:[`item.sm_qr`]="{ item }">
				<b-button @click="downloadAsImage(items.indexOf(item), item)" size="sm" class="border-0 bg-transparent">
					<!-- <canvas class="sm_qr pt-1" :id="`qrcode-${items.indexOf(item)}`"></canvas> -->
					<qrcode :id="`qrcode-${items.indexOf(item)}`" :text="`${customer_portal}register/${item.id}?code=${encodeURIComponent(item.referral_code)}`" :size="100"></qrcode>
					<!-- <div v-text="item.sm_code"></div> -->
				</b-button>
			</template>
			<template v-if="moduleName == `customer`" v-slot:[`item.marking_status`]="{ item }">
				<b-badge v-if="item.marking_status != null" class="text-center" :variant="markingStatusBadge(item.marking_status)" size="sm">
					<span v-text="markingStatusName(item.marking_status)"></span>
				</b-badge>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<b-badge
					v-show="!['requestAccountLog', 'specialProd', 'epo', 'depositRequest', 'payment_advice'].includes(moduleName)"
					class="text-center"
					:variant="$CoreSettingsJs.handleBadgeVariant(item.status)"
					size="sm"
				>
					<span v-text="$CoreSettingsJs.handleStatusDesc(item.status, moduleName)"></span>
				</b-badge>
				<b-badge v-show="moduleName == 'requestAccountLog'" class="text-center" :variant="item.status_badge" size="sm">
					<span v-text="item.status_name"></span>
				</b-badge>

				<b-badge v-show="moduleName == 'specialProd'" class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.status)" size="sm">
					<span v-text="$CoreSettingsJs.handleStatusDesc(item.status, moduleName)"></span>
				</b-badge>
				<span
					v-show="['epo', 'depositRequest', 'payment_advice'].includes(moduleName)"
					class="badge text-nowrap"
					:style="$CoreSettingsJs.colorIndicatorStyle(item.status_color, $CoreSettingsJs.textColor(item.status_color), 2)"
				>
					<span
						v-text="$CoreSettingsJs.emptyStringValidation(item.status_name) || (moduleName == 'depositRequest' && item.deposit_status == 0) ? $t('COMMON.STATUS.DRAFT') : item.status_name"
					></span>
				</span>
			</template>
			<template v-slot:[`item.is_transit`]="{ item }">
				<b-form-checkbox v-model="item.is_transit" :checked="item.is_transit == 1" value="1" unchecked-value="0" :disabled="true" switch>
					<span v-text="$t('COMMON.STATUS.YES')"></span>
				</b-form-checkbox>
			</template>
			<template v-if="moduleName == `receiptPayment`" v-slot:[`item.issue_gor`]="{ item }">
				<b-form-checkbox v-model="item.issue_gor" :checked="item.issue_gor == 1" value="1" unchecked-value="0" :disabled="true" switch>
					<span v-text="$t('COMMON.STATUS.YES')"></span>
				</b-form-checkbox>
			</template>
			<template v-if="moduleName == `receiptPayment`" v-slot:[`item.cheque_return`]="{ item }">
				<b-form-checkbox v-model="item.cheque_return" :checked="item.cheque_return == 1" value="1" unchecked-value="0" :disabled="true" switch>
					<span v-text="$t('COMMON.STATUS.YES')"></span>
				</b-form-checkbox>
			</template>
			<template v-if="moduleName == `receiptPayment`" v-slot:[`item.posted`]="{ item }">
				<b-form-checkbox v-model="item.posted" :checked="item.posted == 1" value="1" unchecked-value="0" :disabled="true" switch>
					<span v-text="$t('COMMON.STATUS.YES')"></span>
				</b-form-checkbox>
			</template>
			<template v-if="moduleName == `menu`" v-slot:[`item.menu_type`]="{ item }">
				<span v-text="getMenuType(item.menu_type)"></span>
			</template>
			<template v-if="moduleName == `docNum`" v-slot:[`item.num_by_company_group`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.num_by_company_group)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.num_by_company_group)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `docNum`" v-slot:[`item.num_with_warehouse_country`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.num_with_warehouse_country)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.num_with_warehouse_country)"></span>
				</b-badge>
			</template>
			<!-- <template v-if="moduleName == `customerDoHeader`" v-slot:[`item.doc_num_id`]="{ item }">
				<span v-text="getDocNumId(item.doc_num_id)"></span>
			</template> -->
			<template v-if="moduleName == `docNum`" v-slot:[`item.num_reset`]="{ item }">
				<span v-text="getNumResetDesc(item.num_reset)"></span>
			</template>
			<template v-if="moduleName == `weightRound`" v-slot:[`item.round_style`]="{ item }">
				<span v-text="getRounding(item.round_style)"></span>
			</template>
			<template v-if="moduleName == `localeSummary`" v-slot:[`item.image`]="{ item }">
				<span class="symbol symbol-20 mr-3">
					<img :src="item.image" alt="" />
				</span>
			</template>
			<template v-if="moduleName == `localeSummary`" v-slot:[`item.support_at`]="{ item }">
				<span v-text="getSupportAt(item.support_at)"></span>
			</template>
			<template v-if="['instructionType'].includes(moduleName)" v-slot:[`item.color_indicator`]="{ item }">
				<div align-v="center" :style="$CoreSettingsJs.colorIndicatorStyle(item.color_indicator, $CoreSettingsJs.textColor(item.color_indicator))" v-text="item.color_indicator"></div>
			</template>
			<template v-if="moduleName == `specialInstructionByMarking` || moduleName == `specialInstructionByEPO`" v-slot:[`item.warehouse_code`]="{ item }">
				<span v-text="item.warehouse_code == null ? $t('COMMON.TXT_ALL') : item.warehouse_code"></span>
			</template>
			<template v-if="moduleName == `specialInstructionByMarking`" v-slot:[`item.delivery_id`]="{ item }">
				<span v-text="item.delivery_id == null ? $t('COMMON.TXT_ALL') : item.delivery_id"></span>
			</template>
			<template v-if="moduleName == `noticeType`" v-slot:[`item.order_receipt`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.order_receipt)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.order_receipt)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `noticeType`" v-slot:[`item.cash_payment`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.cash_payment)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.cash_payment)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `docType`" v-slot:[`item.use_same_id`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_same_id)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.use_same_id)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `docType`" v-slot:[`item.posting_side`]="{ item }">
				<span v-text="getPostingSideDesc(item.posting_side)"></span>
			</template>

			<template v-if="moduleName == `sensitiveType`" v-slot:[`item.use_for_receiving_epo`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_for_receiving_epo)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.use_for_receiving_epo)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `sensitiveType`" v-slot:[`item.allow_purchase_plg`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.allow_purchase_plg)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.allow_purchase_plg)"></span>
				</b-badge>
			</template>
			<template v-if="[`sensitiveType`].includes(moduleName)" v-slot:[`item.print_on_quotation`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.print_on_quotation)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.print_on_quotation)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `uom`" v-slot:[`item.use_epo_inv_receive`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_epo_inv_receive)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.use_epo_inv_receive)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `warehouseSettings`" v-slot:[`item.sticker_fee`]="{ item }">
				<span
					v-show="!$CoreSettingsJs.emptyStringValidation(item.sticker_fee_currency)"
					v-text="`${!$CoreSettingsJs.emptyStringValidation(item.sticker_fee_currency) ? item.sticker_fee_currency : ''} ${$CoreSettingsJs.onFormatterNo(item.sticker_fee)}`"
				></span>
			</template>
			<template v-if="moduleName == `areaGroup`" v-slot:[`item.address_required`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.address_required)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.address_required)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `areaGroup`" v-slot:[`item.agent_forwarder_required`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.agent_forwarder_required)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.agent_forwarder_required)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `areaGroup`" v-slot:[`item.special_area_required`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.special_area_required)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.special_area_required)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `areaGroup`" v-slot:[`item.verify_address_required`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.verify_address_required)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.verify_address_required)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'specialProd'" v-slot:[`item.product_status`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant($CoreSettingsJs.emptyStringValidation(item.rejected, 1) ? 1 : 0)" size="sm">
					<span v-text="item.product_status"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'specialProd'" v-slot:[`item.price_type`]="{ item }">
				<span v-text="getPriceType(item.price_type_id)"></span>
			</template>
			<template v-if="moduleName == 'quote_reject_reason'" v-slot:[`item.scs_confirm`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.scs_confirm)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.scs_confirm)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'specialProd'" v-slot:[`item.product_image`]="{ item }">
				<div class="d-flex flex-wrap justify-content-start mt-1 mb-1">
					<div v-for="(image, idx) in item.product_image" :key="`image${idx}`" style="border: #b8b6b6 solid 1px; padding: 1px !important; background: white !important; cursor: pointer">
						<v-img class="mt-1" :lazy-src="image.url" height="50" width="50" :src="image.url" contain aspect-ratio="1" @click="handlePreview(image.url)"></v-img>
					</div>
					<!--  -->
					<!-- <enlargeableImage
						
						class="mr-2 align-items-center rounded"
						
						
						:src="image.url"
						:src_large="image.url"
						animation_duration="200"
					>
						<v-img class="mt-1" :lazy-src="image.url" height="50" width="50" :src="image.url" contain aspect-ratio="1"></v-img>
					</enlargeableImage> -->
				</div>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.charge_by_uom`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.charge_by_uom)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.charge_by_uom)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.allow_markup`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.allow_markup)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.allow_markup)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.print_on_quotation`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.print_on_quotation)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.print_on_quotation)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.min_charges`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.min_charges)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.min_charges)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.min_charges_by_uom`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.min_charges_by_uom)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.min_charges_by_uom)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `chargesType`" v-slot:[`item.sub_charges`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.sub_charges)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.sub_charges)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `users`" v-slot:[`item.roles`]="{ item }">
				<div class="d-flex">
					<b-badge class="mr-1" variant="secondary" v-for="(r, idx) in JSON.parse(item.roles)" :key="`role${idx}`">
						<span v-text="r"></span>
					</b-badge>
				</div>
			</template>
			<template v-if="moduleName == `users`" v-slot:[`item.data_filters`]="{ item }">
				<div class="d-flex">
					<b-badge class="mr-1" variant="secondary" v-for="(d, idx) in JSON.parse(item.data_filters)" :key="`data_filters${idx}`">
						<span v-text="d"></span>
					</b-badge>
				</div>
			</template>
			<template v-if="moduleName == `users`" v-slot:[`item.ignore_data_filter`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.ignore_data_filter)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.ignore_data_filter)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == `menuType`" v-slot:[`item.menu_item_type`]="{ item }">
				<span v-text="getMenuItemType(item.menu_item_type)"></span>
			</template>
			<template v-if="moduleName == `menuType`" v-slot:[`item.redirect`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.redirect)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.redirect)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'roles'" v-slot:[`item.apply_to`]="{ item }">
				<span v-text="userType(item.apply_to)"></span>
			</template>
			<template v-if="moduleName == 'main_salesman'" v-slot:[`item.contact_number`]="{ item }">
				<span v-show="!$CoreSettingsJs.emptyStringValidation(item.contact_number)" v-text="`${item.contact_calling_code}${item.contact_number}`"></span>
			</template>
			<template v-if="moduleName == 'main_salesman'" v-slot:[`item.mobile_number`]="{ item }">
				<span v-show="!$CoreSettingsJs.emptyStringValidation(item.mobile_number)" v-text="`${item.mobile_calling_code}${item.mobile_number}`"></span>
			</template>
			<template v-if="moduleName == 'users'" v-slot:[`item.staff_type`]="{ item }">
				<span class="text-primary font-weight-bolder" v-show="item.is_system_admin == 1" v-html="$t('COMMON.USER_TYPE.SYSTEM_ADMIN')"></span>
				<span v-show="item.is_system_admin == 0" v-html="userType(item.staff_type)"></span>
			</template>
			<template v-if="moduleName == 'warehouse'" v-slot:[`item.support_other_country_shipment`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.support_other_country_shipment)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.support_other_country_shipment)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'forwarder'" v-slot:[`item.forwarder_address`]="{ item }">
				<span v-html="item.forwarder_address"></span>
			</template>
			<template v-if="['specialInstructionByEPO', 'specialInstructionByMarking'].includes(moduleName)" v-slot:[`item.start_from`]="{ item }">
				<span v-text="$CoreSettingsJs.UnixTimeToDate(item.start_from)"></span>
			</template>
			<template v-if="['specialInstructionByEPO', 'specialInstructionByMarking'].includes(moduleName)" v-slot:[`item.instruction_date`]="{ item }">
				<span v-text="$CoreSettingsJs.UnixTimeToDate(item.instruction_date)"></span>
			</template>
			<template v-if="moduleName == 'depositRequest'" v-slot:[`item.or_amount`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.or_amount)"></span>
			</template>
			<template v-if="moduleName == 'depositRequest'" v-slot:[`item.actual_amount`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.actual_amount)"></span>
			</template>
			<template v-if="moduleName == 'depositRequest'" v-slot:[`item.tt_charges`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.tt_charges)"></span>
			</template>
			<template v-if="['depositRequest', 'payment_advice'].includes(moduleName)" v-slot:[`item.tt_date`]="{ item }">
				<span v-text="$CoreSettingsJs.UnixTimeToDate(item.tt_date)"></span>
			</template>
			<template v-if="moduleName == 'depositRequest'" v-slot:[`item.acknowledged_at`]="{ item }"><span v-text="$CoreSettingsJs.UnixTimeToDate(item.acknowledged_at)"></span></template>
			<template v-if="moduleName == 'depositRequest'" v-slot:[`item.financial_percentage`]="{ item }">
				<b-badge v-show="!$CoreSettingsJs.emptyStringValidation(item.financial_percentage, 1)" class="text-center" variant="info" size="sm">
					<span v-text="`${item.without_or == 1 ? `Without OR : ${$CoreSettingsJs.formatNull(item.financial_percentage)}% ` : $CoreSettingsJs.formatNull(item.financial_percentage)}`"></span>
				</b-badge>
			</template>
			<template v-if="['epo_status'].includes(moduleName)" v-slot:[`item.status_color`]="{ item }">
				<div align-v="center" :style="$CoreSettingsJs.colorIndicatorStyle(item.status_color, $CoreSettingsJs.textColor(item.status_color))" v-text="item.status_color"></div>
			</template>
			<template v-if="moduleName == `epo_status`" v-slot:[`item.show_customer`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.show_customer)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.show_customer)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'bene_type'" v-slot:[`item.use_tt_group_rate`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_tt_group_rate)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.use_tt_group_rate)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'bene_type'" v-slot:[`item.allow_booked_amount`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.allow_booked_amount)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.allow_booked_amount)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'bene_type'" v-slot:[`item.limit_tt`]="{ item }">
				<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.limit_tt)" size="sm">
					<span v-text="$CoreSettingsJs.handleYesNo(item.limit_tt)"></span>
				</b-badge>
			</template>
			<template v-if="moduleName == 'tt_category'" v-slot:[`item.deposit_range_from`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.deposit_range_from)"></span>
			</template>
			<template v-if="moduleName == 'tt_category'" v-slot:[`item.deposit_range_to`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.deposit_range_to)"></span>
			</template>
			<template v-if="moduleName == 'payment_advice'" v-slot:[`item.total_tt_amount`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.total_tt_amount)"></span>
			</template>
			<template v-if="moduleName == 'payment_advice'" v-slot:[`item.total_local_tt_amount`]="{ item }">
				<span v-text="$CoreSettingsJs.onFormatterNo(item.total_local_tt_amount)"></span>
			</template>
		</v-data-table>
		<v-data-table
			:items="items"
			:headers="headers"
			:footer-props="footer_props"
			:options.sync="options"
			:loading="loading"
			:server-items-length="totalRecord == null ? items.length : totalRecord"
			:item-key="itemKey"
			fixed-header
			class="vdatable mt-1"
			dense
			:single-select="disabled_select_row == 1 ? false : true"
			:show-select="disabled_select_row == 1 ? false : true"
			v-model="singleRow"
			@click:row="singleRowSelect"
			v-if="moduleName == 'customer supplier'"
			:height="$Common.dataTableHeightHandle()"
		>
			<template #body="{ items }">
				<tbody>
					<template v-if="!$CoreSettingsJs.emptyObjectValidation(items)">
						<template v-for="(item, iidx) in items">
							<template v-for="(mitem, idx) in JSON.parse(item.marking_info)">
								<tr :key="`marking${iidx}${idx}`">
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)">
										<b-form-checkbox
											class="align-self-center"
											value="1"
											unchecked-value="0"
											v-model="item.check"
											@change="itemSelected($event, iidx, item)"
											:checked="item.check == 1"
										></b-form-checkbox>
									</td>
									<td class="text-center" v-text="mitem.main_salesman"></td>
									<td class="text-center" v-text="mitem.salesman"></td>
									<td class="text-center" v-text="mitem.marking"></td>
									<td class="text-center" v-text="mitem.full_marking"></td>
									<td class="text-center" v-text="mitem.company_group"></td>
									<td class="text-center" v-text="mitem.customer_name"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.customer_email"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.name"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.email"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.contact_no"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.country"></td>

									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.city"></td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center">
										<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.status)" size="sm">
											<span v-text="$CoreSettingsJs.handleStatusDesc(item.status)"></span>
										</b-badge>
									</td>
									<td v-if="idx == 0" :rowspan="countRowSpan(item.marking_info)" class="text-center" v-text="item.modified_by"></td>
								</tr>
							</template>
						</template>
					</template>
					<template v-else>
						<tr class="v-data-table__empty-wrapper">
							<td class="text-center" :colspan="parseInt(headers.length) + 1" v-text="$t('COMMON.MSG.DATA_NOT_AVAILABLE')"></td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-data-table>

		<v-data-table
			:items="items"
			:headers="headers"
			:footer-props="footer_props"
			:options.sync="options"
			:loading="loading"
			:server-items-length="totalRecord == null ? items.length : totalRecord"
			:item-key="itemKey"
			fixed-header
			class="mt-1"
			:class="vtableClass"
			dense
			:single-select="disabled_select_row == 1 ? false : true"
			:show-select="disabled_select_row == 1 ? false : true"
			v-model="singleRow"
			@click:row="singleRowSelect"
			v-if="moduleName == 'doSummary'"
			:hide-default-footer="hide_footer == 1"
			:height="vtableClass == 'vformDataTable' ? 300 : $Common.dataTableHeightHandle()"
		>
			<template #body="{ items }" v-if="!$CoreSettingsJs.emptyObjectValidation(items)">
				<tbody>
					<template v-for="(item, idx) in items">
						<template v-for="(sitem, sidx) in item['so_details']">
							<tr :key="`do-summary${idx}${sidx}`">
								<td v-if="sidx == 0" :rowspan="parseInt(item.so_details.length)">
									<b-form-checkbox
										class="align-self-center"
										value="1"
										unchecked-value="0"
										v-model="item.selected"
										@change="$emit('rowSelected', idx, item)"
										:checked="item.selected == 1"
									></b-form-checkbox>
								</td>
								<template v-for="(h, hi) in headers">
									<template v-if="!$CoreSettingsJs.emptyObjectValidation(h.customer_field) && h.customer_field == 1 && sidx == 0">
										<td :class="h.cellClass" :rowspan="parseInt(item.so_details.length)" v-text="item.marking_set[h.value]"></td>
									</template>
									<template v-if="!$CoreSettingsJs.emptyObjectValidation(h.addr_field) && h.addr_field == 1 && sidx == 0">
										<td :rowspan="parseInt(item.so_details.length)" :class="h.cellClass" v-html="setDoAddressValue(h.value, item)"></td>
									</template>
									<template
										v-if="
											$CoreSettingsJs.emptyObjectValidation(h.is_so) &&
											$CoreSettingsJs.emptyObjectValidation(h.customer_field) &&
											$CoreSettingsJs.emptyObjectValidation(h.addr_field) &&
											sidx == 0
										"
									>
										<td class="text-center" :class="h.cellClass" v-if="h.value == 'change_addr_status' && sidx == 0" :rowspan="parseInt(item.so_details.length)">
											<template v-if="!$CoreSettingsJs.emptyObjectValidation(item.change_addr)">
												<span
													class="badge text-center text-nowrap"
													:style="`background-color: ${item.change_addr.status_color}; color: ${$Common.textColor(item.change_addr.status_color)}`"
												>
													<span v-text="getDoChgArrStatus(item.change_addr.status_id)"></span>
												</span>
											</template>
										</td>

										<td
											v-if="!['city_name', 'postcode', 'reship', 'change_addr_status'].includes(h.value) && sidx == 0"
											:rowspan="parseInt(item.so_details.length)"
											:class="h.cellClass"
											v-html="!$CoreSettingsJs.emptyObjectValidation(h.date_field) ? $CoreSettingsJs.UnixTimeToDate(item[h.value]) : item[h.value]"
										></td>
									</template>
									<template v-if="!$CoreSettingsJs.emptyObjectValidation(h.is_so)">
										<td
											:class="h.cellClass"
											v-html="
												!$CoreSettingsJs.emptyObjectValidation(h.is_decimal)
													? $CoreSettingsJs.onFormatterNo(sitem[h.value], h.is_decimal)
													: !$CoreSettingsJs.emptyObjectValidation(h.date_field)
														? $CoreSettingsJs.UnixTimeToDate(sitem[h.value])
														: sitem[h.value]
											"
										></td>
									</template>
								</template>
							</tr>
						</template>
					</template>
				</tbody>
			</template>
		</v-data-table>
		<enlargeImage :image="image_preview" ref="enlargeImage"></enlargeImage>
	</div>
</template>

<script>
import qrcode from "vue-qrcode-component";
import enlargeImage from "@shared/src/components/enlargeImage.vue";

export default {
	props: {
		items: {
			type: Array,
			default() {
				return [];
			}
		},
		itemKey: {
			type: String,
			default() {
				return "id";
			}
		},
		headers: null,
		loading: false,
		selected_row: {
			type: Array,
			default() {
				return [];
			}
		},
		moduleName: {
			type: String,
			default: null
		},
		disabled_select_row: {
			type: Number,
			default: 0
		},
		hide_footer: {
			type: Number,
			default: 0
		},
		hide_header: {
			type: Boolean,
			default: false
		},
		disableBox: {
			type: Number,
			default: 0
		},
		vtableClass: {
			type: String,
			default: "vdatable"
		},
		sub_module: {
			type: String,
			default: null
		},
		dialog_box_id: {
			type: String,
			default: null
		},
		singleSelect: {
			type: Boolean,
			default: true
		},
		isForm: {
			type: Boolean,
			default: false
		},
		height: {
			type: Number,
			default: 0
		},
		enable_item_class: {
			type: Boolean,
			default: false
		}
	},
	components: {
		qrcode,
		// enlargeableImage,
		enlargeImage
	},
	data() {
		return {
			options: this.$CoreSettingsJs.datatableOptions,
			footer_props: this.$CoreSettingsJs.footerProps,
			singleRow: this.selected_row,
			totalRecord: null,
			qrColor: {
				dark: "#8DFF33",
				light: "#00FFFF"
			},
			customer_portal: `${process.env.VUE_APP_CUST_URL}`,
			image_preview: null,
			row: null
		};
	},
	computed: {
		dataTableHeight() {
			return this.vtableClass == "vformDataTable"
				? 300
				: this.$Common.dataTableHeightHandle(!this.$CoreSettingsJs.emptyStringValidation(this.dialog_box_id) ? this.dialog_box_id : null, this.isForm);
		}
	},
	watch: {
		singleRow: {
			handler: function (newValue) {
				if (this.singleSelect) {
					this.$emit("rowSelected", !this.$CoreSettingsJs.emptyObjectValidation(newValue) && !this.$CoreSettingsJs.emptyObjectValidation(newValue[0]) ? newValue[0] : null);
				} else {
					this.$emit("rowSelected", !this.$CoreSettingsJs.emptyObjectValidation(newValue) ? newValue : null);
				}
			},
			deep: true
		},
		options: {
			handler(new_value, old_value, source) {
				this.$emit("searchRecord");
			},
			deep: true
		},
		totalRecord: {
			handler: function (newValue) {
				if (newValue <= this.options.itemsPerPage && this.options.page != 1) {
					this.options.page = 1;
				}
			},
			deep: true
		}
	},
	mounted() {
		if (this.$Common.dataTableHeightHandle() > 500) {
			this.options.itemsPerPage = 20;
		}
	},
	methods: {
		singleRowSelect(row) {
			if (typeof row != "undefined") {
				if (["system-log", "audit-log"].includes(this.moduleName)) {
					this.singleRow = [row];
				} else {
					this.$emit("singleRowSelect", row);
				}
			}
		},
		getNumResetDesc(num_reset) {
			if (num_reset == 0) {
				return this.$t("MASTER_DATA.DOC_NUM.SELECTION.NO_RESET");
			} else if (num_reset == 1) {
				return this.$t("MASTER_DATA.DOC_NUM.SELECTION.BY_MONTH");
			} else if (num_reset == 2) {
				return this.$t("MASTER_DATA.DOC_NUM.SELECTION.BY_YEAR");
			}
		},
		// getDocNumId(doc_num_id) {
		// 	if (doc_num_id == 0) {
		// 		return this.$t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.SELECTION.DOC_NUM_A");
		// 	} else if (doc_num_id == 1) {
		// 		return this.$t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.SELECTION.DOC_NUM_B");
		// 	}
		// },
		getRounding(type) {
			let roundType = this.$t("SETTINGS.WEIGHT_ROUNDING.ROUNDUP_SELECTION");
			if (type == 0) {
				roundType = this.$t("SETTINGS.WEIGHT_ROUNDING.ROUNDDOWN_SELECTION");
			}

			return roundType;
		},
		getSupportAt(support_at) {
			let desc = null;
			switch (parseInt(support_at)) {
				case 1:
					desc = this.$t("SETTINGS.LANGUAGE.SUPPORT_AT_SELECTION.ALL");
					break;
				case 2:
					desc = this.$t("SETTINGS.LANGUAGE.SUPPORT_AT_SELECTION.ADMIN");
					break;
				case 3:
					desc = this.$t("SETTINGS.LANGUAGE.SUPPORT_AT_SELECTION.CUSTOMER");
					break;
			}

			return desc;
		},
		getMenuType(menu_type) {
			if (menu_type == 50) {
				return this.$t("CMS.MENU.MENU_TYPE.HEADER_PAGE");
			} else if (menu_type == "60") {
				return this.$t("CMS.MENU.MENU_TYPE.FOOTER_PAGE");
			} else if (menu_type == "70") {
				return this.$t("CMS.MENU.MENU_TYPE.DASHBOARD_PAGE");
			} else {
				return this.$t("CMS.MENU.MENU_TYPE.CMS_PAGE");
			}
		},
		getPostingSideDesc(posting_side) {
			if (posting_side == 0) {
				return this.$t("MASTER_DATA.DOC_TYPE.SELECTION.NO_POSTING");
			} else if (posting_side == 1) {
				return this.$t("MASTER_DATA.DOC_TYPE.SELECTION.CREDIT");
			} else if (posting_side == 2) {
				return this.$t("MASTER_DATA.DOC_TYPE.SELECTION.DEBIT");
			}
		},
		getUseSameIdDesc(use_same_id) {
			if (use_same_id == 0) {
				return this.$t("COMMON.STATUS.NO");
			} else {
				return this.$t("COMMON.STATUS.YES");
			}
		},
		itemSelected($event, didx, row) {
			this.$emit("rowSelected", $event, didx, row);
		},
		countRowSpan(data) {
			return JSON.parse(data).length;
		},
		getTTChargesSettingsDesc(data) {
			let str;
			switch (parseInt(data)) {
				case 0:
					str = this.$t("MASTER_DATA.CURRENCIES.TT_CHARGES_SETTINGS.DEPEND_CURRENCY");
					break;
				case 1:
					str = this.$t("MASTER_DATA.CURRENCIES.TT_CHARGES_SETTINGS.DEPEND_LOCATION");
					break;
			}
			return str;
		},
		downloadAsImage(idx, data) {
			var element = document.querySelector("#qrcode-" + idx);
			var canvas = element.firstChild;
			var width = 20;
			var height = 20;

			var centerX = canvas.width / 2 - width / 2;
			var centerY = canvas.height / 2 - height / 2;

			var ctx = canvas.getContext("2d");
			ctx.strokeStyle = "#6e6e80";
			ctx.fillStyle = "#eef5fb";

			ctx.roundRect(centerX, centerY, width, height, 3);
			ctx.stroke();
			ctx.fill();

			ctx.font = "20px Arial 900";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.fillStyle = "#000000";
			ctx.fillText(data.sm_code, centerX + width / 2, centerY + height / 2);
			canvas.style.border = "5px solid #fff";
			var link = document.createElement("a");
			var toDataURL = canvas.toDataURL("image/jpg");
			link.download = `${data.sm_code}.jpg`;
			link.href = toDataURL;
			link.click();
			link.remove();
		},
		getInvoiceFormat(inv_format) {
			let invFormatSelection = this.$CoreJs.invFormatSelection.find((data) => parseInt(data.id) == parseInt(inv_format));
			return typeof invFormatSelection != "undefined" ? invFormatSelection.name : ``;
		},
		getEpoTypeDesc(item) {
			if (item.epo_type == 1) {
				return this.$t("EPO_MANAGEMENT.ORDER.EPO_TYPE_SELECTION_COURIER");
			} else if (item.epo_type == 0) {
				return this.$t("EPO_MANAGEMENT.ORDER.EPO_TYPE_SELECTION_ORDER");
			}
		},
		markingStatusBadge(status) {
			let status_option = this.$CoreJs.markingStatusSelection.find((data) => data.id == status);
			return status_option.color;
		},
		markingStatusName(status) {
			let status_option = this.$CoreJs.markingStatusSelection.find((data) => data.id == status);
			return status_option.name;
		},
		getPriceType(price_type_id) {
			return this.$CoreSettingsJs.emptyStringValidation(price_type_id, 1) ? "" : this.$CoreJs.priceTypeSelection.find((data) => data.id == price_type_id).name;
		},
		getMenuItemType(menu_type) {
			if (menu_type == 10) {
				return this.$t("CMS.MENU_ITEMS.MENU_TYPE.EXTERNAL_LINK");
			} else if (menu_type == 20) {
				return this.$t("CMS.MENU_ITEMS.MENU_TYPE.SYSTEM_PAGE");
			} else {
				return this.$t("CMS.MENU_ITEMS.MENU_TYPE.CMS_PAGE");
			}
		},
		userType(apply_to) {
			return this.$CoreJs.userTypeHandle(apply_to);
		},
		handlePreview(image_path) {
			this.image_preview = image_path;
			let content = document.querySelector("#kt_aside");
			let imageModal = document.querySelector("#imgModal");
			imageModal.style.left = content.clientWidth > 100 ? `${content.clientWidth * 0.5}px` : 0;
			document.querySelector("#closePimage").style.right = content.clientWidth > 100 ? `${parseFloat(content.clientWidth * 0.5).toFixed(0)}px` : 0;
			let imgContent = document.querySelector("#pImageContent");
			imgContent.style.maxWidth = content.clientWidth > 100 ? "60%" : "80%";
			imgContent.style.maxWidth = content.clientWidth > 100 ? "60%" : "80%";
			this.$refs["enlargeImage"].previewVisible = true;
		},
		getDoChgArrStatus(status_id) {
			let str = "";
			this.$emit("changeAddStatus", status_id, (result) => (str = result));
			return str;
		},
		setDoAddressValue(field, item) {
			let data =
				!this.$CoreSettingsJs.emptyStringValidation(this.sub_module) && this.sub_module == "change_addr" && !this.$CoreSettingsJs.emptyObjectValidation(item.change_addr)
					? item.change_addr
					: item;
			let str = `<span ${
				!this.$CoreSettingsJs.emptyStringValidation(this.sub_module) && this.sub_module == "change_addr" && !this.$CoreSettingsJs.emptyObjectValidation(item.change_addr)
					? 'class="text-primary"'
					: ""
			}>`;
			if (field == "city_name") {
				str = str + !this.$CoreSettingsJs.emptyStringValidation(data[field]) ? data[field] : data["other_city"];
			} else if (field == "postcode") {
				str = str + !this.$CoreSettingsJs.emptyStringValidation(data[field]) ? data[field] : data["other_postcode"];
			} else {
				str = str + data[field];
			}
			str = str + "</span>";
			return str;
		},
		itemClass(item) {
			if (this.enable_item_class) {
				let classname = "";
				this.$emit("handleItemClass", item, (result) => (classname = result));
				return classname;
			} else {
				return "";
			}
			console.log(item);
		}
	}
};
</script>
