<template>
	<div>
		<b-alert class="text-left" variant="danger" :show="!$CoreSettingsJs.emptyStringValidation(error_message)" v-html="error_message"></b-alert>
		<b-alert class="text-left" variant="success" :show="!$CoreSettingsJs.emptyStringValidation(success_message)" v-html="success_message"></b-alert>
		<b-alert class="text-left" variant="warning" :show="!$CoreSettingsJs.emptyStringValidation(warning_message)" v-html="warning_message"></b-alert>
	</div>
</template>

<script>
export default {
	name: "FormAlert",
	props: {
		error_message: null,
		success_message: null,
		warning_message: null
	}
};
</script>
